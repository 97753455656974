import { ColDef, ICellRendererParams } from 'ag-grid-enterprise';

import { isGoldModel, isManagedNetwork } from '@/models/columns/definitions/utils';
import { RequiredPermissionParams } from '@/models/columns/utils/col-def-with-permission';
import { ColumnId } from '@/models/enums/grid';
import { FlightViewLegCabinInfo } from '@/models/FlightModel';
import { useOverbookingStore } from '@/modules/details/stores/overbooking.store';
import { UpdateOverbookingAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-overbooking-autopilot-action';
import { formatNumber } from '@/modules/shared';
import { i18n } from '@/plugins/i18n';
import { useFlightStore } from '@/store/modules/flight.store';

const { t } = i18n.global;

export const GrossAvailabilityColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.GrossAvailability,
  headerName: t('gross_availability_short'),
  field: 'grossAvailability',
  type: 'numericColumn',
  minWidth: 50,
  width: 50,
  headerTooltip: t('gross_availability'),
};

export const NetAvailabilityColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.NetAvailability,
  headerName: t('net_availability_short'),
  field: 'netAvailability',
  type: 'numericColumn',
  minWidth: 50,
  width: 50,
  headerTooltip: t('net_availability'),
};

export const LoadFactorColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LoadFactor,
  headerName: t('authorized_capacity_load_factor_short'),
  headerTooltip: t('authorized_capacity_load_factor'),
  type: 'numericColumn',
  minWidth: 60,
  width: 60,
  cellRenderer: 'LoadFactorRenderer',
  cellClass: 'marginless-cell border border-primary flex',
};

export const LegCabinCapacityLoadfactorColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinCapacityLoadfactor,
  headerName: t('saleable_capacity_load_factor_short'),
  headerTooltip: t('saleable_capacity_load_factor'),
  type: 'numericColumn',
  minWidth: 60,
  width: 60,
  cellRenderer: 'CapacityLoadFactorRenderer',
  cellClass: 'marginless-cell border border-primary flex',
};

export const InventoryLegCabinBookingsColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinBookings,
  headerName: t('bookings_short'),
  field: 'updatedBookings',
  type: 'numericColumn',
  minWidth: 80,
  width: 80,
  headerTooltip: t('bookings'),
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: (params: ICellRendererParams<FlightViewLegCabinInfo>) => ({
    originalValue: params.data?.bookings,
  }),
};

export const InventoryLegCabinConnectingBookingsColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinConnectingBookings,
  headerName: t('connecting_bookings_short'),
  cellRenderer: 'ConnectingBookingsRenderer',
  field: 'connectingBookings',
  type: 'numericColumn',
  minWidth: 80,
  width: 80,
  headerTooltip: t('connecting_bookings'),
  requiredPermission: isManagedNetwork,
};

export const InventoryLegCabinLocalRevenueColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinLocalRevenue,
  headerName: t('details.local_revenue_short'),
  headerTooltip: t('details.local_revenue'),
  field: 'localRevenue',
  type: 'numericColumn',
  minWidth: 55,
  width: 55,
  valueFormatter: (params) => formatNumber(params.value),
  requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
};

export const InventoryLegCabinConnectingRevenueColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinConnectingRevenue,
  headerName: t('details.connecting_revenue_short'),
  headerTooltip: t('details.connecting_revenue'),
  field: 'connectingRevenue',
  type: 'numericColumn',
  minWidth: 55,
  width: 55,
  valueFormatter: (params) => formatNumber(params.value),
  requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
};

export const InventoryLegCabinNetworkRevenueColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinNetworkRevenue,
  headerName: t('details.network_revenue_short'),
  headerTooltip: t('details.network_revenue'),
  field: 'networkRevenue',
  type: 'numericColumn',
  minWidth: 55,
  width: 55,
  valueFormatter: (params) => formatNumber(params.value),
  requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
};

export const InventoryLegCabinLocalBookingsColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinLocalBookings,
  headerName: t('local_bookings_short'),
  field: 'localBookings',
  type: 'numericColumn',
  minWidth: 80,
  width: 80,
  headerTooltip: t('local_bookings'),
  requiredPermission: isManagedNetwork,
};

export const UpdatedAuthorizedCapacityColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.UpdatedAuthorizedCapcity,
  cellClass: ({ data }) => `data-test-a-cap-cell-cabin-${data.cabinCode.toLowerCase()}`,
  headerName: t('authorized_capacity_short'),
  field: 'updatedAuthorizedCapacity',
  type: 'numericColumn',
  minWidth: 60,
  width: 60,
  headerTooltip: t('authorized_capacity'),
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: (params: ICellRendererParams<FlightViewLegCabinInfo>) => ({
    originalValue: params.data?.authorizedCapacity,
  }),
};

export const generateLegCabinCapacityColumn = (isEditable: boolean): ColDef<FlightViewLegCabinInfo> => {
  const legCapColDef: ColDef<FlightViewLegCabinInfo> = {
    colId: ColumnId.LegCabinCapacity,
    cellClass: ({ data }) => `ag-right-aligned-cell data-test-s-cap-cell-cabin-${data.cabinCode.toLowerCase()}`,
    headerName: t('saleable_capacity_short'),
    field: 'saleableCapacity',
    type: 'numericColumn',
    menuTabs: ['filterMenuTab'],
    filter: 'agNumberColumnFilter',
    minWidth: 65,
    width: 65,
    headerTooltip: t('saleable_capacity'),
  };

  if (isEditable) {
    legCapColDef.cellRenderer = 'GridCapacityRenderer';
    legCapColDef.cellClass = 'marginless-cell';
  }

  return legCapColDef;
};

export const generateOverbookingFactorColumn = (isDeparted: boolean): ColDef<FlightViewLegCabinInfo> => ({
  colId: ColumnId.OverbookingFactor,
  headerName: t('overbooking_factor_short'),
  field: 'overbookingFactor',
  minWidth: 70,
  width: 70,
  type: 'numericColumn',
  headerTooltip: t('overbooking_factor'),
  cellRenderer: 'GridOverbookingFactorRenderer',
  cellRendererParams: { isDeparted },
  cellClass: 'marginless-cell',
});

export const recommendedOverbookingFactorColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.RecommendedOverbooking,
  headerName: t('details.recommended_overbooking_factor_short'),
  field: 'recommendedOverbookingFactor',
  width: 70,
  minWidth: 70,
  type: 'numericColumn',
  headerTooltip: t('details.recommended_overbooking_factor'),
  cellRenderer: 'GridRecommendedOverbookingFactorRenderer',
  cellClass: 'marginless-cell',
  requiredPermission: (params) => !!params.customerSettings.hasOverbookingRecommendationsEnabled,
};

export const InventoryLegCabinUnbalancedAdjustmentColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinUnbalancedAdjustment,
  headerName: t('details.unbalanced_adjustment_short'),
  cellClass: 'marginless-cell ag-right-aligned-cell data-test-leg-cabin-unbalanced-adjustments',
  field: 'unbalancedAdjustment',
  minWidth: 70,
  width: 70,
  type: 'numericColumn',
  headerTooltip: t('details.unbalanced_adjustment'),
  requiredPermission: (params) => !!params.customerSettings.hasUnbalancedAdjustmentsEnabled,
};

export const InventoryLegCabinAllotmentColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinAllotment,
  headerName: t('details.allotment_short'),
  cellClass: 'marginless-cell ag-right-aligned-cell data-test-leg-cabin-allotment',
  field: 'allotment',
  minWidth: 70,
  width: 70,
  type: 'numericColumn',
  headerTooltip: t('details.allotment'),
  requiredPermission: (params) => !!params.customerSettings.hasAllotmentsEnabled,
};

export type OverbookingAutopilotColumnCallback = ({
  value,
  params,
}: {
  value: boolean;
  params: ICellRendererParams<FlightViewLegCabinInfo>;
}) => void;

const overbookingAutopilotColumnCallback: OverbookingAutopilotColumnCallback = ({ value, params }) => {
  const overbookingStore = useOverbookingStore();
  const flightStore = useFlightStore();
  const flightLineId = params.data?.id;
  const cabinCode = params.data?.cabinCode;
  const legNumber = params.data?.legId;
  overbookingStore.overbookingAutopilot = value;

  const action = new UpdateOverbookingAutopilotAction(cabinCode, legNumber);
  action.setPayload({
    actionType: action.actionType,
    cabinCode,
    value,
    legNumber,
  });
  flightStore.addOverbookingAutopilotAction({
    action,
    flightLineId,
  });
};

export const OverbookingAutopilotColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.OverbookingAutopilot,
  headerName: t('details.overbooking_autopilot_short'),
  field: 'overbookingAutopilot',
  width: 70,
  minWidth: 70,
  cellRenderer: 'GridSwitchRenderer',
  cellRendererParams: {
    actionName: 'Overbooking Autopilot',
    dataTest: 'flight-details-overbooking-autopilot-switch',
    onChange: overbookingAutopilotColumnCallback,
  },
  headerTooltip: t('details.overbooking_autopilot'),
  requiredPermission: (params) => !!params.customerSettings.hasOverbookingRecommendationsEnabled,
};

export const ExpectedNoShowsColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.ExpectedNoShows,
  headerName: t('details.expected_no_shows_short'),
  field: 'expectedNoShows',
  width: 50,
  minWidth: 50,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  cellClass: 'marginless-cell ag-right-aligned-cell',
  headerTooltip: t('details.expected_no_shows'),
  requiredPermission: (params) => !!params.customerSettings.hasOverbookingRecommendationsEnabled,
};

export const OperationCapacityColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.OperationCapacity,
  headerName: t('operating_capacity_short'),
  field: 'operatingCapacity',
  type: 'numericColumn',
  width: 55,
  minWidth: 55,
  headerTooltip: t('operating_capacity'),
};

export const LegCabinAuthorizedCapcityColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinAuthorizedCapacity,
  headerName: t('authorized_capacity_short'),
  field: 'authorizedCapacity',
  type: 'numericColumn',
  filter: 'agNumberColumnFilter',
  menuTabs: ['filterMenuTab'],
  width: 45,
  minWidth: 45,
  headerTooltip: t('authorized_capacity'),
};

export const LegCabinBlockSpaceColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinBlockSpace,
  headerName: t('block_space_short'),
  field: 'cabinAgreements',
  minWidth: 60,
  width: 60,
  type: 'numericColumn',
  headerTooltip: t('block_space'),
  cellRenderer: 'GridBlockSpaceRenderer',
};

export const LegCabinOriginDestinationColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinOriginDestination,
  headerName: t('leg'),
  valueFormatter: (params: any) => {
    if ((params.data as FlightViewLegCabinInfo).cabinIndex === 0) {
      return `${(params.data as FlightViewLegCabinInfo).origin}-${(params.data as FlightViewLegCabinInfo).destination}`;
    } else {
      return '';
    }
  },
  minWidth: 85,
  width: 85,
};

export const LegCabinTourOperatorSaleableLoadFactorColumn: ColDef<FlightViewLegCabinInfo> = {
  colId: ColumnId.LegCabinTourOperatorSaleableLoadFactor,
  headerName: t('grid.columns.tour_operator.saleable_load_factor_short'),
  headerTooltip: t('grid.columns.tour_operator.saleable_load_factor'),
  field: 'tourOperatorCapacityLoadFactor',
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  requiredPermission: ({ customerSettings }) => !!(customerSettings.hasNegoAllocationEnabled && customerSettings.hasTourOperatorsV2Enabled),
};
