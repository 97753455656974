import { CellClassParams, ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';

import { TextColumnFilterSettings } from '@/models/columns/definitions/base';
import { Authority } from '@/modules/api/auth/auth-contracts';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { RoleModel } from '@/modules/user-management/api/role/role-contracts';
import { UserGroup } from '@/modules/user-management/api/user-group/user-group-management.contracts';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const UserNameColumn: ColDef<UserModel> = {
  ...TextColumnFilterSettings,
  headerName: t('user_management.labels.name'),
  type: 'leftAligned',
  field: 'name',
  sortable: true,
  cellClass: () => `flex items-center data-test-user-name-column`,
  width: 175,
};

export const EmailColumn: ColDef<UserModel> = {
  ...TextColumnFilterSettings,
  headerName: t('user_management.labels.email'),
  type: 'leftAligned',
  field: 'email',
  sortable: true,
  width: 275,
};

export const UserStatusColumn: ColDef<UserModel> = {
  ...TextColumnFilterSettings,
  type: 'leftAligned',
  field: 'isEnabled',
  cellRenderer: 'UserStatusCellRenderer',
  headerName: t('user_management.labels.status'),
  sortable: true,
  cellClass: ({ data }: CellClassParams) => `data-test-user-permissions-status-column-${data.id} flex items-center`,
  width: 125,
};

export const UserRolesColumn: ColDef<UserModel> = {
  suppressHeaderMenuButton: true,
  type: 'leftAligned',
  field: 'roles',
  cellRenderer: 'UserRolesCellRenderer',
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data;
    if (data.roles) {
      return data.roles.map((role: RoleModel) => role.name).join();
    }
  },
  cellRendererParams: (params: ICellRendererParams) => ({
    value: (params.data as UserModel).roles,
  }),
  comparator: StringOrNumberComparator,
  headerName: t('user_management.labels.roles'),
  sortable: false,
  width: 600,
};

export const UserGroupsColumn: ColDef<UserModel> = {
  suppressHeaderMenuButton: true,
  type: 'leftAligned',
  field: 'userGroups',
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data;
    if (data.roles) {
      return (
        data.userGroups
          ?.map((userGroup: UserGroup) => userGroup.name)
          .sort((a: string, b: string) => a.localeCompare(b))
          .join(', ') || t('user_management.labels.unassigned')
      );
    }
  },
  headerName: t('user_management.labels.user_groups'),
  sortable: false,
  width: 600,
  requiredPermission: ({ customerSettings, authorities }) =>
    !!customerSettings.hasUserGroupsEnabled &&
    !!authorities?.find((authority: Authority) => authority === Authority.UserGroupsRead || authority === Authority.UserGroupsUpdate),
};
