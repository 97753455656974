import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import {
  RouteAssignedToColumn,
  RouteCabinBYOROpLevelAdjustmentIncrementColumn,
  RouteCabinClustersColumn,
  RouteCabinForecastDynamicProgramOpLevelAdjustmentIncrementColumn,
  RouteCabinPacingCurvesColumn,
  RouteCarrierCodeColumn,
  RouteCfTimeWindow,
  RouteDestinationColumn,
  RouteDirectionColumn,
  RouteFareCurrencyColumn,
  RouteFlightPathColumn,
  RouteForecastGeneration,
  RouteForecastLearning,
  RouteGroupsColumn,
  RouteKeyColumn,
  RouteMarket,
  RouteMarketLabel1,
  RouteMarketLabel2,
  RouteMarketLabel3,
  RouteOriginColumn,
  RouteStopsColumn,
  RouteUserGroupColumn,
} from '@/models/columns/definitions';
import { CabinStructure } from '@/modules/api/application/application-contracts';
import { SlimRouteModel } from '@/modules/route-management/store/route-management.store';
import { AssignRouteModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export function getSharedRouteColumns(cabins: CabinStructure[]): Array<ColDef | ColGroupDef> {
  const sharedColumnGroups = [
    {
      headerName: `${t('general.route')}`,
      groupId: 'route',
      children: [
        RouteKeyColumn,
        RouteCarrierCodeColumn,
        RouteFlightPathColumn,
        RouteStopsColumn,
        RouteOriginColumn,
        RouteDestinationColumn,
        RouteAssignedToColumn,
        RouteUserGroupColumn,
        RouteCfTimeWindow,
        RouteFareCurrencyColumn,
        RouteDirectionColumn,
        RouteGroupsColumn,
        RouteForecastGeneration,
        RouteForecastLearning,
        RouteMarket,
        RouteMarketLabel1,
        RouteMarketLabel2,
        RouteMarketLabel3,
      ],
    },
  ];
  cabins.forEach((cabin: CabinStructure) => {
    sharedColumnGroups.push({
      headerName: `${t('cabin')} ${cabin.code}`,
      groupId: cabin.code,
      children: [
        RouteCabinClustersColumn(cabin.code),
        RouteCabinPacingCurvesColumn(cabin.code),
        RouteCabinBYOROpLevelAdjustmentIncrementColumn(cabin.code),
        RouteCabinForecastDynamicProgramOpLevelAdjustmentIncrementColumn(cabin.code),
      ],
    });
  });
  return sharedColumnGroups;
}

export const getSharedRouteCarrierFlightPathColumns: ColGroupDef<SlimRouteModel | AssignRouteModel>[] = [
  {
    headerName: `${t('general.route')}`,
    groupId: 'routeCarrierFlightPath',
    children: [RouteCarrierCodeColumn, RouteFlightPathColumn] as ColDef<SlimRouteModel | AssignRouteModel>[],
  },
];
