import { FilterFieldType } from '@/modules/api/shared-contracts';
import { CONTROL_DATA_VIEW_MODE } from '@/modules/user-settings/api/user/user.contracts';

export const en = {
  control: {
    titles: {
      flight_control: 'Flight Control',
      flight_review: 'Flight Review',
      review_queue: 'Review Queue',
      provide_flight_keys: 'Provide FLight Keys',
    },
    cross_filters: {
      title: 'Cross Filters',
      active_cross_filters: '{n} Active Cross Filter | {n} Active Cross Filters',
      no_cross_filters: 'Configure your first Cross Filter via the Flight Control Settings',
      ancillary_revenue: 'Ancillaries Share of Revenue',
      bookings: 'Bookings',
      cabin_cf_diff: 'Δ CF %',
      cabin_cluster_performance: 'Performance Band',
      cabin_forecast_load_factor: 'Forecast Load Factor',
      cabin_laf: 'Lowest Available Fare',
      cabin_n_day_pickup: 'Cabin {cabin} Pickup ({n} day) | Cabin {cabin} Pickup ({n} days)',
      cabin_realtime_pickup: 'Realtime Pickup',
      cabin_rival_fares_diff: 'Δ RF %',
      cabin_rlaf: 'Active rLAF',
      cabin_rlaf_variance: 'Active rLAF Variance',
      cabin_shadow_active_tactic_variance: 'Shadow OP Level Variance',
      cabin_rlaf_variance_data_science: 'Data Science rLAF Variance',
      cabin_shadow_rlaf: 'Shadow rLAF',
      cabin_shadow_rlaf_variance: 'Shadow rLAF Variance',
      capacity_load_factor: 'Capacity Load Factor',
      day_of_week: 'Day of Week',
      days_since_last_booking: 'Days Since Last Booking',
      days_since_last_user_action: 'Days Since Last User Action',
      days_since_last_user_review: 'Days Since Last User Review',
      departure_date: 'Departure Date',
      departure_time: 'Departure Time',
      local_bookings_percentage: 'Local Bookings %',
      connecting_bookings_percentage: 'Connecting Bookings %',
      lid_load_factor: 'Lid Load Factor',
      negotiated_allocations_sold: 'Nego Alloc Sold',
      negotiated_allocations_confirmed_utilization: 'Nego Alloc Confirmed Utilization',
      op_level: 'OP Level',
      optimal_final_bookings: 'Optimal Final Bookings',
      performance_band_pickup: `Performance Band Pickup ({n} day) | Performance Band Pickup ({n} days)`,
      projected_pickup: `LAF-based Projected Pickup ({n} day) | LAF-based Projected Pickup ({n} days)`,
      seat_availability_lowest_available_fare: 'Seats Available in LAF',
      rd_acap_percentage: 'Relative RD Growth / aCap %',
    },
    cabin_x: `Cabin {cabin}`,
    reset_selection: 'Reset selection',
    zoom_to_selection: 'Zoom to selection',
    configuration: {
      option: {
        cabin: 'Cabin {cabin}',
        flight: 'Flight',
      },
    },
    columns: {
      equipment_type: 'Equipment Type',
      equipment_type_short: 'EqpT',
      equipment_version: 'Equipment Version',
      equipment_version_short: 'EqpV',
      expected_no_shows: 'Expected No Shows',
      expected_no_shows_short: 'Expected NS',
      overbooking_autopilot: 'Overbooking Autopilot',
      overbooking_autopilot_short: 'OVB Autopilot',
      overbooking_risk: 'Overbooking Risk',
      overbooking_risk_short: 'OVB Risk',
      recommended_overbooking_factor: 'Recommended Overbooking Factor',
      recommended_overbooking_factor_short: 'rOVB Factor',
      unbalanced_adjustments_short: 'Unbal Adj',
      unbalanced_adjustments: 'Unbalanced Adjustments',
      allotment_short: 'Allot',
      allotment: 'Allotment',
      sales_configuration: 'Sales Configuration',
      sales_configuration_short: 'Config',
      class_configuration: 'Class Configuration Name',
      class_configuration_short: 'Cls Cfg',
      flight_optimal_final_bookings_short: 'OptFnlBk',
      flight_optimal_final_bookings: 'Optimal Final Bookings',
      cabin_optimal_final_bookings: 'Optimal Final Bookings - Cabin {cabin}',
      flight_optimal_final_revenue_short: 'OptFnlRev',
      flight_optimal_final_revenue: 'Optimal Final Revenue',
      cabin_optimal_final_revenue: 'Optimal Final Revenue - Cabin {cabin}',
      projected_bookings_pickup_short: '∆({n}d) LAF Proj Bk',
      projected_bookings_pickup: '∆ ({n}d) LAF-based Projected Bookings Pickup',
      cabin_projected_bookings_pickup: '∆ ({day}d) LAF-based Projected Bookings Pickup - Cabin {cabin}',
      recommended_floor_fare_short: 'rFF',
      cabin_recommended_floor_fare: 'Recommended Floor Fare - Cabin {cabin}',
      stops: 'Stops',
      stops_short: 'Number of Stops',
      crossing_flights: 'Xing Flts',
      crossing_flights_short: 'Crossing Flights',
      local_bookings: 'Local Bookings',
      local_bookings_short: 'Local Bk',
      connecting_bookings: 'Connecting Bookings',
      connecting_bookings_short: 'Conx Bk',
      local_revenue: 'Local Revenue',
      local_revenue_short: 'Local Rev',
      connecting_revenue: 'Through/Connecting Revenue',
      connecting_revenue_short: 'Conx Rev',
      network_revenue: 'Network Revenue',
      network_revenue_short: 'Ntwrk Rev',
      flight_key: 'Flight Key',
      byos_remaining_demand: 'BYOS Base Remaining Demand',
      byos_remaining_demand_short: 'BYOS Base RD',
      byos_standard_deviation: 'BYOS Base Standard Deviation',
      byos_standard_deviation_short: 'BYOS Base SD',
      user_groups_short: 'User Groups',
      user_groups: 'Flight Managed within User Groups',
    },
    info: {
      outdated_results: {
        title: 'Outdated Results',
        description: 'Data may not be present due to the updates made. Run the search again to get the latest results!',
      },
      tour_operators: "Add the selected tour operators' performance indicators columns to the results grid.",
      number_of_flight_keys_provided: 'Number of Flight Keys provided: {n}',
      max_n: 'Max. {n}',
    },
    placeholders: {
      flight_keys: '20220617.XX.135..KMB.YUL\n20220617.XX.135..KMB.YUL\n...',
    },
    actions: {
      search_by_flight_keys: 'Search by Flight Keys',
      search: 'Search',
      cancel: 'Cancel',
      review_selection_in_new_tab: 'Review Selection in New Tab',
    },
    bulk_actions: {
      apply_recommended_overbooking: 'Apply rOVB',
      move_recommended_overbooking_risk_percentage_down: 'Decrease rOVB Risk %',
      move_recommended_overbooking_risk_percentage_up: 'Increase rOVB Risk %',
      set_overbooking_autopilot: 'Set OVB Autopilot',
    },
    labels: {
      tour_operators: 'Tour Operators',
      flight_keys: 'Flight Keys',
      filter_fields: {
        stops: 'Stops',
        to_allotment: 'Tour Operator Allotment',
        has_allotment: 'Allotment',
        has_no_allotment: 'No Allotment',
        to_contract_type: 'Tour Operator Contract Type',
        to_contract_name: 'Tour Operator Contract Name',
        user_group: 'User group',
        types: {
          [FilterFieldType.in]: 'Any',
          [FilterFieldType.all]: 'All',
          [FilterFieldType.none]: 'None',
          [FilterFieldType.noneOf]: 'None Of',
          [FilterFieldType.equal]: 'Equal',
        },
      },
    },
    errors: {
      route_filter_type_missing_from_query_payload:
        "No Route Filter Type selected. Use Flight Control Settings to use either the 'O&D' or 'Hub' option",
    },
    invalid_field: {
      flight_keys_max_number: 'Maximum number (20,000) of Flight Keys exceeded',
    },
    dynamic_class_view: {
      capacity_short: 'Cap',
      load_factor_short: 'LF',
    },
    view_modes: {
      [CONTROL_DATA_VIEW_MODE.TABLE]: 'Table',
      [CONTROL_DATA_VIEW_MODE.GRID]: 'Grid',
    },
    pending_changes: 'You have no changes | You have 1 pending change | You have {count} pending changes',
    capacity_short: 'Cap',
    lid_short: 'Lid',
    revert_changes: 'Revert Changes',
    save_changes: 'Save',
    action_bar: 'Action Bar',
  },
};
