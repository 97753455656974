import { RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const INVENTORY_OVERWRITE_RULES_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/inventory-overwrite-rules',
    name: RouteName.inventoryOverwriteRules,
    meta: {
      title: t('inventory_overwrite_rules.titles.inventory_overwrite_rules'),
    },
    component: () => import('@/modules/inventory-overwrite-rules/views/InventoryOverwriteRules.vue'),
  },
];
