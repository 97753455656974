import {
  CellClassParams,
  ColDef,
  ICellRendererParams,
  IRowNode,
  NewValueParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import { first, isEmpty, isFinite, isNil } from 'lodash-es';

import { Constants } from '@/constants';
import { translatePerformanceBandPickupHeader, translatePerformanceBandPickupHeaderTooltip } from '@/models/columns/definitions/pickup';
import {
  ColumnId,
  generateCabinLevelColumnId,
  generateCabinLevelCompetitorColumnId,
  generateCabinLevelMinCompetitorColumnId,
  generateCabinLevelPercentageDifferenceCompetitorColumnId,
  generateCabinPerfomanceBandPickupColumnId,
  generateCabinPickupColumnId,
} from '@/models/enums/grid';
import { getCabinFare, getFareForCabin } from '@/models/FareFinder';
import { FlightDynamicPropertiesAllowed, FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { OptimisationTactic } from '@/models/OptimisationTactic';
import { CabinCode, ClassStructure } from '@/modules/api/application/application-contracts';
import { CompetitorFareGridModel } from '@/modules/api/flight/competitive-fares/competitive-fares-contracts';
import {
  FlightLineCabin,
  FlightLineCabinClass,
  FlightLineModel,
  PerformanceBandPickupModel,
  ProjectedBookingsPickupDays,
  SlimFlightLineModel,
} from '@/modules/api/flight/flight-contracts';
import { InventoryGridModel } from '@/modules/api/flight/inventory-grid-model';
import { RivalFareGridModel } from '@/modules/api/flight/rival-fares/rival-fares-contracts';
import { IOptimizationProfileLevel } from '@/modules/control/api/optimisation-profiles.contracts';
import { useControlStore } from '@/modules/control/store/control.store';
import { FareSource } from '@/modules/customer-settings/api/customer-settings.contracts';
import { AddOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-optimization-profile-level-action';
import { AddShadowOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-shadow-optimization-profile-level-action';
import { RemovePromotionAction } from '@/modules/flight-actions/actions/cabin-actions/remove-promotion-action';
import { SetPricingAdjustmentAction } from '@/modules/flight-actions/actions/cabin-actions/set-pricing-adjustment-action';
import { SetPricingIncrementAction } from '@/modules/flight-actions/actions/cabin-actions/set-pricing-increment-action';
import { SetPricingTacticAction } from '@/modules/flight-actions/actions/cabin-actions/set-pricing-tactic-action';
import { SetPromotionAction } from '@/modules/flight-actions/actions/cabin-actions/set-promotion-action';
import { UpdateAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-autopilot-action';
import { FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { seatAvailabilityFormatter } from '@/modules/grid/formatters/seat-availability.formatter';
import { logger } from '@/modules/monitoring';
import { formatNumber, roundNumber } from '@/modules/shared';
import { CabinService } from '@/modules/shared/services/cabin.service';
import { ClassCodeComparatorParams, StringOrNumberComparator, classCodeComparator } from '@/modules/shared/utils/comparisons.utils';
import { LafLoadFactorColoring } from '@/modules/user-settings/api/user/user.contracts';
import { generateLafColorScheme } from '@/modules/user-settings/utils/colors.utils';
import { i18n } from '@/plugins/i18n';
import { CalculationService } from '@/services/calculation.service';
import { DateTimeService } from '@/services/date-time.service';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';
import { useFlightStore } from '@/store/modules/flight.store';

import { NumberColumnFilterSettings, SetColumnFilterSettings, TextColumnFilterSettings, getLoadFactorCellRenderParams } from './base';

const { t } = i18n.global;

export const generateCabinOptimizationTacticsColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.OptimizationTactic),
  headerName: t('active_optimisation_tactic_short'),
  field: 'optimisationTactics',
  minWidth: 80,
  width: 80,
  sortable: true,
  headerClass: 'ag-left-aligned-header',
  cellClass: ({ data }: CellClassParams) => `ag-left-aligned-cell data-test-opt-tactic-key-cell-${data.ondId}`,
  headerTooltip: `${t('active_optimisation_tactic', {
    cabin: cabinCode,
  })}`,
  valueGetter: (params: ValueGetterParams) => getOptimizationTactic(params.data, cabinCode, false),
  comparator: (valueA: string, valueB: string) => compareOptimizationTactics(valueA, valueB),
});

export const generateCabinShadowOptimizationTacticsColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.ShadowTacticsOptimizationTactic),
  headerName: t('shadow_optimisation_tactic_short'),
  field: 'shadowOptimisationTactics',
  minWidth: 80,
  width: 80,
  sortable: true,
  headerClass: 'ag-left-aligned-header',
  cellClass: ({ data }: CellClassParams) => `ag-left-aligned-cell data-test-shadow-opt-tactic-key-cell-${data.ondId}`,
  headerTooltip: `${t('shadow_optimisation_tactic', {
    cabin: cabinCode,
  })}`,
  valueGetter: (params: ValueGetterParams) => getOptimizationTactic(params.data, cabinCode, true),
  comparator: (valueA: string, valueB: string) => compareOptimizationTactics(valueA, valueB),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasShadowTacticsEnabled,
});

function getOptimizationTactic(flightLine: FlightDynamicPropertiesAllowed, cabinCode: string, shadow: boolean): string | undefined {
  const matchingCabin = FlightService.getMatchedCabin(flightLine, cabinCode);
  if (!matchingCabin) {
    return;
  }
  const tactic = shadow ? matchingCabin.shadowOptimisationTactics : matchingCabin.optimisationTactics;
  const tacticLevel: IOptimizationProfileLevel | undefined = shadow
    ? matchingCabin.shadowOptimisationProfileLevel
    : matchingCabin.optimisationProfileLevel;

  if (tactic !== OptimisationTactic.Manual && tacticLevel) {
    return `${tacticLevel.name} / ${tacticLevel.level}`;
  }
  return tactic || undefined;
}

function compareOptimizationTactics(valueA: string, valueB: string) {
  const isFirstRowManual = valueA === OptimisationTactic.Manual;
  const isSecondRowManual = valueB === OptimisationTactic.Manual;
  if (isFirstRowManual && isSecondRowManual) {
    // Don't sort so multi sorting doesn't get overridden.
    return 0;
  }
  // 'manual' is sorted separately from the other optimization tactics'
  if (isFirstRowManual) {
    return 1;
  }
  if (isSecondRowManual) {
    return -1;
  }
  return StringOrNumberComparator(valueA, valueB, undefined, undefined, false);
}

export const generateCabinLoadFactorColumn = (cabinCode: string, lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinLoadFactor),
  headerName: t('authorized_capacity_load_factor_short'),
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  cellRenderer: 'GridLoadFactorRenderer',
  minWidth: 40,
  width: 40,
  sortable: true,
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      return Number(FormatService.roundNumber(matchingCabin.lidLoadFactor, 1));
    }
  },
  cellRendererParams: (params: ICellRendererParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      return getLoadFactorCellRenderParams(matchingCabin.lidLoadFactor, lafLoadFactorColoring, 'cabin');
    }
  },
  headerTooltip: t('lid_load_factor'),
});

export const generateCabinCapacityLoadFactorColumn = (cabinCode: string, lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinCapacityLoadFactor),
  headerName: t('saleable_capacity_load_factor_short'),
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  cellRenderer: 'GridLoadFactorRenderer',
  minWidth: 40,
  width: 40,
  sortable: true,
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      return Number(FormatService.roundNumber(matchingCabin.capacityLoadFactor, 1));
    }
  },
  cellRendererParams: (params: ICellRendererParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      return getLoadFactorCellRenderParams(matchingCabin.capacityLoadFactor, lafLoadFactorColoring, 'cabin');
    }
  },
  headerTooltip: t('capacity_load_factor'),
});

export enum lafKeyType {
  LowestAvailableFareClass = 'lowestAvailableFareClass',
  DeltaLowestAvailableFareClass = 'deltaLowestAvailableFareClass',
  CapturedLowestAvailableFareClass = 'capturedLowestAvailableFareClass',
  RecommendedLowestAvailableFareClass = 'recommendedLowestAvailableFareClass',
  ShadowRecommendedLowestAvailableFareClass = 'shadowRecommendedLowestAvailableFareClass',
}

export enum seatAvailabilityKeyType {
  SeatAvailability = 'seatAvailability',
  RecommendedSeatAvailability = 'recommendedSeatAvailability',
  ShadowRecommendedSeatAvailability = 'shadowRecommendedSeatAvailability',
}

export const generateCabinLowestAvailableFareColumn = (
  cabinCode: string,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
  classesByCabin: Map<string, ClassStructure[]>,
): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.LowestAvailableFareClass),
  headerName: t('lowest_available_fare_short'),
  type: 'rightAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-laf-key-cell-${data.ondId} marginless-cell`,
  cellRenderer: 'GridLafRenderer',
  minWidth: 35,
  width: 35,
  sortable: true,
  comparator: (valueA: string, valueB: string, nodeA: IRowNode | undefined, nodeB: IRowNode | undefined, isInverted) =>
    classCodeComparator(getCabinClassCodeComparatorParams(cabinClasses, cabinCode, isInverted, valueA, valueB, nodeA, nodeB)),
  headerTooltip: `${t('lowest_available_fare', {
    cabin: cabinCode,
  })}`,
  cellRendererParams: (params: ICellRendererParams) =>
    getLafCellRendererParams(params, cabinCode, classesByCabin.get(cabinCode) ?? [], lafLoadFactorColoring),
  valueGetter: (params: ValueGetterParams): string => FlightService.getMatchedCabin(params.data, cabinCode)?.lowestAvailableFareClass ?? '',
});

export const generateCabinDeltaLowestAvailableFareColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.DeltaLowestAvailableFareClass),
  headerName: t('delta_lowest_available_fare_short'),
  type: 'rightAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-delta-laf-key-cell-${data.ondId} marginless-cell`,
  cellRenderer: 'GridDeltaLafRenderer',
  minWidth: 35,
  width: 35,
  sortable: true,
  headerTooltip: `${t('delta_lowest_available_fare', {
    cabin: cabinCode,
  })}`,
  requiredPermission: ({ customerSettings }) => !!customerSettings.useGoldModel,
  cellRendererParams: (params: ICellRendererParams) =>
    getDeltaLafCellRendererParams(
      params,
      cabinCode,
      lafKeyType.LowestAvailableFareClass,
      lafKeyType.DeltaLowestAvailableFareClass,
      lafKeyType.CapturedLowestAvailableFareClass,
    ),
  valueGetter: (params: ValueGetterParams): number | null => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      if (typeof matchingCabin.deltaLowestAvailableFareClass === 'number') {
        return Number(matchingCabin.deltaLowestAvailableFareClass);
      }
    }
    return null;
  },
});

export const generateCabinRecommendedLowestAvailableFareColumn = (
  cabinCode: string,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
  classListForColoring: Map<string, ClassStructure[]>,
): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.RecommendedLowestAvailableFareClass),
  headerName: t('active_recommended_lowest_available_fare_short'),
  type: 'rightAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-rlaf-key-cell-${data.ondId} marginless-cell`,
  cellRenderer: 'GridLafRenderer',
  minWidth: 35,
  width: 35,
  sortable: true,
  comparator: (valueA: string, valueB: string, nodeA: IRowNode | undefined, nodeB: IRowNode | undefined, isInverted) =>
    classCodeComparator(getCabinClassCodeComparatorParams(cabinClasses, cabinCode, isInverted, valueA, valueB, nodeA, nodeB)),
  headerTooltip: `${t('active_recommended_lowest_available_fare', {
    cabin: cabinCode,
  })}`,
  cellRendererParams: (params: ICellRendererParams) =>
    getGenericLafCellRendererParams(
      params,
      cabinCode,
      lafKeyType.RecommendedLowestAvailableFareClass,
      seatAvailabilityKeyType.RecommendedSeatAvailability,
      classListForColoring.get(cabinCode) ?? [],
      lafLoadFactorColoring,
    ),
  valueGetter: (params: ValueGetterParams): string =>
    getGenericLafValueGetter(params, cabinCode, lafKeyType.RecommendedLowestAvailableFareClass),
});

export const generateCabinShadowRecommendedLowestAvailableFareColumn = (
  cabinCode: string,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
  classListForColoring: Map<string, ClassStructure[]>,
): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.ShadowRecommendedLowestAvailableFareClass),
  headerName: t('shadow_recommended_lowest_available_fare_short'),
  type: 'rightAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-shadow-rlaf-key-cell-${data.ondId} marginless-cell`,
  cellRenderer: 'GridLafRenderer',
  minWidth: 35,
  width: 35,
  sortable: true,
  comparator: (valueA: string, valueB: string, nodeA: IRowNode | undefined, nodeB: IRowNode | undefined, isInverted) =>
    classCodeComparator(getCabinClassCodeComparatorParams(cabinClasses, cabinCode, isInverted, valueA, valueB, nodeA, nodeB)),
  headerTooltip: `${t('shadow_recommended_lowest_available_fare', {
    cabin: cabinCode,
  })}`,
  cellRendererParams: (params: ICellRendererParams) =>
    getGenericLafCellRendererParams(
      params,
      cabinCode,
      lafKeyType.ShadowRecommendedLowestAvailableFareClass,
      seatAvailabilityKeyType.ShadowRecommendedSeatAvailability,
      classListForColoring.get(cabinCode) ?? [],
      lafLoadFactorColoring,
    ),
  valueGetter: (params: ValueGetterParams): string =>
    getGenericLafValueGetter(params, cabinCode, lafKeyType.ShadowRecommendedLowestAvailableFareClass),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasShadowTacticsEnabled,
});

function getCabinClassCodeComparatorParams(
  cabinClasses: ClassStructure[],
  cabinCode: string,
  isInverted: boolean,
  valueA?: string,
  valueB?: string,
  nodeA?: IRowNode,
  nodeB?: IRowNode,
): ClassCodeComparatorParams {
  return {
    classCodes: CabinService.getCabinLafClasses({
      cabinClasses,
    }).map((cls) => cls.code),
    classCodeA: valueA,
    classCodeB: valueB,
    seatAvailabilityA: nodeA
      ? FlightService.getLafSaForCabin({
          cabinCode,
          flightLine: nodeA.data,
        })
      : undefined,
    seatAvailabilityB: nodeB
      ? FlightService.getLafSaForCabin({
          cabinCode,
          flightLine: nodeB.data,
        })
      : undefined,
    isInverted: isInverted,
  };
}

export interface LafCellRendererParams {
  soldOut?: boolean;
  lafClass: string;
  lafSa?: number | undefined;
  lafColor: string | undefined;
}

function getGenericLafCellRendererParams(
  params: ICellRendererParams<FlightDynamicPropertiesAllowed>,
  cabinCode: string,
  lafKey: lafKeyType,
  seatAvailabilityKey: seatAvailabilityKeyType,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
): LafCellRendererParams | undefined {
  const colorScheme = generateLafColorScheme(lafLoadFactorColoring, CabinService.getCabinLafClasses({ cabinClasses }));
  const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
  if (matchingCabin) {
    const lafClassCode = matchingCabin[lafKey] as string;
    const lafClass: FlightLineCabinClass | undefined = matchingCabin.classes.find((cls) => cls.code === lafClassCode);
    const lafSeatAvailability: number | undefined = lafClass ? lafClass[seatAvailabilityKey] : undefined;

    let lafColor = undefined;
    if (params.value && lafLoadFactorColoring !== LafLoadFactorColoring.OFF) {
      const matchingClass = colorScheme.find((item) => item.class === params.value);
      if (matchingClass) {
        lafColor = matchingClass.color;
      }
    }
    return {
      soldOut:
        !matchingCabin.classes[0] ||
        !!(matchingCabin.classes[0].minCabinSeatAvailability && matchingCabin.classes[0].minCabinSeatAvailability < 1),
      lafClass: matchingCabin[lafKey] as string,
      lafSa: lafSeatAvailability,
      lafColor,
    };
  }
}

function getGenericLafValueGetter(params: ValueGetterParams, cabinCode: string, lafKey: lafKeyType): string {
  const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
  if (
    matchingCabin &&
    (!matchingCabin.classes[0] ||
      (matchingCabin.classes[0].minCabinSeatAvailability && matchingCabin.classes[0].minCabinSeatAvailability < 1))
  ) {
    return '';
  }
  return matchingCabin ? (matchingCabin[lafKey] as string) : '';
}

function getLafCellRendererParams(
  params: ICellRendererParams<FlightDynamicPropertiesAllowed>,
  cabinCode: string,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
): LafCellRendererParams | undefined {
  const colorScheme = generateLafColorScheme(lafLoadFactorColoring, CabinService.getCabinLafClasses({ cabinClasses }));
  const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
  if (matchingCabin) {
    const lafClassCode = matchingCabin[lafKeyType.LowestAvailableFareClass] as string;
    const lafSeatAvailability = getLafSeatAvailability(matchingCabin, lafClassCode);

    let lafColor = undefined;
    if (params.value && lafLoadFactorColoring !== LafLoadFactorColoring.OFF) {
      const matchingClass = colorScheme.find((item) => item.class === params.value);
      if (matchingClass) {
        lafColor = matchingClass.color;
      }
    }

    return {
      soldOut: (matchingCabin?.seatAvailability ?? 0) < 1,
      lafClass: lafClassCode,
      lafSa: lafSeatAvailability,
      lafColor,
    };
  }
}

function getLafSeatAvailability(matchingCabin: FlightLineCabin | undefined, lafClassCode: string): number | undefined {
  if (!!matchingCabin && !!matchingCabin.lowestAvailableFareClassSeatAvailability) {
    return matchingCabin.lowestAvailableFareClassSeatAvailability;
  } else {
    const lafClass: FlightLineCabinClass | undefined = matchingCabin?.classes.find((cls) => cls.code === lafClassCode);
    return lafClass ? lafClass[seatAvailabilityKeyType.SeatAvailability] : undefined;
  }
}

export interface DeltaLafCellRendererParams {
  lafClass: string;
  deltaLaf: number | null;
  capturedLaf: number | null;
  deltaLafColor: string | undefined;
}

function getDeltaLafCellRendererParams(
  params: ICellRendererParams<FlightDynamicPropertiesAllowed>,
  cabinCode: string,
  lafKey: lafKeyType,
  deltaLafKey: lafKeyType,
  capturedLafKey: lafKeyType,
): DeltaLafCellRendererParams | undefined {
  const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
  if (matchingCabin) {
    const colorScheme = useControlStore().generateDeltaLafColorScheme;
    const lafColor = colorScheme.find((item) => item.deltaLaf === (matchingCabin[deltaLafKey] as number | null))?.color;
    return {
      lafClass: matchingCabin[lafKey] as string,
      deltaLaf: matchingCabin[deltaLafKey] as number | null,
      capturedLaf: matchingCabin[capturedLafKey] as number | null,
      deltaLafColor: lafColor,
    };
  }
}

export const generateCabinSeatAvailabilityColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.SeatAvailability),
  headerName: t('seat_availability_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  headerTooltip: t('cabin_seat_availability', {
    cabin: cabinCode,
  }),
  valueGetter: (params: ValueGetterParams) => FlightService.getMatchedCabin(params.data, cabinCode)?.seatAvailability,
  valueFormatter: seatAvailabilityFormatter,
});

export const generateCabinAutopilotColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.Autopilot),
  headerName: t('autopilot_short'),
  minWidth: 40,
  width: 40,
  sortable: true,
  hide: true,
  type: 'leftAligned',
  cellClass: ({ data }: CellClassParams) => `ag-left-aligned-cell data-test-autopilot-key-cell-${data.ondId}`,
  headerTooltip: t('autopilot'),
  cellStyle: (params: CellClassParams) => (params.value ? { color: '#409EFF' } : params.value === false ? { color: '#909399' } : undefined),
  valueGetter: (params: ValueGetterParams): boolean => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin?.autopilot ?? false;
  },
  valueFormatter: (params: ValueFormatterParams<FlightLineModel, boolean>): string => (params.value ? t('on') : t('off')),
  filterParams: {
    valueFormatter: (params: ValueFormatterParams<null, string>): string => (params.value ? t('on') : t('off')),
  },
});

export const generateCabinLowestAvailableFarePriceColumn = (cabinCode: string, fareSource: FareSource): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-laf-fare-price`,
  headerName: t('own_fare'),
  minWidth: 60,
  width: 60,
  sortable: true,
  type: 'numericColumn',
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    if (params.data.cabins && params.data.cabins.length) {
      return getCabinFare(params.data, cabinCode, fareSource);
    }
    return undefined;
  },
  valueFormatter: (params: ValueFormatterParams) => {
    const fare = params.value;
    if (isFinite(fare)) {
      return FormatService.formatNumber(fare);
    }

    return '';
  },
  headerTooltip: `${t('cabin_x_fare_value', {
    cabin: cabinCode,
  })}`,
});

const getCompetitorFareValue = (matchingCabin: FlightLineCabin | undefined, competitorCarrierCode: string) => {
  if (matchingCabin?.competitiveFares) {
    const competitorData = first(
      FlightService.getSortedFares(matchingCabin.competitiveFares.filter((cf) => cf.carrier === competitorCarrierCode)),
    );
    if (competitorData) {
      return competitorData.fare;
    }
  }
  return;
};

const getCompetitorFarePercentageValue = (matchingCabin: FlightLineCabin | undefined, competitorCarrierCode: string) => {
  if (matchingCabin?.competitiveFares) {
    const competitorData = first(
      FlightService.getSortedFares(matchingCabin.competitiveFares.filter((cf) => cf.carrier === competitorCarrierCode)),
    );
    if (competitorData) {
      return competitorData.deltaPercentage;
    }
  }
  return;
};

const generateCabinMinCompetitorFareColumn = (cabinCode: string, competitorCarrierCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  headerName: t('min_fare_of_carrier_short', {
    carrier: competitorCarrierCode,
  }),
  headerClass: `ag-right-aligned-header data-test-header-${cabinCode}-${competitorCarrierCode}-cabin-competitor-fare`,
  headerTooltip: t('min_fare_of_carrier', {
    carrier: competitorCarrierCode,
  }),
  colId: generateCabinLevelMinCompetitorColumnId(cabinCode, competitorCarrierCode, ColumnId.CabinCompetitorFare),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-cabin-min-competitor-fare-${competitorCarrierCode}-${data.ondId}`,
  minWidth: 55,
  width: 55,
  sortable: true,
  type: 'numericColumn',
  valueFormatter: (params: ValueFormatterParams) => {
    const value = params.value;

    if (isFinite(value)) {
      return FormatService.amountWithoutCurrency(value, params.data.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    if (isEmpty(params.data.cabins)) {
      return;
    }
    const cabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return getCompetitorFareValue(cabin, competitorCarrierCode);
  },
});

const generateCabinCompetitorFareDifferenceColumn = (
  cabinCode: string,
  competitorCarrierCode: string,
  ownFareSource: FareSource,
): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelCompetitorColumnId(cabinCode, competitorCarrierCode, ColumnId.CabinCompetitorFare),
  headerName: t('delta_min_fare_of_carrier_short', {
    carrier: competitorCarrierCode,
  }),
  headerClass: 'ag-right-aligned-header',
  headerTooltip: t('delta_min_fare_of_carrier'),
  minWidth: 55,
  width: 55,
  sortable: true,
  type: 'numericColumn',
  cellRenderer: 'GridDifferenceRenderer',
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    if (isEmpty(params.data.cabins)) {
      return;
    }
    const cabin = FlightService.getMatchedCabin(params.data, cabinCode);
    const carrierLowestCf = getCompetitorFareValue(cabin, competitorCarrierCode);

    if (cabin && carrierLowestCf) {
      const farePrice = getFareForCabin(cabin, ownFareSource);

      if (carrierLowestCf && farePrice) {
        return carrierLowestCf - farePrice;
      }
    }

    return;
  },
});

export const generateCabinCompetitorFarePercentageDifferenceColumn = (
  cabinCode: string,
  competitorCarrierCode: string,
): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelPercentageDifferenceCompetitorColumnId(cabinCode, competitorCarrierCode, ColumnId.CabinCompetitorFare),
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-min-cf-delta-percentage-${data.ondId}`,
  headerName: t('delta_percentage_min_fare_of_carrier_short', {
    carrier: competitorCarrierCode,
  }),
  headerTooltip: t('delta_percentage_min_fare_of_carrier'),
  minWidth: 55,
  width: 55,
  sortable: true,
  type: 'numericColumn',
  cellRenderer: 'GridDifferenceRenderer',
  cellRendererParams: {
    zeroIsNeutral: true,
    numberOfDecimals: 1,
    suffix: '%',
  },
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams<FlightLineModel>): number | undefined => {
    if (!params.data || isEmpty(params.data?.cabins)) {
      return;
    } else {
      const cabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return getCompetitorFarePercentageValue(cabin, competitorCarrierCode);
    }
  },
});

export const generateCabinCompetitorFareColumns = (
  cabinCode: string,
  competitorCarrierCodes: string[],
  ownFareSource: FareSource,
): ColDef[] => {
  if (isEmpty(competitorCarrierCodes)) {
    return [];
  }

  return competitorCarrierCodes.flatMap((competitorCarrierCode) => [
    generateCabinMinCompetitorFareColumn(cabinCode, competitorCarrierCode),
    generateCabinCompetitorFareDifferenceColumn(cabinCode, competitorCarrierCode, ownFareSource),
    generateCabinCompetitorFarePercentageDifferenceColumn(cabinCode, competitorCarrierCode),
  ]);
};

export const generateCabinCompetitiveFareColumn = (cabinCode: string, ownFareSource: FareSource): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-competitive-fare`,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-competitive-fare-${data.ondId}`,
  headerName: t('min_cf_short'),
  headerTooltip: t('min_cf'),
  headerClass: `ag-right-aligned-header data-test-header-${cabinCode}-competitive-fare`,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  sortable: true,
  cellRenderer: 'GridInventoryTacticsCompetitorFareCell',
  comparator: StringOrNumberComparator,
  cellRendererParams: (params: ICellRendererParams<FlightLineModel>) => {
    if (params.data) {
      const matchedCabin = FlightService.getMatchedCabin(params.data, cabinCode);

      if (matchedCabin) {
        const farePrice = getFareForCabin(matchedCabin, ownFareSource);
        const lowestFare = matchedCabin.competitiveFares ? first(FlightService.getSortedFares(matchedCabin.competitiveFares)) : undefined;

        return {
          cabinCode,
          farePrice,
          lowestFare,
          departureDateTime: DateTimeService.getDate({
            date: params.data.departureDate + 'T' + params.data.departureTime,
            format: Constants.DEFAULT_DATE_FORMAT + 'T' + Constants.DEFAULT_TIME_FORMAT,
          }),
        };
      }
    }
  },
  valueGetter: (params: ValueGetterParams<FlightLineModel>): number | undefined => {
    if (params.data?.cabins?.length) {
      const cabin = params.data.cabins.find((flightLineCabin: FlightLineCabin) => flightLineCabin.code === cabinCode);
      return cabin ? FlightService.getMinCompetitiveFareOfCabin(cabin) : undefined;
    }
  },
  valueFormatter: (params: ValueFormatterParams<FlightLineModel>) => formatNumber(params.value),
});

export const generateCabinMinRivalFareColumn = (cabinCode: string, ownFareSource: FareSource): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-rival-fare`,
  headerName: t('min_rf_short'),
  headerTooltip: t('min_rf'),
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  sortable: true,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-min-rival-fare-${data.ondId}-${cabinCode}`,
  cellRenderer: 'GridInventoryTacticsRivalFareCell',
  comparator: StringOrNumberComparator,
  cellRendererParams: (params: ICellRendererParams) => {
    const matchedCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchedCabin) {
      const farePrice = getFareForCabin(matchedCabin, ownFareSource);
      const lowestFare = matchedCabin.rivalFares ? first(FlightService.getSortedFares(matchedCabin.rivalFares)) : undefined;

      return {
        cabinCode,
        farePrice,
        lowestFare,
        departureDateTime: DateTimeService.getDate({
          date: params.data.departureDate + 'T' + params.data.departureTime,
          format: Constants.DEFAULT_DATE_FORMAT + 'T' + Constants.DEFAULT_TIME_FORMAT,
        }),
      };
    }
  },
  valueGetter: (params: ValueGetterParams) => {
    if (params.data.cabins && params.data.cabins.length) {
      const cabin = params.data.cabins.find((flightLineCabin: FlightLineCabin) => flightLineCabin.code === cabinCode) as FlightLineCabin;
      const minRivalFare = FlightService.getMinRivalFareOfCabin(cabin);
      return minRivalFare || minRivalFare === 0 ? minRivalFare : undefined;
    }
  },
  valueFormatter: ({ value }: ValueFormatterParams<FlightLineModel>) => formatNumber(value),
  requiredPermission(params) {
    return !!params.customerSettings.hasRivalRulesEnabled;
  },
});

export const generateCabinCompetitiveFarePercentageDifferenceColumn = (cabinCode: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-cabin-min-cf-delta-percentage`,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-min-cf-delta-percentage-${data.ondId}`,
  headerName: t('min_cf_difference_percentage_short'),
  headerTooltip: t('min_cf_difference_percentage'),
  headerClass: `ag-right-aligned-header data-test-header-${cabinCode}-competitive-fare-difference-percentage`,
  minWidth: 35,
  width: 35,
  cellRenderer: 'GridDifferenceRenderer',
  cellRendererParams: {
    zeroIsNeutral: true,
    numberOfDecimals: 1,
    suffix: '%',
  },
  sortable: true,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams<FlightLineModel>): number | undefined => {
    if (params.data?.cabins && params.data?.cabins.length) {
      const cabin = params.data.cabins.find((flightLineCabin) => flightLineCabin.code === cabinCode);
      return cabin?.minCfDeltaPercentage || undefined;
    }

    return;
  },
});

export const generateCabinRivalFarePercentageDifferenceColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-cabin-min-rf-delta-percentage`,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-min-rf-delta-percentage-${data.ondId}`,
  headerName: t('min_rf_difference_percentage_short'),
  headerTooltip: t('min_rf_difference_percentage'),
  headerClass: `ag-right-aligned-header data-test-header-${cabinCode}-rival-fare-difference-percentage`,
  minWidth: 35,
  width: 35,
  cellRenderer: 'GridDifferenceRenderer',
  cellRendererParams: {
    zeroIsNeutral: true,
    numberOfDecimals: 1,
    suffix: '%',
  },
  sortable: true,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams<FlightLineModel>): number | undefined => {
    if (params.data?.cabins && params.data?.cabins.length) {
      const cabin = params.data.cabins.find((flightLineCabin) => flightLineCabin.code === cabinCode);
      return cabin?.minRfDeltaPercentage ?? undefined;
    }

    return;
  },
});

export const generateCabinCompetitiveFareDifferenceColumn = (cabinCode: string, fareSource: FareSource): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-competitive-fare-difference`,
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-competitive-fare-difference-${data.ondId}`,
  headerName: t('min_cf_difference_short'),
  headerClass: `ag-right-aligned-header data-test-header-${cabinCode}-competitive-fare-difference`,
  type: 'rightAligned',
  minWidth: 35,
  width: 35,
  sortable: true,
  cellRenderer: 'GridDifferenceRenderer',
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams): number | undefined => {
    if (params.data.cabins && params.data.cabins.length) {
      const cabin = params.data.cabins.find((flightLineCabin: FlightLineCabin) => flightLineCabin.code === cabinCode);

      if (!cabin) {
        return undefined;
      }

      const ownFare = getCabinFare(params.data as FlightDynamicPropertiesAllowed, cabinCode, fareSource);
      const lowestFare = cabin.competitiveFares ? first(FlightService.getSortedFares(cabin.competitiveFares)) : undefined;
      if (lowestFare && ownFare) {
        return lowestFare.fare - ownFare;
      }

      return undefined;
    }

    return undefined;
  },
  headerTooltip: `${t('min_cf_difference', {
    cabin: cabinCode,
  })}`,
});

export const generateCabinRivalFareDifferenceColumn = (cabinCode: string, fareSource: FareSource): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-rival-fare-difference`,
  headerName: t('min_rival_fare_difference_short'),
  type: 'numericColumn',
  minWidth: 20,
  width: 20,
  sortable: true,
  cellRenderer: 'GridDifferenceRenderer',
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams): number | undefined => {
    if (params.data.cabins && params.data.cabins.length) {
      const cabin = params.data.cabins.find((flightLineCabin: FlightLineCabin) => flightLineCabin.code === cabinCode) as FlightLineCabin;

      if (!cabin) return undefined;

      const ownFare = getCabinFare(params.data as FlightDynamicPropertiesAllowed, cabinCode, fareSource);
      const lowestFare = cabin.rivalFares ? first(FlightService.getSortedFares(cabin.rivalFares)) : undefined;

      return lowestFare && ownFare ? lowestFare.fare - ownFare : undefined;
    }

    return undefined;
  },
  headerTooltip: t('min_rival_fare_difference', {
    cabin: cabinCode,
  }),
  requiredPermission(params) {
    return !!params.customerSettings.hasRivalRulesEnabled;
  },
});

export const generateCabinSpillageSpoilageColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-sds`,
  headerName: t('sds_short'),
  width: 100,
  minWidth: 100,
  headerTooltip: t('sds'),
  cellRenderer: 'GridSpillageSpoilageRenderer',
  sortable: true,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    const specificCabin = (params.data as FlightDynamicPropertiesAllowed).cabins.find((cabinInGrid) => cabinInGrid.code === cabinCode);
    if (specificCabin && specificCabin.lowestAvailableFareClass) {
      const lf = (specificCabin.maxLegBookings / specificCabin.minLegSaleableCapacity) * 100;
      return Math.round(lf - CalculationService.calculateLafPercentage(specificCabin.code, specificCabin.lowestAvailableFareClass));
    }
    return null;
  },
});

export const generateCabinLoadFactorLafColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  type: 'numericColumn',
  colId: `${cabinCode}-load-factor-laf`,
  headerName: t('lid_lf_laf_short'),
  width: 35,
  minWidth: 35,
  headerTooltip: t('lid_lf_laf'),
  cellRenderer: 'GridLoadFactorLafRenderer',
  sortable: true,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    const specificCabin = (params.data as FlightDynamicPropertiesAllowed).cabins.find((cabinInGrid) => cabinInGrid.code === cabinCode);
    if (specificCabin) {
      return Number(FormatService.roundNumber(specificCabin.lidLoadFactor, 0));
    }
  },
  cellRendererParams: {
    cabinCode: cabinCode,
  },
});

export const CabinCompetitorFaresColumn: ColDef = {
  colId: ColumnId.CabinCompetitorFares,
  headerName: t('min_cf_short'),
  headerTooltip: t('min_cf'),
  type: 'numericColumn',
  width: 55,
  minWidth: 55,
  field: 'competitorFarePrices',
  cellRenderer: 'GridInventoryTacticsCompetitorFareCell',
  comparator: StringOrNumberComparator,
  cellRendererParams: (params: ICellRendererParams<FlightViewLegCabinInventoryTactic>): Partial<CompetitorFareGridModel> => {
    const invTacticRow = params.data;

    return {
      cabinCode: invTacticRow?.cabinCode,
      farePrice: invTacticRow?.ownFare,
      lowestFare: invTacticRow?.competitorFarePrices ? invTacticRow.competitorFarePrices[0] : undefined,
      departureDateTime: invTacticRow?.departureDateTime,
    };
  },
  valueGetter: (params: ValueGetterParams<FlightViewLegCabinInventoryTactic>): number | undefined =>
    params.data?.competitorFarePrices?.[0]?.fare,
  valueFormatter: ({ value }: ValueFormatterParams<FlightViewLegCabinInventoryTactic>): string => formatNumber(value),
};

export const CabinCompetitorFaresDifferenceColumn: ColDef = {
  colId: ColumnId.CabinCompetitorFaresDifference,
  headerName: t('min_cf_difference_short'),
  type: 'numericColumn',
  width: 50,
  minWidth: 50,
  field: 'competitorFarePrices',
  cellRenderer: 'GridDifferenceRenderer',
  headerTooltip: t('min_cf_difference'),
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams<FlightViewLegCabinInventoryTactic>): number | undefined => {
    const invTacticRow = params.data;
    const ownFare = invTacticRow?.ownFare;
    const lowestFare = invTacticRow?.competitorFarePrices?.[0];

    return lowestFare && !isNil(ownFare) ? lowestFare.fare - ownFare : undefined;
  },
};

export const CabinCompetitorFaresPercentageDifferenceColumn: ColDef = {
  colId: ColumnId.CabinCompetitorFaresPercentageDifference,
  headerName: t('min_cf_difference_percentage_short'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-rival-fare-percentage-difference-${data.id}`,
  type: 'numericColumn',
  width: 50,
  minWidth: 50,
  field: 'competitorFarePrices',
  cellRenderer: 'GridDifferenceRenderer',
  headerTooltip: t('min_cf_difference_percentage'),
  cellRendererParams: {
    zeroIsNeutral: true,
    numberOfDecimals: 1,
    suffix: '%',
  },
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams<FlightViewLegCabinInventoryTactic>): number | undefined =>
    params.data?.competitorFarePrices?.[0]?.deltaPercentage,
};

export const CabinRivalFaresColumn: ColDef = {
  colId: ColumnId.CabinRivalFares,
  headerName: t('min_rf_short'),
  headerTooltip: t('min_rf'),
  type: 'numericColumn',
  width: 55,
  minWidth: 55,
  field: 'rivalFarePrices',
  cellRenderer: 'GridInventoryTacticsRivalFareCell',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-rival-fares-${data.id}`,
  cellRendererParams: (params: ICellRendererParams): Partial<RivalFareGridModel> => {
    const invTacticRow = params.data as FlightViewLegCabinInventoryTactic;
    return {
      cabinCode: invTacticRow.cabinCode,
      farePrice: invTacticRow.ownFare,
      lowestFare: invTacticRow.rivalFarePrices?.[0],
      departureDateTime: invTacticRow.departureDateTime,
    };
  },
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams): number | undefined => {
    const invTacticRow = params.data as FlightViewLegCabinInventoryTactic;
    return invTacticRow.rivalFarePrices?.[0]?.fare;
  },
  valueFormatter: ({ value }: ValueFormatterParams) => formatNumber(value),
};

export const CabinRivalFaresDifferenceColumn: ColDef = {
  colId: ColumnId.CabinRivalFaresDifference,
  headerName: t('min_rival_fare_difference_short'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-rival-fare-difference-${data.id}`,
  type: 'numericColumn',
  width: 50,
  minWidth: 50,
  field: 'rivalFarePrices',
  cellRenderer: 'GridDifferenceRenderer',
  headerTooltip: t('min_rival_fare_difference'),
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams): number | undefined => {
    const invTacticRow = params.data as FlightViewLegCabinInventoryTactic;
    const ownFare = invTacticRow.ownFare;
    const lowestFare = invTacticRow.rivalFarePrices ? invTacticRow.rivalFarePrices[0] : undefined;
    if (lowestFare && !isNil(ownFare)) {
      return lowestFare.fare - ownFare;
    } else {
      return undefined;
    }
  },
};

export const CabinRivalFaresPercentageDifferenceColumn: ColDef = {
  colId: ColumnId.CabinRivalFaresPercentageDifference,
  headerName: t('min_rf_difference_percentage_short'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-rival-fare-percentage-difference-${data.id}`,
  type: 'numericColumn',
  width: 50,
  minWidth: 50,
  field: 'rivalFarePrices',
  cellRenderer: 'GridDifferenceRenderer',
  cellRendererParams: {
    zeroIsNeutral: true,
    numberOfDecimals: 1,
    suffix: '%',
  },
  headerTooltip: t('min_rf_difference_percentage'),
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams<FlightViewLegCabinInventoryTactic>): number | undefined =>
    params.data?.rivalFarePrices?.[0]?.deltaPercentage,
};

export type AutopilotColumnCallback = ({
  value,
  params,
}: {
  value: boolean;
  params: ICellRendererParams<{ cabinCode: CabinCode; id: number }>;
}) => void;

const autopilotColumnCallback: AutopilotColumnCallback = ({ value, params }) => {
  const action = new UpdateAutopilotAction(params.data.cabinCode);
  action.setPayload({
    actionType: FlightActionType.updateAutopilot,
    cabinCode: params.data.cabinCode,
    value,
  });

  const flightStore = useFlightStore();

  logger.trackEvent(`Autopilot ${value ? 'On' : 'Off'}`);

  flightStore.addAutopilotAction({
    action,
    flightLineId: params.data.id,
  });
};

export const AutopilotColumn: ColDef = {
  colId: ColumnId.Autopilot,
  headerName: t('autopilot'),
  field: 'autopilot',
  type: 'leftAligned',
  width: 70,
  minWidth: 70,
  cellRenderer: 'GridSwitchRenderer',
  cellRendererParams: {
    actionName: 'Autopilot',
    dataTest: 'flight-details-autopilot-switch',
    onChange: autopilotColumnCallback,
  },
  headerTooltip: t('autopilot'),
};

export type OptimizationTacticColumnSwap = ({
  value,
  cabinCode,
  flightLineId,
  autopilot,
}: {
  value: any;
  cabinCode: CabinCode;
  flightLineId: number;
  autopilot: boolean;
}) => void;

const optimizationTacticColumnSwap: OptimizationTacticColumnSwap = ({ cabinCode, flightLineId, autopilot }) => {
  useFlightStore().addSwapOptimisationProfileAction({ cabinCode, flightLineId, autopilot });
};

export type OptimizationTacticColumnCallback = ({
  value,
  cabinCode,
  flightLineId,
  autopilot,
}: {
  value: any;
  cabinCode: CabinCode;
  flightLineId: number;
  autopilot: boolean;
}) => void;

const optimizationTacticColumnCallback: OptimizationTacticColumnCallback = ({ value, cabinCode, flightLineId, autopilot }) => {
  const action = new AddOptimizationProfileLevelAction(cabinCode, autopilot, value);
  logger.trackEvent('Optimisation Tactic Change', { action });
  useFlightStore().addRemoveOptimizationTacticAction({
    action,
    flightLineId,
    shadow: false,
  });
};

const shadowOptimizationTacticColumnCallback: OptimizationTacticColumnCallback = ({ value, cabinCode, flightLineId }) => {
  const action = new AddShadowOptimizationProfileLevelAction(cabinCode, value);
  logger.trackEvent('Shadow Optimisation Tactic Change', { action });
  useFlightStore().addRemoveOptimizationTacticAction({
    action,
    flightLineId,
    shadow: true,
  });
};

export enum SwapType {
  ToActive = 'toActive',
  ToShadow = 'toShadow',
}

export const OptimizationTacticColumn: ColDef<FlightViewLegCabinInventoryTactic, number | string> = {
  colId: ColumnId.OptimizationTactic,
  headerName: t('optimisation_tactics_short'),
  field: 'optimisationProfileLevelId',
  width: 80,
  minWidth: 80,
  cellRenderer: 'GridOptimisationTacticRenderer',
  cellRendererParams: (params: ICellRendererParams) => ({
    onSwap: optimizationTacticColumnSwap,
    onChange: optimizationTacticColumnCallback,
    optimisationTactics: (params.data as FlightViewLegCabinInventoryTactic).optimisationTactics,
    swapType: SwapType.ToShadow,
  }),
  headerClass: `ag-left-aligned-header`,
  cellClass: 'marginless-cell ag-left-aligned-cell',
  headerTooltip: t('optimisation_tactics'),
  cellDataType: false,
};

export const ShadowTacticsOptimizationTacticColumn: ColDef<FlightViewLegCabinInventoryTactic, number | string> = {
  colId: ColumnId.ShadowTacticsOptimizationTactic,
  headerName: t('optimisation_tactics_short'),
  field: 'shadowOptimisationProfileLevelId',
  width: 80,
  minWidth: 80,
  cellRenderer: 'GridOptimisationTacticRenderer',
  cellRendererParams: (params: ICellRendererParams) => ({
    onSwap: optimizationTacticColumnSwap,
    onChange: shadowOptimizationTacticColumnCallback,
    optimisationTactics: (params.data as FlightViewLegCabinInventoryTactic).shadowOptimisationTactics,
    swapType: SwapType.ToActive,
  }),
  headerClass: `ag-left-aligned-header`,
  cellClass: 'marginless-cell ag-left-aligned-cell',
  headerTooltip: t('optimisation_tactics'),
  cellDataType: false,
};

export type PricingAdjustmentColumnCallback = ({
  value,
  cabinCode,
  flightLineId,
}: {
  value: number;
  cabinCode: CabinCode;
  flightLineId: number;
}) => void;

const pricingAdjustmentColumnCallback: PricingAdjustmentColumnCallback = ({ value, cabinCode, flightLineId }) => {
  const action = new SetPricingAdjustmentAction(cabinCode, value);

  useFlightStore().addAction({
    action,
    flightLineId,
  });
};

export const PricingAdjustmentColumn: ColDef = {
  colId: ColumnId.PricingAdjustment,
  headerName: t('tactic_adjustment_short'),
  type: 'numericColumn',
  field: 'pricingAdjustment',
  minWidth: 156,
  width: 156,
  cellRenderer: 'GridTacticAdjustmentRenderer',
  cellClass: 'marginless-cell',
  headerTooltip: t('tactic_adjustment'),
  cellRendererParams: {
    onChange: pricingAdjustmentColumnCallback,
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasContinuousPricingEnabled,
};

export const PricingTacticColumn: ColDef = {
  colId: ColumnId.PricingTactic,
  headerName: t('pricing_tactic'),
  type: 'numericColumn',
  field: 'pricingTactic',
  minWidth: 120,
  width: 120,
  cellRenderer: 'GridPricingTacticRenderer',
  cellClass: 'marginless-cell',
  headerTooltip: t('pricing_tactic'),
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewLegCabinInventoryTactic;

    const action = new SetPricingTacticAction(data.cabinCode, event.newValue);

    useFlightStore().addAction({
      action,
      flightLineId: data.id,
    });
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasContinuousPricingEnabled,
};

export const PricingAggregationColumn: ColDef = {
  colId: ColumnId.PricingAggregation,
  headerName: t('price_increment_short'),
  type: 'numericColumn',
  field: 'pricingIncrement',
  minWidth: 75,
  width: 75,
  cellRenderer: 'GridPricingAggregationRenderer',
  cellClass: 'marginless-cell',
  headerTooltip: t('price_increment'),
  onCellValueChanged: ({ newValue, data: { cabinCode, id: flightLineId } }: NewValueParams) => {
    const action = new SetPricingIncrementAction(newValue, cabinCode);
    useFlightStore().addAction({ action, flightLineId });
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasContinuousPricingEnabled,
};

export const PromotionColumn: ColDef = {
  colId: ColumnId.Promotion,
  headerName: t('promotion'),
  type: 'numericColumn',
  field: 'promotion',
  minWidth: 80,
  width: 80,
  cellRenderer: 'GridPromotionRenderer',
  cellClass: 'marginless-cell',
  headerTooltip: t('promotion'),
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewLegCabinInventoryTactic;
    const value = event.newValue;
    let action;

    const flightStore = useFlightStore();

    // No value means user removed promotion
    if (isEmpty(value)) {
      action = new RemovePromotionAction(data.cabinCode);

      flightStore.removeAction({
        actionType: FlightActionType.setPromotion,
        flightLineId: data.id,
      });
    } else {
      action = new SetPromotionAction(data.cabinCode, [value[0], value[1]]);

      flightStore.removeAction({
        actionType: FlightActionType.removePromotion,
        flightLineId: data.id,
      });
    }

    flightStore.addAction({
      action,
      flightLineId: data.id,
    });
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasPromotionsEnabled,
};

export const CabinSoldColumn: ColDef = {
  colId: ColumnId.CabinSold,
  headerName: t('sold_short'),
  type: 'numericColumn',
  field: 'sold',
  width: 45,
  minWidth: 45,
  headerTooltip: t('sold'),
};

export const CabinCodeColumn: ColDef<FlightViewLegCabinInventoryTactic> = {
  colId: ColumnId.CabinCode,
  headerName: t('cabin_short'),
  field: 'cabinCode',
  type: 'leftAligned',
  suppressSizeToFit: true,
  lockPosition: true,
  lockPinned: true,
  lockVisible: true,
  minWidth: 40,
  width: 40,
  headerTooltip: t('cabin'),
};

export const generateMaxLegBookingsColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.MaxLegBookings),
  headerName: t('max_leg_bk_short'),
  field: 'maxLegBookings',
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('max_leg_bk'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin ? matchingCabin.maxLegBookings : undefined;
  },
});

export const generateCabinBookingsColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinBookings),
  headerName: t('bookings_short'),
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('bookings'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);

    if (!matchingCabin) {
      return undefined;
    }
    return matchingCabin.realTimeBookings !== undefined ? matchingCabin.realTimeBookings : matchingCabin.bookings;
  },
});

export const generateCabinPssBookingsColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.PssBookings),
  headerName: t('pss_bookings_short'),
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('pss_bookings'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (!matchingCabin) {
      return undefined;
    }
    return typeof matchingCabin.pssBookings === 'number' ? matchingCabin.pssBookings : undefined;
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasPssBookingsColumn,
});

export const generateCabinCapacityColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinAuthorizedCapacity),
  headerName: t('general.capacity_short'),
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('general.capacity'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin ? matchingCabin.minLegSaleableCapacity : undefined;
  },
});

export const generateCabinLidColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinSaleableCapacity),
  headerName: t('general.lid_short'),
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('general.lid'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin ? matchingCabin.minLegAuthorizedCapacity : undefined;
  },
});

export const generateCabinCurrencyColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: `${cabinCode}-currency`,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-currency-${data.ondId}`,
  headerName: t('general.cabin_currency_short'),
  headerTooltip: t('general.cabin_currency'),
  field: 'fareCurrency',
  minWidth: 35,
  width: 35,
  sortable: true,
  type: 'leftAligned',
});

export function generateCabinSummedRevenueColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinSummedRevenue),
    headerName: t('revenue_short'),
    headerTooltip: t('revenue'),
    type: 'numericColumn',
    width: 40,
    minWidth: 40,
    sortable: true,
    comparator: StringOrNumberComparator,
    valueFormatter: (params) => {
      const revenue = params.value;
      if (revenue || revenue === 0) {
        return FormatService.amountWithoutCurrency(revenue, params?.data?.fareCurrency);
      }

      return '';
    },
    valueGetter: (params: ValueGetterParams) =>
      roundNumber(FlightService.getMatchedCabin(params.data, cabinCode)?.totalRevenue?.fare?.amount),
    requiredPermission: ({ customerSettings }): boolean => !!customerSettings.pssCapabilities.hasRevenue,
  };
}

export const generateCabinAverageFareColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.AverageFare),
  headerName: t('average_fare_short'),
  field: InventoryGridModel.averageFare,
  minWidth: 40,
  width: 40,
  type: 'numericColumn',
  hide: false,
  sortable: true,
  headerTooltip: t('average_fare'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const averageFare = params.value;

    if (averageFare || averageFare === 0) {
      return FormatService.amountWithoutCurrency(averageFare, params.data.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && Math.round(matchingCabin.averageFare);
  },
  requiredPermission: ({ customerSettings }): boolean => !!customerSettings.pssCapabilities.hasFareValue,
});

export const generateCabinByosRemainingDemandColumn = (cabinCode: CabinCode): ColDef<SlimFlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.ByosRemainingDemand),
  headerName: t('control.columns.byos_remaining_demand_short'),
  field: 'byosRemainingDemand',
  width: 100,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('control.columns.byos_remaining_demand'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && matchingCabin.byosRemainingDemand;
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasByosForecastEnabled,
});

export const generateCabinByosStandardDeviationColumn = (cabinCode: CabinCode): ColDef<SlimFlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.ByosStandardDeviation),
  headerName: t('control.columns.byos_standard_deviation_short'),
  field: 'byosStandardDeviation',
  width: 100,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('control.columns.byos_standard_deviation'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && matchingCabin.byosStandardDeviation;
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasByosForecastEnabled,
});

export const generateCabinLafFareValueColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinLafFareValue),
  headerName: t('laf_fare_value_short'),
  type: 'numericColumn',
  field: 'lafFareValue',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('laf_fare_value'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const lafFareValue = params.value;

    if (lafFareValue || lafFareValue === 0) {
      return FormatService.amountWithoutCurrency(lafFareValue, params.data.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && matchingCabin.lafFareValue;
  },
  requiredPermission: ({ customerSettings }): boolean => !!customerSettings.pssCapabilities.hasFareValue,
});

export const generateCabinOptimalFinalBookingsColumn = (cabinCode: string): ColDef<SlimFlightLineModel, number> => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinOptimalFinalBookings),
  headerName: t('control.columns.flight_optimal_final_bookings_short'),
  type: 'numericColumn',
  field: 'optimalFinalBookings',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('control.columns.cabin_optimal_final_bookings', { cabin: cabinCode }),
  valueGetter: ({ data }) => (data ? FlightService.getMatchedCabin(data, cabinCode)?.optimalFinalBookings : undefined),
  valueFormatter: ({ value }) => (typeof value === 'number' ? FormatService.formatNumber(value, 1) : ''),
  requiredPermission: ({ customerSettings }) =>
    !!customerSettings.hasForecastingEnabled && !!customerSettings.hasForecastingAndDynamicProgramEnabled,
});

export const generateCabinNDayProjectedBookingsPickupColumns = (
  days: ProjectedBookingsPickupDays[],
  cabinCode: CabinCode,
): ColDef<SlimFlightLineModel>[] =>
  days.map(
    (day): ColDef<SlimFlightLineModel, number> => ({
      ...NumberColumnFilterSettings,
      colId: generateCabinPickupColumnId(cabinCode, 'projected-bookings', day),
      headerName: t('control.columns.projected_bookings_pickup_short', day),
      headerTooltip: t('control.columns.cabin_projected_bookings_pickup', { cabin: cabinCode, day }),
      minWidth: 35,
      width: 35,
      sortable: true,
      valueGetter: ({ data }) =>
        data
          ? FlightService.getMatchedCabin(data, cabinCode)?.projectedBookingPickup?.find((pickup) => day === pickup.dayOffset)?.bookings
          : undefined,
      valueFormatter: ({ value }) => (typeof value === 'number' ? FormatService.formatNumber(value, 1) : ''),
      requiredPermission: ({ customerSettings }) =>
        !!customerSettings.hasForecastingEnabled && !!customerSettings.hasForecastingAndDynamicProgramEnabled,
    }),
  );

export const generateCabinOptimalFinalRevenueColumn = (cabinCode: string): ColDef<SlimFlightLineModel, number> => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinOptimalFinalRevenue),
  headerName: t('control.columns.flight_optimal_final_revenue_short'),
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('control.columns.cabin_optimal_final_revenue', { cabin: cabinCode }),
  valueGetter: ({ data }) => roundNumber(data ? FlightService.getMatchedCabin(data, cabinCode)?.optimalFinalRevenue : undefined),
  valueFormatter: ({ value }) => (typeof value === 'number' ? FormatService.formatNumber(value, 1) : ''),
  requiredPermission: ({ customerSettings }) =>
    !!customerSettings.hasForecastingEnabled && !!customerSettings.hasForecastingAndDynamicProgramEnabled,
});

export const generateCabinRecommendedFloorFareColumn = (
  cabinCode: string,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
): ColDef<SlimFlightLineModel, string | undefined> => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.RecommendedFloorFare),
  headerName: t('control.columns.recommended_floor_fare_short'),
  type: 'rightAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-rff-key-cell-${data.ondId} marginless-cell`,
  cellRenderer: 'GridLafRenderer',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('control.columns.cabin_recommended_floor_fare'),
  comparator: (valueA, valueB, nodeA, nodeB, isInverted) =>
    classCodeComparator(getCabinClassCodeComparatorParams(cabinClasses, cabinCode, isInverted, valueA, valueB, nodeA, nodeB)),
  cellRendererParams: (params: ICellRendererParams<FlightDynamicPropertiesAllowed>) => {
    let lafColor: string | undefined;
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    const floorFare: string | undefined = matchingCabin?.recommendedFloorFare;
    if (lafLoadFactorColoring !== LafLoadFactorColoring.OFF) {
      const colorScheme = generateLafColorScheme(lafLoadFactorColoring, CabinService.getCabinLafClasses({ cabinClasses }));
      lafColor = colorScheme.find((item) => item.class === floorFare)?.color;
    }

    return {
      lafClass: floorFare,
      lafColor,
    };
  },
  valueGetter: (params) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin?.recommendedFloorFare;
  },
  requiredPermission: ({ customerSettings }) =>
    !!customerSettings.hasForecastingEnabled && !!customerSettings.hasForecastingAndDynamicProgramEnabled,
});

export const generateCabinPinnedClassesColumn = (cabinCode: string, cabinClasses: ClassStructure[]): ColDef => {
  // Prepare the options in the filter option dropdown box
  const classes = CabinService.getCabinLafClasses({
    cabinClasses,
  }).map((cls) => cls.code);

  return {
    ...TextColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.PinnedClasses),
    headerName: t('pinned_classes_short'),
    type: 'leftAligned',
    width: 50,
    minWidth: 50,
    sortable: true,
    headerTooltip: t('pinned_classes'),
    valueGetter: (params: ValueGetterParams) => {
      const flight = params.data as FlightLineModel;
      const amountOfPins = flight.pins;

      if (amountOfPins && amountOfPins > 0) {
        const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
        if (matchingCabin) {
          const classCodesWithPins = matchingCabin.classes
            .filter((cls) => cls.isProtectionPinned || cls.isAuthorizationUnitPinned)
            .map((cls) => cls.classCode);

          return classCodesWithPins.join(', ');
        }
      }
      return undefined;
    },
    comparator: (valueA: any, valueB: any, nodeA: IRowNode, nodeB: IRowNode, isInverted) => {
      // If there are values without pins
      if (isEmpty(valueA) && isEmpty(valueB)) {
        return 0;
      }
      if (isEmpty(valueA)) {
        return isInverted ? -1 : 1;
      }
      if (isEmpty(valueB)) {
        return isInverted ? 1 : -1;
      }

      // Otherwise, compare the first/highest class in the cabin
      const firstClassA = classes.indexOf(valueA[0]);
      const firstClassB = classes.indexOf(valueB[0]);

      if (firstClassA < firstClassB) {
        return 1;
      }
      if (firstClassA > firstClassB) {
        return -1;
      }
      if (firstClassA === firstClassB) {
        return 0;
      }

      return 0;
    },
  };
};

export const generateCabinPinnedClassesCountColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.Pins),
  headerName: t('pin_count_column_header'),
  width: 40,
  minWidth: 40,
  type: 'numericColumn',
  hide: false,
  sortable: true,
  headerClass: `ag-left-aligned-header`,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-pin-count-key-cell-${data.ondId}`,
  headerTooltip: t('pins_count'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin?.sumOfPinnedClasses;
  },
});

export const generateCabinPerformanceBandPickupCols = (pickupDays: number[]): ColDef[] => {
  if (isEmpty(pickupDays)) {
    return [];
  }

  return pickupDays.map((pickupDay: number) => {
    const pickupColumn: ColDef = {
      ...NumberColumnFilterSettings,
      headerName: translatePerformanceBandPickupHeader(pickupDay),
      cellRenderer: 'GridDifferenceRenderer',
      minWidth: 25,
      width: 25,
      sortable: false,
      hide: false,
      colId: generateCabinPerfomanceBandPickupColumnId(pickupDay),
      cellClass: 'ag-right-aligned-cell marginless-cell',
      headerTooltip: translatePerformanceBandPickupHeaderTooltip(pickupDay),
      comparator: StringOrNumberComparator,
      valueGetter: (params: ValueGetterParams) => {
        const data = (params.data as FlightViewLegCabinInventoryTactic).performanceBandPickUps?.find(
          (pickup: PerformanceBandPickupModel) => pickup.dayOffset === pickupDay,
        );
        return data?.performanceBandDifference;
      },
    };
    return pickupColumn;
  });
};
