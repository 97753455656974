import { CellClassParams, ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';

import {
  DetailsPickupTypes,
  generateCabinPickupColumnId,
  generateClassPickupColumnId,
  generateFlightPickupColumnId,
} from '@/models/enums/grid';
import { CabinCode } from '@/modules/api/application/application-contracts';
import {
  BookingsPickupModel,
  ByosStatsByDay,
  FlightLineModel,
  PerformanceBandPickupModel,
  SlimFlightLineModel,
} from '@/modules/api/flight/flight-contracts';
import { roundNumber } from '@/modules/shared';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';

import { NumberColumnFilterSettings } from './base';

const { t } = i18n.global;

export type PickupGenerateLevel = 'flight' | 'cabin' | 'class';

export enum ByosProp {
  RemainingDemand = 'remaining_demand',
  StandardDeviation = 'standard_deviation',
}

export const ByosPropConstants: Record<ByosProp, { name: keyof ByosStatsByDay; shortName: string; pickupType: DetailsPickupTypes }> = {
  [ByosProp.RemainingDemand]: { name: 'remaining_demand', shortName: 'rd', pickupType: 'byos-rd' },
  [ByosProp.StandardDeviation]: { name: 'standard_deviation', shortName: 'sd', pickupType: 'byos-sd' },
};

function translateBookingsPickupHeader(pickupDay: number): string {
  return pickupDay === 0
    ? t('shared.real_time_bookings_pickup').toString()
    : t('bookings_pickup_days_short', { days: pickupDay }).toString();
}

function translateBookingsPickupHeaderTooltip(pickupDay: number): string {
  return pickupDay === 0 ? t('shared.real_time_bookings_pickup').toString() : t('bookings_pickup_days', { days: pickupDay }).toString();
}

function getBookingsPickupSource(
  data: SlimFlightLineModel,
  generateLevel: 'flight' | 'class' | 'cabin',
  pickupDay: number,
  cabinCode?: CabinCode,
): BookingsPickupModel | undefined {
  switch (generateLevel) {
    case 'flight':
    case 'class':
      return data?.bookingsPickUps?.find((pickup: BookingsPickupModel) => pickup.dayOffset === pickupDay);
    case 'cabin':
      return cabinCode
        ? FlightService.getMatchedCabin(data, cabinCode)?.bookingsPickUps?.find(
            (pickup: BookingsPickupModel) => pickup.dayOffset === pickupDay,
          )
        : undefined;
    default:
      return undefined;
  }
}

export const generateBookingsPickupCols = (
  generateLevel: PickupGenerateLevel,
  pickupDays: number[],
  cabinCode?: CabinCode,
  sortable = true,
): ColDef<FlightLineModel>[] => {
  if (isEmpty(pickupDays)) {
    return [];
  }

  return pickupDays.map((pickupDay: number) => {
    const pickupColumn: ColDef = {
      ...NumberColumnFilterSettings,
      headerName: translateBookingsPickupHeader(pickupDay),
      cellRenderer: 'GridBookingsPickupRenderer',
      minWidth: 35,
      width: 35,
      sortable,
      hide: false,
      colId:
        generateLevel === 'cabin' && cabinCode
          ? generateCabinPickupColumnId(cabinCode, 'bookings', pickupDay)
          : generateFlightPickupColumnId('bookings', pickupDay),
      cellClass: ({ data }: CellClassParams) => `data-test-cabin-booking-pickups-${pickupDay}-${data.ondId} marginless-cell`,
      headerTooltip: translateBookingsPickupHeaderTooltip(pickupDay),
      comparator: StringOrNumberComparator,
      /**
       * Only allow to return BookingsPickupModel | undefined so the renderer can handle it in the same way.
       */
      valueGetter: (params: ValueGetterParams): number | undefined => {
        const bookingsPickupSource: BookingsPickupModel | undefined = getBookingsPickupSource(
          params.data,
          generateLevel,
          pickupDay,
          cabinCode,
        );

        return bookingsPickupSource ? bookingsPickupSource.bookings - bookingsPickupSource.cancellations : undefined;
      },
      cellRendererParams: (params: ICellRendererParams) => {
        const bookingsPickupSource: BookingsPickupModel | undefined = getBookingsPickupSource(
          params.data,
          generateLevel,
          pickupDay,
          cabinCode,
        );

        return { cabinCode, pickupDay, bookingsPickupSource };
      },
    };

    return pickupColumn;
  });
};

export function translatePerformanceBandPickupHeader(pickupDay: number): string {
  return pickupDay === 0
    ? t('shared.real_time_performance_band_pickup').toString()
    : t('performance_band_pickup_days_short', { days: pickupDay }).toString();
}

export function translatePerformanceBandPickupHeaderTooltip(pickupDay: number): string {
  return pickupDay === 0
    ? t('shared.real_time_performance_band_pickup').toString()
    : t('performance_band_pickup_days', { days: pickupDay }).toString();
}

export const generatePerformanceBandPickupCols = (
  generateLevel: PickupGenerateLevel,
  pickupDays: number[],
  cabinCode?: string,
): ColDef[] => {
  if (isEmpty(pickupDays)) {
    return [];
  }

  return pickupDays.map((pickupDay: number) => {
    const pickupColumn: ColDef = {
      ...NumberColumnFilterSettings,
      headerName: translatePerformanceBandPickupHeader(pickupDay),
      cellRenderer: 'GridDifferenceRenderer',
      minWidth: 35,
      width: 35,
      sortable: true,
      hide: false,
      colId:
        generateLevel === 'cabin' && cabinCode
          ? generateCabinPickupColumnId(cabinCode, 'performance-band', pickupDay)
          : generateFlightPickupColumnId('performance-band', pickupDay),
      cellClass: ({ data }: CellClassParams) =>
        `ag-right-aligned-cell data-test-cabin-performance-band-pick-up-day-${pickupDay}-${data.ondId} marginless-cell`,
      headerTooltip: translatePerformanceBandPickupHeaderTooltip(pickupDay),
      comparator: StringOrNumberComparator,
      valueGetter: (params: ValueGetterParams) => {
        const performanceBandPickupSource = FlightService.getMatchedCabin(params.data, cabinCode)?.performanceBandPickUps?.find(
          (pickup: PerformanceBandPickupModel) => pickup.dayOffset === pickupDay,
        );
        return performanceBandPickupSource?.performanceBandDifference;
      },

      cellRendererParams: {
        cabinCode,
        pickupDay,
      },
    };
    return pickupColumn;
  });
};

export const generateAllByosPickupCols = (
  generateLevel: PickupGenerateLevel,
  pickupDays: number[],
  cabinCode?: string,
  classCode?: string,
): ColDef[] => [
  ...generateByosPickupCols(generateLevel, pickupDays, ByosProp.RemainingDemand, cabinCode, classCode),
  ...generateByosPickupCols(generateLevel, pickupDays, ByosProp.StandardDeviation, cabinCode, classCode),
];

export const generateByosPickupCols = (
  generateLevel: PickupGenerateLevel,
  pickupDays: number[],
  prop: ByosProp,
  cabinCode?: string,
  classCode?: string,
): ColDef[] => {
  if (isEmpty(pickupDays)) {
    return [];
  }

  const byosProp = ByosPropConstants[prop];

  return pickupDays.map((pickupDay: number) => {
    let colId;
    if (generateLevel === 'class' && classCode) {
      colId = generateClassPickupColumnId(classCode, byosProp.pickupType, pickupDay);
    } else if (generateLevel === 'cabin' && cabinCode) {
      colId = generateCabinPickupColumnId(cabinCode, byosProp.pickupType, pickupDay);
    } else {
      colId = generateFlightPickupColumnId(byosProp.pickupType, pickupDay);
    }

    const pickupColumn: ColDef = {
      colId,
      headerName: t(`byos_${byosProp.shortName}_pickup_days_short`, { days: pickupDay }).toString(),
      headerTooltip: t(`byos_${byosProp.shortName}_pickup_days`, { days: pickupDay }).toString(),
      ...NumberColumnFilterSettings,
      minWidth: 35,
      width: 35,
      sortable: true,
      hide: false,
      cellClass: ({ data }: CellClassParams) =>
        `ag-right-aligned-cell data-test-cabin-byos-${byosProp.shortName}-pick-up-day-${pickupDay}-${data.ondId} marginless-cell`,
      comparator: StringOrNumberComparator,
      valueGetter: (params: ValueGetterParams): number | undefined => {
        const statByDay = getByosPickupSource(params.data, generateLevel, pickupDay, cabinCode);
        const result = statByDay?.[byosProp.name];
        return roundNumber(result, 1);
      },
    };
    return pickupColumn;
  });
};

function getByosPickupSource(
  data: SlimFlightLineModel,
  generateLevel: 'flight' | 'class' | 'cabin',
  pickupDay: number,
  cabinCode?: string,
): ByosStatsByDay | undefined {
  switch (generateLevel) {
    case 'flight':
    case 'class':
      return data?.byosStatsByDays?.find((pickup: ByosStatsByDay) => pickup.ndo === pickupDay);
    case 'cabin':
      return cabinCode
        ? FlightService.getMatchedCabin(data, cabinCode)?.byosStatsByDays?.find((pickup: ByosStatsByDay) => pickup.ndo === pickupDay)
        : undefined;
    default:
      return undefined;
  }
}
