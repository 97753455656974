<template>
  <div class="alert" :class="variantClass">
    <font-awesome-icon v-if="icon" :icon="['fal', icon]" class="mr-2" />

    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    /** This determines the color */
    variant: {
      default: 'primary',
      type: String,
      required: false,
    },
    icon: {
      default: '',
      type: String,
      required: false,
    },
    loading: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  computed: {
    variantClass(): string {
      switch (this.variant) {
        case 'primary':
          return `alert-primary`;
        case 'green':
          return `alert-green`;
        case 'red':
          return `alert-red`;
        case 'orange':
        default:
          return `alert-orange`;
      }
    },
  },
});
</script>

<style lang="scss">
@tailwind components;

@layer components {
  .alert {
    @apply rounded-md px-5 py-2 border-l-4 shadow-sm text-sm font-medium;
  }

  // Variants
  .alert-primary {
    @apply bg-primary bg-opacity-20 text-primary border-primary;
  }
  .alert-red {
    @apply bg-red bg-opacity-20 text-red border-red;
  }
  .alert-green {
    @apply bg-green bg-opacity-20 text-green border-green;
  }
  .alert-orange {
    @apply bg-orange bg-opacity-20 text-orange border-orange;
  }
}
</style>
