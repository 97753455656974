<template>
  <div>
    <div class="flex justify-end mb-4">
      <el-button
        v-if="useCheckAuthority(['user:create'])"
        id="add-user-btn"
        :icon="Plus"
        type="success"
        data-test="add-user-button"
        @click="onAddUser"
      >
        {{ t('user_management.actions.add_user') }}
      </el-button>
    </div>
    <k-grid
      v-loading="isLoading"
      :grid-options="gridOptions"
      :element-loading-text="t('user_management.states.loading_users')"
      :row-data="users"
      :col-defs="columnDefs"
      data-test="users-and-permissions-overview-table"
    />

    <user-management-modal
      v-if="selectedUser && showModal"
      :edit-mode="editMode"
      :user="selectedUser"
      :users="users"
      @close="showModal = false"
      @user-saved="onUserSaved"
    />
  </div>
</template>

<script lang="ts" setup>
import { Plus } from '@element-plus/icons-vue';
import { ColDef, GridOptions } from 'ag-grid-enterprise';
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { Ref, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Environment } from '@/environment';
import { filterColumnsOnPermission } from '@/models/columns/utils/col-def-with-permission';
import { Authority } from '@/modules/api/auth/auth-contracts';
import { ApiError } from '@/modules/api/base-client';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { useFeaturesStore } from '@/modules/features/store/features.store';
import GridUserActionsRenderer from '@/modules/grid/components/GridUserActionsRenderer.vue';
import { generateUserActionsColumn } from '@/modules/promotions/configuration/overview-columns';
import { handleErrors } from '@/modules/shared';
import KGrid from '@/modules/shared/components/KGrid.vue';
import { useCheckAuthority } from '@/modules/shared/composables';
import UserManagementModal from '@/modules/user-management/components/UserManagementModal.vue';
import UserRolesCellRenderer from '@/modules/user-management/components/UserRolesCellRenderer.vue';
import UserStatusCellRenderer from '@/modules/user-management/components/UserStatusCellRenderer.vue';
import {
  EmailColumn,
  UserGroupsColumn,
  UserNameColumn,
  UserRolesColumn,
  UserStatusColumn,
} from '@/modules/user-management/models/column-definitions/user-management-column-definitions';
import { useUserGroupStore } from '@/modules/user-management/store/user-group.store';
import { useUserManagementStore } from '@/modules/user-management/store/user-management.store';
import { userService } from '@/modules/user-settings/api/user/user.service';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { useUserStore } from '@/modules/user-settings/store/user.store';

const { t } = useI18n();

defineOptions({
  components: {
    GridUserActionsRenderer,
    UserRolesCellRenderer,
    UserStatusCellRenderer,
  },
});

const selectedUser = ref<UserModel | null>(null);
const editMode = ref(false);
const showModal = ref(false);
const isLoading = ref(false);
const columnDefs: Ref<ColDef<UserModel>[]> = ref([]);
const gridOptions: Ref<GridOptions<UserModel> | undefined> = ref();

const userStore = useUserStore();
const { authorities } = storeToRefs(userStore);

const userGroupStore = useUserGroupStore();
const { userGroups } = storeToRefs(userGroupStore);

const userManagementStore = useUserManagementStore();
const { allUsers: users } = storeToRefs(userManagementStore);

const customerSettingsStore = useCustomerSettingsStore();
const { settings } = storeToRefs(customerSettingsStore);

const featuresStore = useFeaturesStore();
const { features } = storeToRefs(featuresStore);

onMounted(async () => {
  gridOptions.value = {
    domLayout: 'normal',
    getRowId: (params: any) => `${params.data.id}`,
  };
  columnDefs.value = filterColumnsOnPermission({
    columns: [UserNameColumn, EmailColumn, UserStatusColumn, UserRolesColumn, UserGroupsColumn],
    customerSettings: settings.value ?? {},
    features: features.value ?? {},
    authorities: authorities.value ?? [],
  });

  if (authorities.value?.includes(Authority.UserUpdate)) {
    columnDefs.value.push({
      ...generateUserActionsColumn([
        {
          icon: ['fal', 'pen'],
          title: t('user_management.actions.edit'),
          action: onEditUser,
        },
      ]),
      headerName: t('user_management.labels.actions'),
    });
  }

  await fetchUsers();
});

async function fetchUsers(forceRefresh?: boolean): Promise<void> {
  try {
    isLoading.value = true;
    if (!users.value?.length || forceRefresh) {
      await userManagementStore.getAllUsers();
    }

    if (!userGroups.value?.length || forceRefresh) {
      await userGroupStore.getUserGroups();
    }
  } catch (e) {
    await handleErrors(e as ApiError);
  } finally {
    isLoading.value = false;
  }
}

function onAddUser(): void {
  selectedUser.value = {
    name: '',
    email: '',
    enrollMFA: false,
    isEnabled: true,
    authorities: [],
    roles: [],
    ssoLogin: Environment.ssoEnabled,
  };

  editMode.value = false;
  showModal.value = true;
}

const onUserSaved = (): Promise<void> => fetchUsers(true);

async function onEditUser(editingUser: UserModel): Promise<void> {
  const user = await userService.getByEmail(editingUser.email);
  selectedUser.value = cloneDeep(user);

  editMode.value = true;
  showModal.value = true;
}
</script>
