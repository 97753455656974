<template>
  <el-dialog
    :width="`${width}px`"
    :close-on-click-modal="false"
    :element-loading-text="loadingState"
    :title="dialogTitle"
    :model-value="dialogVisible"
    class="route-management-user-assignment-dialog"
    :draggable="true"
    @close="closeDialog(false)"
  >
    <k-card v-loading="loading" :title="t('select_users')">
      <el-row>
        <el-select
          v-model="selectedUsers"
          :multiple="true"
          :placeholder="`${t('select_users')}`"
          :reserve-keyword="false"
          collapse-tags
          data-test="assign-user-select"
          filterable
        >
          <el-option
            v-for="user in userOptions"
            :key="user.id"
            :label="user.name"
            :value="user.id"
            :data-test="'select-user-options-' + `${user.name}`"
          />
        </el-select>
      </el-row>

      <k-grid
        v-if="routeManagementAction === RouteManagementActions.ReassignUser"
        class="mt-2"
        :col-defs="getSharedRouteCarrierFlightPathColumns"
        :row-data="selectedRoutes"
        data-test="route-management-overview-table"
      />

      <template #footer>
        <k-card-footer>
          <el-button-group v-if="routeManagementAction === RouteManagementActions.ReassignUser">
            <el-button
              type="primary"
              data-test="route-management-split-button"
              :disabled="selectedUsers.length === 0"
              @click="onRouteAssignmentChange"
            >
              {{ t('actions.apply_changes') }}
            </el-button>

            <el-dropdown>
              <el-button type="primary">
                <el-icon><ArrowDown /></el-icon>
              </el-button>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="true" @click="closeDialog(true)">
                    {{ t('actions.leave_unassigned') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-button-group>

          <span v-else>
            <el-button
              data-test="route-management-apply-changes-button"
              :disabled="selectedUsers.length < 1"
              type="success"
              @click="onRouteAssignmentChange"
            >
              {{ t('actions.apply_changes') }}
            </el-button>
          </span>

          <el-button class="ml-2" @click="closeDialog(false)">
            {{ t('actions.cancel') }}
          </el-button>
        </k-card-footer>
      </template>
    </k-card>
  </el-dialog>
</template>

<script lang="ts" setup>
import { ArrowDown } from '@element-plus/icons-vue';
import { uniqBy } from 'lodash-es';
import { ComputedRef, Ref, computed, defineAsyncComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { getSharedRouteCarrierFlightPathColumns } from '@/models/columns/collections/routes/shared';
import { RouteManagementActionTypeKey, RouteManagementActions } from '@/models/enums';
import { ApiError } from '@/modules/api/base-client';
import { RouteUsers } from '@/modules/route-management/api/routes/routes.contracts';
import { SlimRouteModel } from '@/modules/route-management/store/route-management.store';
import { EddyError, ErrorCode, MessageService, handleErrors } from '@/modules/shared';
import KCard from '@/modules/shared/components/card/KCard.vue';
import KCardFooter from '@/modules/shared/components/card/KCardFooter.vue';
import { AssignRouteModel, UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { userManagementService } from '@/modules/user-settings/api/users/users-management.service';

const KGrid = defineAsyncComponent(() => import('@/modules/shared/components/KGrid.vue'));

export type RouteManagementUserAssignmentDialogProps = {
  dialogVisible?: boolean;
  selectedRoutes: SlimRouteModel[] | AssignRouteModel[];
  selectedUser?: string;
  users?: UserModel[];
  routeManagementAction: RouteManagementActionTypeKey;
  width?: string;
};

type UserSelectOption = { id: number; name: string };

const props = withDefaults(defineProps<RouteManagementUserAssignmentDialogProps>(), {
  dialogVisible: false,
  selectedRoutes: () => [],
  selectedUser: String,
  users: () => [],
  routeManagementAction: RouteManagementActions.AssignUser,
  width: '400',
});

const emit = defineEmits<{
  (event: 'dialogClosed', value: boolean): void;
}>();

const { t } = useI18n();

const selectedUsers: Ref<number[]> = ref([]);
const loading: Ref<boolean> = ref(false);

const dialogTitle: ComputedRef<string> = computed(() => {
  switch (props.routeManagementAction) {
    case RouteManagementActions.AssignUser:
      return t('route_management.titles.add_assignments_for_n_routes', props.selectedRoutes.length);
    case RouteManagementActions.ReassignUser:
      return t('route_management.titles.change_assignments_for_n_routes', { u: props.selectedUser, n: props.selectedRoutes.length });
    case RouteManagementActions.UnassignUser:
      return t('route_management.titles.remove_assignments_for_n_routes', props.selectedRoutes.length);
    default:
      break;
  }

  return '';
});
const loadingState: ComputedRef<string> = computed(() =>
  props.routeManagementAction === RouteManagementActions.UnassignUser
    ? t('route_management.state.removing_assignments')
    : t('route_management.state.adding_assignments'),
);
/**
 * Select options for the user select component.
 * If the action is to assign users, all users are shown.
 * If the action is to remove users, only users that are assigned to the selected routes are shown.
 */
const userOptions: ComputedRef<UserSelectOption[]> = computed(() => {
  const routes = (props.selectedRoutes as typeof props.selectedRoutes & { users: RouteUsers[] }[]) ?? [];
  const uniqueUsersFromSelectedRoutes: RouteUsers[] = uniqBy(
    routes?.flatMap((route) => route.users),
    (user) => user?.id,
  );

  const options =
    props.routeManagementAction === RouteManagementActions.UnassignUser
      ? uniqueUsersFromSelectedRoutes
      : props.users?.map((user) => ({ id: user.id, name: user.name }));

  return options?.sort((a, b) => a.name.localeCompare(b.name)).filter((option): option is UserSelectOption => !!option?.id);
});

function closeDialog(appliedChanges: boolean): void {
  selectedUsers.value = [];
  emit('dialogClosed', appliedChanges);
}

async function onRouteAssignmentChange(): Promise<void> {
  loading.value = true;
  let hasErrors = false;

  for (const userId of selectedUsers.value) {
    switch (props.routeManagementAction) {
      case RouteManagementActions.AssignUser: {
        const routesNotAssignedToTheUser = props.selectedRoutes.filter(
          (route) => !route.users?.map((user: RouteUsers) => user.id).includes(userId),
        );

        if (routesNotAssignedToTheUser.length) {
          try {
            await userManagementService.addRoutes(routesNotAssignedToTheUser, userId);
          } catch (e) {
            hasErrors = true;

            const errors = (e as ApiError).response.data?.errors as EddyError[];
            if (errors[0].errorCode === ErrorCode.UserInactive) {
              MessageService.error(
                t('form.validation_errors.user_inactive', {
                  u: userOptions.value.find((user: RouteUsers) => user.id === userId)?.name ?? '',
                }),
              );
            } else {
              handleErrors(e as ApiError);
            }
          }
        }

        break;
      }
      case RouteManagementActions.UnassignUser: {
        const routesAssignedToTheUser = props.selectedRoutes.filter((route) =>
          route.users?.map((user: RouteUsers) => user.id).includes(userId),
        );

        if (routesAssignedToTheUser.length) {
          try {
            await userManagementService.removeRoutes(routesAssignedToTheUser, userId);
          } catch (e) {
            hasErrors = true;
            handleErrors(e as ApiError);
          }
        }

        break;
      }
      case RouteManagementActions.ReassignUser: {
        try {
          await userManagementService.addRoutes(props.selectedRoutes, userId);
        } catch (e) {
          hasErrors = true;
          handleErrors(e as ApiError);
        }

        break;
      }
      default:
        break;
    }
  }

  loading.value = false;
  if (!hasErrors) {
    closeDialog(true);
  }
}

defineExpose({
  emit,
});
</script>

<style lang="scss" scoped>
.route-management-user-assignment-dialog {
  &__body {
    padding-bottom: 20px;
  }

  &__header {
    word-break: break-word;
  }
}
</style>
