import { ApiClient } from '@/modules/api/base-client';
import { OptimizationProfileLevelDataImportAssignment } from '@/modules/data-import/api/data-import/data-import-contracts';

import { IOptimizationProfileLevelConfiguration, IOptimizationProfiles, ProfileLevelParams } from './optimisation-profiles.contracts';

export class OptimizationProfileService {
  private basePath = 'optimisation-profile';

  public async getAll(): Promise<IOptimizationProfiles> {
    return await ApiClient.get<IOptimizationProfiles>(`${this.basePath}`);
  }

  public async getForFlight(flightKey: string | undefined): Promise<IOptimizationProfiles> {
    return await ApiClient.get<IOptimizationProfiles>(`${this.basePath}`, { flightKey });
  }

  public async bulkAssign(request: OptimizationProfileLevelDataImportAssignment): Promise<void> {
    return await ApiClient.patch<void>(`${this.basePath}/level/bulk-assign`, request);
  }

  public async getOptimizationProfileLevelDetails(params: ProfileLevelParams): Promise<IOptimizationProfileLevelConfiguration> {
    return await ApiClient.get<IOptimizationProfileLevelConfiguration>(
      `${this.basePath}/level/${params.cabinCode}/${params.profileGroupId}/${params.levelId}`,
    );
  }
}

export const optimizationProfileService = new OptimizationProfileService();
