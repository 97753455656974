<template>
  <div class="flex h-full items-center">
    <span v-for="(role, index) in value" :key="role.id">
      <el-tooltip :content="role.description" :show-after="300" placement="top-start">
        <span>
          {{ translateRoleName(role.name) }}
        </span>
      </el-tooltip>
      <span v-if="index !== value.length - 1">,&nbsp;</span>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { ICellRendererParams } from 'ag-grid-enterprise';
import { onMounted, ref } from 'vue';

import { RoleModel } from '@/modules/user-management/api/role/role-contracts';
import { roleService } from '@/modules/user-management/api/role/role-service';

const props = defineProps<{
  params: ICellRendererParams;
}>();
const value = ref<RoleModel[]>([]);

onMounted(() => {
  setValue(props.params);
});

// AG Grid hook not recognized by TS
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function refresh(params: typeof props.params): void {
  setValue(params);
}

function translateRoleName(value: string): string {
  return roleService.translateRole(value);
}

function setValue(params: typeof props.params): void {
  value.value = params.value;
}
</script>
