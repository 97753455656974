import { FormatService } from '@/services/format.service';

export function formatNumber(amount: any, showPosNegSymbol: any = false, numberOfDecimals?: number): string {
  if (typeof amount !== 'number') {
    return '';
  }

  if (typeof numberOfDecimals === 'number') {
    amount = FormatService.roundNumber(amount, numberOfDecimals);
  }

  let formattedNumber = FormatService.formatNumber(Math.abs(amount), numberOfDecimals);

  if (showPosNegSymbol) {
    const prefix = amount > 0 ? '+' : amount < 0 ? '-' : '';
    formattedNumber = prefix + formattedNumber;
  }

  return formattedNumber;
}

export function roundNumber(value: number | undefined, precision: number = 0): number | undefined {
  if (value === undefined || value === null) return value;

  return Number(FormatService.roundNumber(value, precision));
}
