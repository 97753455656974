import 'firebase/compat/auth';

import { ApiClient } from '@/modules/api/base-client';
import {
  IUserGroupManagementService,
  UserGroup,
  UserGroupDefinition,
} from '@/modules/user-management/api/user-group/user-group-management.contracts';

export class UserGroupManagementService implements IUserGroupManagementService {
  private basePath = 'user-groups';

  public async get(): Promise<UserGroup[]> {
    return await ApiClient.get<UserGroup[]>(`${this.basePath}`);
  }

  public async getForControlFilter(): Promise<UserGroupDefinition[]> {
    return await ApiClient.get<UserGroupDefinition[]>(`${this.basePath}/control-filter`);
  }

  public async editUserGroup(userGroup: UserGroup): Promise<void> {
    return await ApiClient.post(`${this.basePath}`, userGroup);
  }

  public async deleteUserGroup(userGroup: UserGroup): Promise<void> {
    await ApiClient.delete<void>(`${this.basePath}/${userGroup.id}`);
  }
}

export const userGroupManagementService = new UserGroupManagementService();
