import { ColumnMenuTab, IFilterOptionDef } from 'ag-grid-enterprise';

import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { LafLoadFactorColoring } from '@/modules/user-settings/api/user/user.contracts';
import { generateLfColorScheme } from '@/modules/user-settings/utils/colors.utils';
import { FormatService } from '@/services/format.service';

const customBlankFilter: IFilterOptionDef = {
  displayKey: 'customBlank',
  displayName: 'Is empty',
  predicate: (filterValue: any, cellValue: any) => cellValue === null || cellValue === undefined,
  numberOfInputs: 0,
};
const customNotBlankFilter: IFilterOptionDef = {
  displayKey: 'customNotBlank',
  displayName: 'Is not empty',
  predicate: (filterValue: any, cellValue: any) => cellValue !== null && cellValue !== undefined,
  numberOfInputs: 0,
};

export const columnCentered = {
  headerClass: 'text-center',
  cellClass: 'flex items-center justify-center',
};

export const BaseColumnFilterSettings = {
  type: 'numericColumn',
  menuTabs: ['filterMenuTab'] as ColumnMenuTab[],
  filterParams: { newRowsAction: 'keep', buttons: ['reset'] },
};

export const TextColumnFilterSettings = {
  ...BaseColumnFilterSettings,
  type: 'textColumn',
  filter: 'agTextColumnFilter',
  filterParams: {
    ...BaseColumnFilterSettings.filterParams,
    filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith', customBlankFilter, customNotBlankFilter],
  },
};

export const NumberColumnFilterSettings = {
  ...BaseColumnFilterSettings,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  filterParams: {
    ...BaseColumnFilterSettings.filterParams,
    comparator: StringOrNumberComparator,
    filterOptions: [
      'equals',
      'notEqual',
      'lessThan',
      'lessThanOrEqual',
      'greaterThan',
      'greaterThanOrEqual',
      'inRange',
      customBlankFilter,
      customNotBlankFilter,
    ],
  },
};

export const LoadfactorFilterSettings = {
  filterParams: {
    filterOptions: ['greaterThan', 'inRange', 'lessThan'],
    defaultOption: 'greaterThan',
  },
};

export const DateColumnFilterSettings = {
  ...BaseColumnFilterSettings,
  type: 'rightAligned',
  filter: 'agDateColumnFilter',
  filterParams: {
    ...BaseColumnFilterSettings?.filterParams,
    filterOptions: ['equals', 'greaterThan', 'lessThan', 'notEqual', 'inRange', customBlankFilter, customNotBlankFilter],
  },
};

export const SetColumnFilterSettings = {
  ...BaseColumnFilterSettings,
  filter: 'agSetColumnFilter',
};

export const BaseTagStyle: Record<string, string> = {
  'align-items': 'center',
  'justify-content': 'flex-end',
  'font-size': '12px',
  display: 'flex',
};

export type LoadFactorType = 'flight' | 'leg' | 'cabin';

export function getLoadFactorCellRenderParams(
  loadFactor: number,
  lafLoadFactorColoring: LafLoadFactorColoring,
  type: LoadFactorType,
): { lf: string; lfColor?: string; type: LoadFactorType } {
  const roundedValue = FormatService.roundNumber(loadFactor, 1);
  const rendererParams: {
    lf: string;
    lfColor?: string;
    type: LoadFactorType;
  } = {
    lf: roundedValue,
    type,
  };

  if (lafLoadFactorColoring !== LafLoadFactorColoring.OFF) {
    const colorScheme = generateLfColorScheme(lafLoadFactorColoring).reverse();
    // Math.min to map loadfactors higher than 100% to 100%
    // minus 1 to match with index
    // Math.max to avoid loadfactors of 0 to map to -1 index
    const matchingLf = colorScheme[Math.max(Math.min(100, Math.round(loadFactor)) - 1, 0)];

    if (matchingLf) {
      rendererParams.lfColor = matchingLf;
    }
  }

  return rendererParams;
}
