import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { RouteName } from '@/modules/shared/types/routes';
import UserManagementUserGroups from '@/modules/user-management/views/UserGroups.vue';
import UserManagementUserList from '@/modules/user-management/views/UserList.vue';
import UserManagement from '@/modules/user-management/views/UserManagement.vue';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

const hasUserGroupsEnabled = (): boolean => {
  const customerSettingsStore = useCustomerSettingsStore();
  return !!customerSettingsStore.settings?.hasUserGroupsEnabled;
};

export const USER_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/user-management',
    name: RouteName.userManagement,
    component: UserManagement,
    redirect: { name: RouteName.userManagementUsers },
    meta: {
      authorities: [Authority.UserRead],
    },
    children: [
      {
        path: 'users',
        name: RouteName.userManagementUsers,
        meta: {
          authorities: [Authority.UserRead],
          title: t('user_management.titles.user_management'),
        },
        component: UserManagementUserList,
      },
      {
        path: 'user-groups',
        name: RouteName.userManagementUserGroups,
        meta: {
          authorities: [Authority.UserGroupsRead],
          isAllowed: hasUserGroupsEnabled,
          title: t('user_management.titles.user_management'),
        },
        component: UserManagementUserGroups,
      },
    ],
  },
];
