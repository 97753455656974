import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import {
  IOptimizationProfile,
  IOptimizationProfileLevelConfiguration,
  IOptimizationProfiles,
  OptimizationProfileLevelKey,
  ProfileLevelParams,
} from '@/modules/control/api/optimisation-profiles.contracts';
import { optimizationProfileService } from '@/modules/control/api/optimisation-profiles.service';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';

export const useOptimisationProfilesStore = defineStore('optimisationProfiles', () => {
  const allOptimisationProfilesDefaultValue: IOptimizationProfiles = { profiles: [], byor: [], dsOp: [] };
  const allOptimisationProfiles: Ref<IOptimizationProfiles> = ref({ ...allOptimisationProfilesDefaultValue });
  const combinedProfiles: ComputedRef<IOptimizationProfile[]> = computed(() => [].concat(...Object.values(allOptimisationProfiles.value)));
  const isLoadingProfiles: Ref<boolean> = ref(false);

  const optimisationProfilesByFlight: Ref<Map<string, IOptimizationProfiles>> = ref(new Map());
  const optimizationProfileLevelDetailsMap: Ref<Map<OptimizationProfileLevelKey, IOptimizationProfileLevelConfiguration>> = ref(new Map());

  const isLoadingDetails: Ref<boolean> = ref(false);

  function getSortedOptimisationProfiles(profiles: IOptimizationProfiles): IOptimizationProfiles {
    return Object.keys(profiles).reduce(
      (acc, profileType): IOptimizationProfiles => {
        const type = profileType as keyof IOptimizationProfiles;

        acc[type] = profiles[type].map(({ id, name, description, active, levels, type }) => ({
          id,
          name,
          description,
          active,
          levels: levels.sort((a, b) => b.level - a.level),
          type,
        }));

        return acc;
      },
      { ...allOptimisationProfilesDefaultValue },
    );
  }

  async function getOptimisationProfileForFlight(flightKey: string): Promise<IOptimizationProfiles | undefined> {
    if (optimisationProfilesByFlight.value.has(flightKey)) {
      return optimisationProfilesByFlight.value.get(flightKey);
    }

    try {
      const response = await optimizationProfileService.getForFlight(flightKey);
      const sortedResponse = getSortedOptimisationProfiles(response);
      optimisationProfilesByFlight.value.set(flightKey, sortedResponse);
      return sortedResponse;
    } catch (e) {
      logger.error(e as Error);
    }
  }

  async function getAll(): Promise<void> {
    try {
      isLoadingProfiles.value = true;
      const response = await optimizationProfileService.getAll();
      allOptimisationProfiles.value = getSortedOptimisationProfiles(response);
    } catch (e) {
      logger.error(e as Error);
    } finally {
      isLoadingProfiles.value = false;
    }
  }

  async function getOptimizationProfileLevelDetails(params: ProfileLevelParams): Promise<void> {
    try {
      isLoadingDetails.value = true;
      const optimizationProfileLevelKey: OptimizationProfileLevelKey = `${params.cabinCode}-${params.profileGroupId}-${params.levelId}`;
      if (!optimizationProfileLevelDetailsMap.value.has(optimizationProfileLevelKey)) {
        const response = await optimizationProfileService.getOptimizationProfileLevelDetails(params);
        optimizationProfileLevelDetailsMap.value.set(optimizationProfileLevelKey, {
          ...response,
          details: response.details.reverse(),
        });
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    } finally {
      isLoadingDetails.value = false;
    }
  }

  function $reset(): void {
    allOptimisationProfiles.value = { ...allOptimisationProfilesDefaultValue };
    optimisationProfilesByFlight.value = new Map();
    optimizationProfileLevelDetailsMap.value = new Map();
    isLoadingDetails.value = false;
    isLoadingProfiles.value = false;
  }

  return {
    allOptimisationProfiles,
    combinedProfiles,
    optimizationProfileLevelDetailsMap,
    isLoadingDetails,
    isLoadingProfiles,
    getOptimisationProfileForFlight,
    getAll,
    getOptimizationProfileLevelDetails,
    $reset,
  };
});
