export enum ComponentName {
  AddSeatsLowestAvailableFareFilterField = 'AddSeatsLowestAvailableFareFilterField',
  AdjustmentRangeFilterField = 'AdjustmentRangeFilterField',
  AircraftFilterField = 'AircraftFilterField',
  AutopilotFilterField = 'AutopilotFilterField',
  CarrierCodeFilterField = 'CarrierCodeFilterField',
  ClusterFilterField = 'ClusterFilterField',
  CustomAuthorizationUnitsManagementField = 'CustomAuthorizationUnitsManagementField',
  CustomDateFilterField = 'CustomDateFilterField',
  CustomInventoryManagementDatePickerField = 'CustomInventoryManagementDatePickerField',
  CustomInventoryManagementField = 'CustomInventoryManagementField',
  CustomInventoryManagementPinField = 'CustomInventoryManagementPinField',
  CustomNumberInputFilterField = 'CustomNumberInputFilterField',
  CustomNumberRangeFilterField = 'CustomNumberRangeFilterField',
  CustomSeatAvailabilityManagementField = 'CustomSeatAvailabilityManagementField',
  CustomSelectFilterField = 'CustomSelectFilterField',
  DatePickerFilterField = 'DatePickerFilterField',
  DateRangeFilterField = 'DateRangeFilterField',
  DayOfWeekFilterField = 'DayOfWeekFilterField',
  DestinationSelectFilterField = 'DestinationSelectFilterField',
  DistributionOptionFilterField = 'DistributionOptionFilterField',
  EventClusterFilterField = 'EventClusterFilterField',
  EventNameFilterField = 'EventNameFilterField',
  FlightNumberRangeFilterField = 'FlightNumberRangeFilterField',
  FlightPathFilterField = 'FlightPathFilterField',
  FlightPickerFilterField = 'FlightPickerFilterField',
  HubFilterField = 'HubFilterField',
  InvertSearchFilterField = 'InvertSearchFilterField',
  LinkedClassRuleSelectFilterField = 'LinkedClassRuleSelectFilterField',
  MatchCompetitorField = 'MatchCompetitorField',
  MoveClusterLevelField = 'MoveClusterLevelField',
  MoveLafField = 'MoveLafField',
  MoveLafUpField = 'MoveLafUpField',
  MoveOptimizationProfileField = 'MoveOptimizationProfileField',
  MoveRecommendedOverbookingRiskPercentageFilterField = 'MoveRecommendedOverbookingRiskPercentageFilterField',
  MoveTacticalAdjustmentField = 'MoveTacticalAdjustmentField',
  OptimisationProfileField = 'OptimisationProfileField',
  OptimizationProfileFilterField = 'OptimizationProfileFilterField',
  OptimizationTacticFilterField = 'OptimizationTacticFilterField',
  OriginSelectFilterField = 'OriginSelectFilterField',
  OverbookingAutopilotField = 'OverbookingAutopilotField',
  OverbookingFilterField = 'OverbookingFilterField',
  PricingAggregationSelect = 'PricingAggregationSelect',
  PricingTacticSelect = 'PricingTacticSelect',
  ResolveLeftoverField = 'ResolveLeftoverField',
  RivalRuleSelectFilterField = 'RivalRuleSelectFilterField',
  RouteGroupFilterField = 'RouteGroupFilterField',
  SetCabinPromotionActionEditor = 'SetCabinPromotionActionEditor',
  SetRecommendedOverbookingRiskPercentageFilterField = 'SetRecommendedOverbookingRiskPercentageFilterField',
  StopsFilterField = 'StopsFilterField',
  SwapOptimisationProfileField = 'SwapOptimisationProfileField',
  TacticAdjustmentSlider = 'TacticAdjustmentSlider',
  TagSelectFilterField = 'TagSelectFilterField',
  TagsFilterField = 'TagsFilterField',
  TagsRemoveField = 'TagsRemoveField',
  ToggleFilterFieldType = 'ToggleFilterFieldType',
  UpdateClusterLevelField = 'UpdateClusterLevelField',
  UserFilterField = 'UserFilterField',
  TourOperatorAllotmentFilterField = 'TourOperatorAllotmentFilterField',
  tourOperatorContractTypeFilterField = 'TourOperatorContractTypeFilterField',
  tourOperatorsFilterField = 'TourOperatorsFilterField',
  userGroupFilterField = 'UserGroupFilterField',

  // Single Inventory Management Bulk Actions
  SetSrcLafField = 'SetSrcLafField',
  MoveSrcLafField = 'MoveSrcLafField', // for both up and down
  SetAllocationField = 'SetAllocationField',
  MoveAllocationField = 'MoveAllocationField', // For both up and down
  ToggleContractStatusField = 'ToggleContractStatusField', // For enabling/disabling Pool contracts
  AddNewContractField = 'AddNewContractField',
}
