import { useAppSettingsStore } from '@/store/modules/app-settings.store';

export class CalculationService {
  public static calculateLafPercentage(cabinCode: string, cabinLowestAvailableFareClass: string): number | undefined {
    const cabinConfig = useAppSettingsStore().inventoryConfigurationProperties.cabins.find((cab) => cab.code === cabinCode);

    if (cabinConfig) {
      const nonDiscreteClasses = cabinConfig.classes.filter((value) => !value.discrete && !value.excludeInLAF && !value.subClass);

      const totalAmountOfClasses = nonDiscreteClasses.length - 1;

      const rankOfLaf = nonDiscreteClasses.findIndex((cls) => cls.code === cabinLowestAvailableFareClass);

      return rankOfLaf > 0 ? 100 - (rankOfLaf / totalAmountOfClasses) * 100 : 100;
    }
  }
}
