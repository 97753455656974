<template>
  <div class="flex items-center h-full">
    <el-button v-if="userActions?.length === 1" @click="onAction(firstUserAction)">
      <font-awesome-icon :icon="firstUserAction.icon" class="mr-1" />
      {{ firstUserAction.title }}
    </el-button>
    <el-dropdown v-else trigger="click" data-test="actions-dropdown" split-button @click="onAction(firstUserAction)" @command="onAction">
      <font-awesome-icon :icon="firstUserAction.icon" class="mr-1" />
      <span>
        {{ firstUserAction.title }}
      </span>

      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="gridAction in otherUserActions"
            :key="gridAction.title"
            :data-test="uniqueIdentifierMap.get(gridAction.title)"
            :command="gridAction"
            :divided="gridAction.divided"
          >
            <font-awesome-icon :icon="gridAction.icon" class="mr-1" />
            {{ gridAction.title }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { ICellRendererParams } from 'ag-grid-enterprise';
import { ComputedRef, computed } from 'vue';

import { GridUserActionModel } from '@/modules/grid/components/grid-user-action-model';

type GridUserActionsRendererProps = ICellRendererParams<{ [key: string]: unknown }, number | undefined> & {
  gridUserActions: GridUserActionModel[];
};

const props = defineProps<{ params: GridUserActionsRendererProps }>();

const userActions: ComputedRef<GridUserActionModel[]> = computed(() => props.params.gridUserActions || []);
const firstUserAction: ComputedRef<GridUserActionModel> = computed(() => userActions.value?.[0]);
const otherUserActions: ComputedRef<GridUserActionModel[]> = computed(() => userActions.value?.slice(1));
const uniqueIdentifierMap: ComputedRef<Map<string, string>> = computed(() => {
  const map = new Map<string, string>();
  userActions.value.forEach((action) => {
    if (props.params.data) {
      const dataIdentifier: string =
        'dropdown-' +
        action.title.toLowerCase().split(' ').join('-') +
        '-' +
        ((action.uniqueIdentifier && props.params.data[action.uniqueIdentifier]) ?? props.params.data.id ?? 'not-unique');
      map.set(action.title, dataIdentifier);
    }
  });
  return map;
});

function onAction(gridUserAction: GridUserActionModel): void {
  gridUserAction.action(props.params?.data);
}
</script>
