import { CellClassParams, ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { RequiredPermissionParams } from '@/models/columns/utils/col-def-with-permission';
import { ColumnId } from '@/models/enums/grid';
import { FlightLegModel, FlightLineModel } from '@/modules/api/flight/flight-contracts';
import { formatNumber, roundNumber } from '@/modules/shared';
import { ScheduleMode } from '@/modules/shared/shared-contracts';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { LafLoadFactorColoring } from '@/modules/user-settings/api/user/user.contracts';
import { generateLfColorScheme } from '@/modules/user-settings/utils/colors.utils';
import { i18n } from '@/plugins/i18n';
import { FormatService } from '@/services/format.service';

import { LoadFactorType, NumberColumnFilterSettings } from './base';

const { t } = i18n.global;

const isManagedNetwork = ({ customerSettings }: RequiredPermissionParams): boolean =>
  customerSettings.scheduleMode === ScheduleMode.MANAGED_NETWORK;

const isGoldModel = ({ customerSettings }: RequiredPermissionParams): boolean => !!customerSettings.useGoldModel;

const flightLeg = (legs: FlightLegModel[] | undefined): FlightLegModel | undefined => (legs?.length === 1 ? legs[0] : undefined);

export const LegSaleableCapacityColumn = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegAircraftSaleableCapacity,
  headerName: t('leg_saleable_capacity_short'),
  type: 'numericColumn',
  field: 'aircraftSaleableCapacity',
  minWidth: 35,
  width: 35,
  sortable: true,
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-sCap-key-cell-${data.ondId}`,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => (params.data.legs?.length === 1 ? params.data.aircraftSaleableCapacity : null),
  headerTooltip: t('leg_saleable_capacity'),
  // allow gui to set aggregations for this column
  enableValue: true,
  // restrict aggregations to sum, min and max
  //   allowedAggFuncs: ['sum', 'min', 'max'],
};

export const LegAuthorizedCapacityColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegAircraftAuthorizedCapacity,
  headerName: t('authorized_capacity_short'),
  type: 'numericColumn',
  field: 'aircraftLid',
  minWidth: 35,
  width: 35,
  sortable: true,
  headerTooltip: t('authorized_capacity'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-aircraft-a-cap-${data.ondId}`,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => (params.data.legs?.length === 1 ? params.data.aircraftLid : null),
};

export const LegLocalBookingsColumn: ColDef<FlightLineModel> = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegLocalBookings,
  headerName: t('control.columns.local_bookings_short'),
  type: 'numericColumn',
  minWidth: 55,
  width: 75,
  sortable: true,
  headerTooltip: t('control.columns.local_bookings'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-local-bookings-${data.ondId}`,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => flightLeg(params.data.legs)?.localBookings,
  requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
};

export const LegConnectingBookingsColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegConnectingBookings,
  headerName: t('control.columns.connecting_bookings_short'),
  type: 'numericColumn',
  minWidth: 55,
  width: 75,
  sortable: true,
  headerTooltip: t('control.columns.connecting_bookings'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-connecting-bookings-${data.ondId}`,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => flightLeg(params.data.legs)?.connectingBookings,
  requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
};

export const LegLocalRevenueColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegLocalRevenue,
  headerName: t('control.columns.local_revenue_short'),
  type: 'numericColumn',
  minWidth: 55,
  width: 75,
  sortable: true,
  headerTooltip: t('control.columns.local_revenue'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-local-revenue-${data.ondId}`,
  comparator: StringOrNumberComparator,
  valueFormatter: (params) => formatNumber(params.value),
  valueGetter: (params: ValueGetterParams) => roundNumber(flightLeg(params.data.legs)?.localRevenue),
  requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
};

export const LegConnectingRevenueColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegConnectingRevenue,
  headerName: t('control.columns.connecting_revenue_short'),
  type: 'numericColumn',
  minWidth: 55,
  width: 75,
  sortable: true,
  headerTooltip: t('control.columns.connecting_revenue'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-connecting-revenue-${data.ondId}`,
  comparator: StringOrNumberComparator,
  valueFormatter: (params) => formatNumber(params.value),
  valueGetter: (params: ValueGetterParams) => roundNumber(flightLeg(params.data.legs)?.connectingRevenue),
  requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
};

export const LegNetworkRevenueColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegNetworkRevenue,
  headerName: t('control.columns.network_revenue_short'),
  type: 'numericColumn',
  minWidth: 55,
  width: 75,
  sortable: true,
  headerTooltip: t('control.columns.network_revenue'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-network-revenue-${data.ondId}`,
  comparator: StringOrNumberComparator,
  valueFormatter: (params) => formatNumber(params.value),
  valueGetter: (params: ValueGetterParams) => roundNumber(flightLeg(params.data.legs)?.networkRevenue),
  requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
};

export const LegAuthorizedCapacityLoadFactorColumn = (lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegAircraftLidLoadFactor,
  headerName: t('authorized_capacity_load_factor_short'),
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  field: 'aircraftLidLoadFactor',
  cellRenderer: 'GridLoadFactorRenderer',
  comparator: StringOrNumberComparator,
  minWidth: 40,
  width: 40,
  sortable: true,
  valueFormatter: (params: ValueFormatterParams) => FormatService.roundNumber(params.value, 1),
  valueGetter: (params: ValueGetterParams) =>
    params.data.legs?.length === 1 ? Number(FormatService.roundNumber(params.data.aircraftLidLoadFactor, 1)) : null,
  cellRendererParams: (params: ICellRendererParams) => {
    const rendererParams: { lf: string; lfColor?: string; type: LoadFactorType } = {
      lf: params.valueFormatted,
      type: 'leg',
    };

    if (lafLoadFactorColoring !== LafLoadFactorColoring.OFF) {
      const colorScheme = generateLfColorScheme(lafLoadFactorColoring).reverse();
      // for load factors above 100%,
      // match them to the highest color
      const matchingLf = colorScheme[Math.max(Math.min(100, Math.round(params.value)) - 1, 0)];

      if (matchingLf) {
        rendererParams.lfColor = matchingLf;
      }
    }

    return rendererParams;
  },
  headerTooltip: t('authorized_capacity_load_factor'),
});

export const LegSaleableCapacityLoadFactorColumn = (lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegAircraftCapacityLoadfactor,
  headerName: t('saleable_capacity_load_factor_short'),
  field: 'aircraftCapacityLoadFactor',
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  comparator: StringOrNumberComparator,
  width: 40,
  minWidth: 40,
  sortable: true,
  valueFormatter: (params: ValueFormatterParams) => FormatService.roundNumber(params.value, 1),
  headerTooltip: t('saleable_capacity_load_factor'),
  cellRenderer: 'GridLoadFactorRenderer',
  valueGetter: (params: ValueGetterParams) =>
    params.data.legs?.length === 1 ? Number(FormatService.roundNumber(params.data.aircraftCapacityLoadFactor, 1)) : null,
  cellRendererParams: (params: ICellRendererParams) => {
    const rendererParams: { lf: string; lfColor?: string; type: LoadFactorType } = {
      lf: params.valueFormatted as string,
      type: 'leg',
    };

    if (lafLoadFactorColoring !== LafLoadFactorColoring.OFF) {
      const colorScheme = generateLfColorScheme(lafLoadFactorColoring).reverse();
      // for load factors above 100%,
      // match them to the highest color
      const matchingLf = colorScheme[Math.max(Math.min(100, Math.round(params.value)) - 1, 0)];

      if (matchingLf) {
        rendererParams.lfColor = matchingLf;
      }
    }

    return rendererParams;
  },
});
