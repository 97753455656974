import { datadogRum } from '@datadog/browser-rum';

import { Environment } from '@/environment';
import { installDatadog } from '@/modules/monitoring/services/datadog-initializer';
import { AnalyticsUser, IAnalytics, IErrorLogger } from '@/modules/monitoring/types/logger.types';

export class DatadogLogService implements IErrorLogger, IAnalytics {
  constructor() {
    installDatadog({
      env: Environment.environment,
      version: Environment.version,
      releaseVersion: Environment.releaseVersion,
      customer: Environment.customer,
    });
  }

  public async error(exception: Error, context: object): Promise<void> {
    datadogRum.addError(exception, context);
  }

  public async trackEvent(event: string, properties?: object | undefined): Promise<void> {
    datadogRum.addAction(event, properties);
  }

  /**
   * Track the user in Datadog. We only track the user ID, as we don't want to store any PII in Datadog.
   */
  public async setUser(user: AnalyticsUser): Promise<void> {
    datadogRum.setUser({
      id: user.id,
    });
  }

  public async clearUser(): Promise<void> {
    datadogRum.clearUser();
  }
}
