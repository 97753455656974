import { ColDef } from 'ag-grid-enterprise';

import { ColumnId } from '@/models/enums/grid';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const IndexColumn: ColDef = {
  colId: ColumnId.Index,
  headerName: t('index'),
  field: 'index',
  suppressHeaderMenuButton: true,
  width: 50,
  minWidth: 50,
  type: 'numericColumn',
  sort: 'asc',
};

export const FlightLineColumn: ColDef = {
  colId: ColumnId.FlightLineCode,
  headerName: t('flight_line_code_short'),
  field: 'flightLine',
  minWidth: 125,
  width: 125,
  type: 'numericColumn',
  suppressHeaderMenuButton: true,
  cellClass: 'data-test-queue-navigation-flightline-column',
};

export const DepartureDateWithoutDiffColumn: ColDef = {
  colId: ColumnId.DepartureDate,
  headerName: t('departure_date_short'),
  field: 'departureDate',
  minWidth: 100,
  width: 100,
  type: 'numericColumn',
  suppressHeaderMenuButton: true,
  headerTooltip: t('general.departure_date'),
};
