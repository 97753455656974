/**
 * @deprecated Prefer not to use this enum! If you want to use it just to get the right field in a ColDef, t
 * then make sure you properly type your ColDef instead!
 * @example 
    ColDef<FlightReviewFlightLineRow> = {
      field: 'totalRevenue',
    }
 */
export enum InventoryGridModel {
  averageFare = 'averageFare',
  authorizationUnits = 'authorizationUnits',
  boardedRevenue = 'boardedRevenue',
  boardings = 'boardings',
  bookings = 'bookings',
  bookingsExGroup = 'bookingsExGroup',
  byosRemainingDemand = 'byosRemainingDemand',
  byosStandardDeviation = 'byosStandardDeviation',
  legBookings = 'legBookings',
  code = 'code',
  discontinuedDate = 'discontinuedDate',
  effectiveDate = 'effectiveDate',
  fareValue = 'fareValue',
  groupBookings = 'groupBookings',
  groupPending = 'groupOptional',
  groupWaitlisted = 'groupWaitlisted',
  isAuthorizationUnitPinned = 'isAuthorizationUnitPinned',
  isAuthorizationUnitsUpdated = 'isAuthorizationUnitsUpdated',
  isProtectionPinned = 'isProtectionPinned',
  isProtectionUpdated = 'isProtectionUpdated',
  maxPrice = 'maxPrice',
  minControl = 'minControl',
  minPrice = 'minPrice',
  negotiatedAllottedSeats = 'negotiatedAllottedSeats',
  parentClass = 'parentClass',
  protection = 'protection',
  publishedFareValue = 'publishedFareValue',
  recommendedAuthorizationUnits = 'recommendedAuthorizationUnits',
  recommendedLowestAvailableFareClass = 'recommendedLowestAvailableFareClass',
  recommendedProtection = 'recommendedProtection',
  recommendedProtectionDifference = 'recommendedProtectionDifference',
  recommendedSeatAvailability = 'recommendedSeatAvailability',
  recommendedSeatAvailabilityDifference = 'recommendedSeatAvailabilityDifference',
  ruleAuthorizationUnits = 'ruleAuthorizationUnits',
  seatAvailability = 'seatAvailability',
  segmentAvailability = 'segmentAvailability',
  segmentLimit = 'segmentLimit',
  shadowRecommendedAuthorizationUnits = 'shadowRecommendedAuthorizationUnits',
  shadowRecommendedProtection = 'shadowRecommendedProtection',
  shadowRecommendedProtectionDifference = 'shadowRecommendedProtectionDifference',
  shadowRecommendedSeatAvailability = 'shadowRecommendedSeatAvailability',
  shadowRecommendedSeatAvailabilityDifference = 'shadowRecommendedSeatAvailabilityDifference',
  sold = 'sold',
  updatedAuthorizationUnits = 'updatedAuthorizationUnits',
  updatedBookings = 'updatedBookings',
  updatedDiscontinuedDate = 'updatedDiscontinuedDate',
  updatedEffectiveDate = 'updatedEffectiveDate',
  updatedMinControl = 'updatedMinControl',
  updatedProtection = 'updatedProtection',
  updatedSeatAvailability = 'updatedSeatAvailability',
  updatedSegmentLimit = 'updatedSegmentLimit',
  updatedWaitlistMax = 'updatedWaitlistMax',
  updatedWaitlistMaxPercentage = 'updatedWaitlistMaxPercentage',
  waitlist = 'waitlist',
  waitlistMax = 'waitlistMax',
  waitlistMaxPercentage = 'waitlistMaxPercentage',
}

export function isCabinClass(classIndex: number): boolean {
  return classIndex === 0;
}
