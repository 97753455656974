export const en = {
  optimization_profile_management: {
    titles: {
      optimization_profile_management: 'Optimization Profile Management',
    },
    states: {
      loading_optimization_profile_groups: 'Loading Optimization Profile Groups',
      no_preview_title: 'No Preview To Show',
      no_preview_message: 'Please make sure to upload OP files. Then, select a group and level to display the preview.',
    },
    labels: {
      selection: 'Group and Level Selection',
      details: 'Details of {profileLevel}',
      no_details: 'No details available',
      choose_a_level: 'Choose a Optimization Profile Level',
      choose_a_level_placeholder: 'Pick a level from the list',
    },
  },
};
