import { CellClassParams, ColDef, ICellRendererParams, NewValueParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { isFinite } from 'lodash-es';

import { ColumnId } from '@/models/enums/grid';
import { FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { RemoveClusterAction } from '@/modules/flight-actions/actions/cabin-actions/remove-cluster-action';
import { UpdateClusterLevelAction } from '@/modules/flight-actions/actions/cabin-actions/update-cluster-level-action';
import { FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';
import { FormatService } from '@/services/format.service';
import { useFlightStore } from '@/store/modules/flight.store';

const { t } = i18n.global;

export const InventoryTacticFlightLineColumn: ColDef<FlightViewLegCabinInventoryTactic> = {
  colId: ColumnId.InventoryTacticFlightLine,
  headerName: t('general.flight_line'),
  field: 'flightLine',
  minWidth: 90,
  width: 90,
  suppressSizeToFit: true,
  lockPosition: true,
  lockPinned: true,
  lockVisible: true,
  valueFormatter: (params: any) => {
    if ((params.data as FlightViewLegCabinInventoryTactic).cabinIndex === 0) {
      return (params.data as FlightViewLegCabinInventoryTactic).flightLine;
    } else {
      return '';
    }
  },
};

export const InventoryTacticCabinLafFareValueColumn: ColDef = {
  colId: ColumnId.CabinLafFareValue,
  headerName: t('laf_fare_value_short'),
  type: 'numericColumn',
  field: 'lafFareValue',
  width: 50,
  minWidth: 50,
  headerTooltip: t('laf_fare_value'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const lafFareValue = params.value;

    if (lafFareValue) {
      return FormatService.amountWithCurrency(lafFareValue, params.data.fareCurrency);
    }

    return '';
  },
};

export const FlightCabinInventoryTacticsCapacity: ColDef = {
  colId: ColumnId.FlightCabinCapacity,
  headerName: t('general.capacity_short'),
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('general.capacity'),
  valueGetter: (params: ValueGetterParams) => params.data.minLegSaleableCapacity,
};

export const FlightCabinInventoryTacticsLid: ColDef = {
  colId: ColumnId.FlightCabinLid,
  headerName: t('general.lid_short'),
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('general.lid'),
  valueGetter: (params: ValueGetterParams) => params.data.minLegAuthorizedCapacity,
};

export const FlightCabinInventoryTacticsPssBookingsColumn: ColDef<FlightViewLegCabinInventoryTactic> = {
  colId: ColumnId.PssBookings,
  headerName: t('pss_bookings_short'),
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('pss_bookings'),
  valueGetter: (params: ValueGetterParams) => params.data.pssBookings,
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasPssBookingsColumn,
};

export const InventoryTacticCabinNegotiatedAllocationsColumn: ColDef<FlightViewLegCabinInventoryTactic> = {
  colId: 'nego-allocations',
  field: 'negotiatedAllocations.totalSoldSeats',
  headerName: t('nego_space'),
  type: 'numericColumn',
  width: 60,
  minWidth: 60,
  cellRenderer: 'GridNegotiatedAllocationsRenderer',
  cellRendererParams: (params: ICellRendererParams) => ({ cabinCode: params.data.cabinCode }),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasSingleInventoryManagementEnabled,
};

export const InventoryTacticCabinTourOperatorsColumn: ColDef<FlightViewLegCabinInventoryTactic, number | undefined> = {
  colId: ColumnId.TourOperators,
  headerName: t('nego_space'),
  type: 'numericColumn',
  width: 60,
  minWidth: 60,
  cellRenderer: 'GridTourOperatorsRenderer',
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasNegoAllocationEnabled && !!customerSettings.hasTourOperatorsV2Enabled,
  valueGetter: (params: ValueGetterParams) => {
    if (params.data.negotiatedAllocations) {
      return params.data.negotiatedAllocations.totalSoldSeats;
    }
  },
};

export const ClusterLevelColumn: ColDef = {
  colId: ColumnId.ClusterLevel,
  headerName: t('cluster'),
  field: 'clusterLevel',
  type: 'leftAligned',
  width: 80,
  minWidth: 80,
  cellRenderer: 'GridClusterLevelRenderer',
  cellClass: 'ag-left-aligned-cell marginless-cell',
  cellRendererParams: (params: ICellRendererParams) => {
    const { cabinCode } = params.data as FlightViewLegCabinInventoryTactic;
    return { cabinCode };
  },
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewLegCabinInventoryTactic;
    const value = event.newValue;
    let action;

    const flightStore = useFlightStore();
    // No value means user removed the cluster
    if (!isFinite(value)) {
      action = new RemoveClusterAction(data.cabinCode);

      flightStore.removeAction({
        actionType: FlightActionType.updateClusterLevel,
        flightLineId: data.id,
      });
    } else {
      action = new UpdateClusterLevelAction(data.cabinCode, event.newValue);

      flightStore.removeAction({
        actionType: FlightActionType.removeCluster,
        flightLineId: data.id,
      });
    }

    flightStore.addAction({
      action,
      flightLineId: data.id,
    });
  },
};

export const ClusterLevelReadOnlyColumn: ColDef<FlightViewLegCabinInventoryTactic> = {
  colId: ColumnId.ClusterLevelReadOnly,
  headerName: t('cluster'),
  field: 'clusterName',
  type: 'leftAligned',
  width: 100,
  minWidth: 100,
};

export const PerformanceScoreColumn: ColDef = {
  colId: ColumnId.PerformanceScore,
  headerName: t('pacing_performance_band_short'),
  headerTooltip: t('pacing_performance_band'),
  type: 'numericColumn',
  width: 70,
  minWidth: 70,
  cellRenderer: 'GridPerformanceRenderer',
  cellClass: ({ data }: CellClassParams) => `data-test-perf-score-key-cell-${data.ondId} marginless-cell`,
  cellRendererParams: (params: ICellRendererParams) => {
    const { performanceLabel, performanceScore } = params.data as FlightViewLegCabinInventoryTactic;
    return {
      performanceLabel,
      performanceScore,
      linkToFlightStoreLoading: true,
    };
  },
};

export const InventoryClassConfigColumn: ColDef<FlightViewLegCabinInventoryTactic> = {
  field: 'classConfigurationName',
  colId: ColumnId.InventoryClassConfig,
  headerName: t('details.flight_cabin_inventory_tactics.columns.class_configuration_short'),
  headerTooltip: t('details.flight_cabin_inventory_tactics.columns.class_configuration'),
  minWidth: 35,
  width: 35,
};

export const BidPriceColumn: ColDef = {
  colId: ColumnId.BidPrice,
  cellClass: ({ data }: CellClassParams) => `data-test-bid-price-${data.ondId} marginless-cell`,
  headerName: t('details.flight_cabin_inventory_tactics.columns.bid_price'),
  headerClass: 'ag-left-aligned-header',
  width: 70,
  minWidth: 70,
  cellRenderer: 'GridBidPriceRenderer',
  requiredPermission: ({ customerSettings }) =>
    !!customerSettings.hasForecastingEnabled && !!customerSettings.hasForecastingAndDynamicProgramEnabled,
};
