import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { FeaturesModel } from '@/modules/features';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';

type BasePermissionParams = {
  customerSettings: Partial<CustomerSettingsModel<UiAndApiPssCapabilities>>;
  features: FeaturesModel;
  authorities?: Authority[];
};

export type RequiredPermissionParams = BasePermissionParams & {
  colDef: ColDef;
};

export type RequiredPermissionParamsWithColumns = BasePermissionParams & {
  columns: (ColDef | ColGroupDef)[];
};

/**
 * String literal type for the requiredPermission property based on Customer Settings and Features.
 * Alternatively, a custom function can be used to determine if the column should be displayed.
 */
export type RequiredPermission = (params: RequiredPermissionParams) => boolean;

/**
 * This is a global augmentation to add the requiredPermission property to the ColDef and ColGroupDef type.
 * This works because of Declaration Merging.
 */

declare module 'ag-grid-enterprise' {
  interface AbstractColDef {
    /**
     * Custom handler to determine if the column should be displayed based on a value.
     * @example requiredPermission: (colDef) => colDef.field === 'test',
     */
    requiredPermission?: RequiredPermission;
  }
}

const permissionConditions: Array<({ colDef, customerSettings, features, authorities }: RequiredPermissionParams) => boolean> = [
  ({ colDef }) => !colDef.requiredPermission,
  // Check if the required permission is a custom handler, and call it with the appropriate arguments
  ({ colDef, customerSettings, features, authorities }) =>
    typeof colDef.requiredPermission === 'function' && colDef.requiredPermission({ colDef, customerSettings, features, authorities }),
  // or check that the requiredPermission is unknown and no custom handler is defined
  ({ colDef }) => typeof colDef.requiredPermission !== 'function',
];

/**
 * Filters columns based on user permissions.
 * @param columns - The columns to filter.
 * @param customerSettings - The customer settings to check permissions against.
 * @param features - Engineering toggles to check permissions against.
 * @returns The filtered columns.
 */

export const filterColumnsOnPermission = ({
  columns = [],
  customerSettings = {},
  features = {},
  authorities = [],
}: Partial<RequiredPermissionParamsWithColumns> = {}): Array<ColDef | ColGroupDef> =>
  columns
    .filter((colDef: ColDef | ColGroupDef) =>
      permissionConditions.some((condition) => condition({ colDef, customerSettings, features, authorities })),
    )
    .map((column: ColDef | ColGroupDef) =>
      'children' in column && column.children?.length > 0
        ? { ...column, children: filterColumnsOnPermission({ columns: column.children, customerSettings, features, authorities }) }
        : column,
    );
