import { ColDef } from 'ag-grid-enterprise';

import { CabinCode } from '@/modules/api/application/application-contracts';
import { ApiClient } from '@/modules/api/base-client';

type NumberDiff = {
  value: number;
  diff: number;
};

export interface BaseSingleInventoryManagement {
  name: string;
  type: 'cabin' | 'seat_only' | 'pool' | 'tour_operator' | 'pool_tour_operator';
  path?: string[];
  active?: boolean;
}

export interface SingleInventoryManagementNonTourOperator extends BaseSingleInventoryManagement {
  type: 'cabin' | 'seat_only' | 'pool';
  fare?: number;
  seats: {
    allocated: number;
    adjustedAllocation: number;
    availability: number;
    sold: NumberDiff;
  };
  calculatedClass?: string;
}

export interface SingleInventoryManagementPool extends Omit<SingleInventoryManagementNonTourOperator, 'type'> {
  type: 'pool';
  tourOperators: SingleInventoryManagementPoolTourOperator[];
}

interface BaseSingleInventoryManagementTourOperator extends BaseSingleInventoryManagement {
  new?: boolean;
  fare?: number;
  calculatedClass?: string;
  srcLAF: string | undefined;
  offset: number;
  contractType: string;
}

export interface SingleInventoryManagementTourOperator extends BaseSingleInventoryManagementTourOperator {
  type: 'tour_operator';
  ceiling?: string;
  seats: {
    allocated: number;
    adjustedAllocation: number;
    availability: number;
    sold: NumberDiff;
  };
}

export interface SingleInventoryManagementPoolTourOperator extends BaseSingleInventoryManagementTourOperator {
  active: boolean;
  type: 'pool_tour_operator';
  seats: {
    sold: NumberDiff;
  };
}

export type SingleInventoryManagement = {
  cabin: SingleInventoryManagementNonTourOperator;
  seatOnly: SingleInventoryManagementNonTourOperator;
  pool: SingleInventoryManagementPool;
  tourOperators: SingleInventoryManagementTourOperator[];
};

export type SingleInventoryManagementTypes =
  | SingleInventoryManagementNonTourOperator
  | SingleInventoryManagementTourOperator
  | SingleInventoryManagementPool
  | SingleInventoryManagementPoolTourOperator;

export type SingleInventoryManagementColDef = ColDef<SingleInventoryManagementTypes>;

export interface FareClass {
  fareClass: string;
  fare?: number;
}

export interface ScrLafOption {
  label: string;
  value: string;
  optionParams?: { additionalLabel: string | undefined };
}

export type TourOperatorContractDto = {
  contractType: string;
};

export interface AssignableTourOperatorContract {
  contractCode: string;
  contractType: string;
}

class SingleInventoryManagementService {
  private basePath = 'single-inventory-management';

  public async getTourOperatorContracts(flightKey: string, cabinCode: CabinCode): Promise<SingleInventoryManagement> {
    const url = `${this.basePath}/${flightKey}/${cabinCode}`;
    return await ApiClient.get<SingleInventoryManagement>(url);
  }

  public async getFareClassesForFlight(flightKey: string, cabinCode: CabinCode): Promise<FareClass[]> {
    const url = `${this.basePath}/classes/${flightKey}/${cabinCode}`;
    return await ApiClient.get<FareClass[]>(url);
  }

  /**
   * Get all available fare classes regardless origin/destination.
   */
  public async getFareClasses(): Promise<string[]> {
    return await ApiClient.get<string[]>(`${this.basePath}/fare-classes`);
  }

  public async saveTourOperatorContract(
    flightKey: string,
    cabinCode: CabinCode,
    singleInventoryManagement: SingleInventoryManagement,
    preview: boolean,
  ): Promise<SingleInventoryManagement> {
    const url = `${this.basePath}/${flightKey}/${cabinCode}?preview=${preview}`;
    return await ApiClient.put<SingleInventoryManagement>(url, singleInventoryManagement);
  }

  public async getTourOperatorContractTypes(): Promise<TourOperatorContractDto[]> {
    const url = `${this.basePath}/tour-operator/contract-types`;
    return await ApiClient.get<TourOperatorContractDto[]>(url);
  }

  public async getAvailableTourOperators(): Promise<AssignableTourOperatorContract[]> {
    const url = `${this.basePath}/tour-operator/available`;
    return await ApiClient.get<AssignableTourOperatorContract[]>(url);
  }
}

export const singleInventoryManagementService = new SingleInventoryManagementService();
