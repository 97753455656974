export const en = {
  inventory_overwrite_rules: {
    titles: {
      inventory_overwrite_rules: 'Inventory Overwrite Rules',
    },
    states: {
      loading_rules: 'Loading rules...',
    },
    actions: {
      save: 'Save',
    },
    labels: {
      recommended_authorization_adjustments: 'Recommended Authorization Adjustments',
    },
    info: {
      recommended_authorization_adjustments:
        'When recommended Flight Inventory is generated, the recommended Authorization Units will be adjusted by the sequence of overwrites ranked as:',
    },
    rules: {
      linked_class_rules: 'Linked Class Rules',
      pins: 'Pins',
      order_au_bottom_to_top: 'Order AU Bottom to Top',
      order_au_top_to_bottom: 'Order AU Top to Bottom',
    },
  },
};
