import { ColDef, ValueGetterParams } from 'ag-grid-enterprise';
import { first } from 'lodash-es';

import { ColumnId, generateCabinLevelColumnId } from '@/models/enums/grid';
import { PromotionState } from '@/modules/promotions/api/promotion-contracts';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';

import { SetColumnFilterSettings } from './base';

const { t } = i18n.global;

/**
 *  A column displaying all the promotions attached to the cabin's classes.
 * @param cabinCode The code of the cabin
 * @returns A column displaying all the promotions attached to the cabin's classes.
 */
export const generateCabinPromotionsColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinPromotions),
  headerName: t('promotion_level_price_short'),
  width: 150,
  minWidth: 150,
  hide: false,
  sortable: true,
  headerTooltip: t('promotion_level_price'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);

    if (matchingCabin) {
      const promotionClasses = matchingCabin.classes.filter((clazz) => clazz.promotion);

      return promotionClasses
        ? promotionClasses.map((clazz) => `${clazz.promotion.sequence} - ${clazz.promotion.price}`).join()
        : undefined;
    }

    return undefined;
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasPromotionsEnabled,
});

/**
 * A column displaying the active promotion
 * @param cabinCode The code of the cabin
 * @returns A column displaying the active promotion
 */
export const generateCabinActivePromotionColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinActivePromotion),
  headerName: t('assigned_promotion_short'),
  width: 120,
  minWidth: 120,
  hide: false,
  sortable: true,
  headerTooltip: t('assigned_promotion'),
  cellClass: (params) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);

    const promotionClass = first(matchingCabin.classes.filter((clazz) => clazz.promotion));

    if (matchingCabin && promotionClass) {
      switch (promotionClass.promotion.state) {
        case PromotionState.active:
          return ['text-green', 'flex justify-end'];
        case PromotionState.planned:
          return ['text-primary', 'flex justify-end'];
        case PromotionState.ended:
          return ['text-gray-500', 'flex justify-end'];

        default:
          return '';
      }
    }
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);

    if (matchingCabin) {
      return matchingCabin.classes
        .filter((clazz) => clazz.promotion)
        .map((clazz) => clazz.promotion && clazz.promotion.name)
        .join();
    }

    return undefined;
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasPromotionsEnabled,
});
