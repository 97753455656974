import { isEmpty, snakeCase } from 'lodash-es';

import { ApiClient } from '@/modules/api/base-client';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { i18n } from '@/plugins/i18n';

import { IRoleService, RoleModel } from './role-contracts';

const { t } = i18n.global;

export class RoleService implements IRoleService {
  private basePath = 'roles';

  public async get(): Promise<RoleModel[]> {
    const customerSettingsStore = useCustomerSettingsStore();

    let roles = await ApiClient.get<RoleModel[]>(`${this.basePath}`);

    if (!customerSettingsStore.settings?.hasLinkedClassRulesEnabled) {
      // Filter out Rules role if hasLinkedClassRulesEnabled is not enabled
      roles = roles.filter((role) => role.name !== 'Rules.admin');
    }

    return roles;
  }

  public translateResource(resource: string): string {
    return t('user_management.resource.' + snakeCase(resource));
  }
  public translateRole(roleName: string): string {
    return isEmpty(roleName) ? '' : t('user_management.role_name.' + snakeCase(roleName));
  }
}

export const roleService = new RoleService();
