export enum DefaultRouteManagementActionType {
  AssignUser = 'AssignUser',
  ReassignUser = 'ReassignUser',
  UnassignUser = 'UnassignUser',
  SetCFWindow = 'SetCFWindow',
  SetFareCurrency = 'SetFareCurrency',
  SetDirection = 'SetDirection',
  AssignRouteGroup = 'AssignRouteGroup',
  UnassignRouteGroup = 'UnassignRouteGroup',
  SetPriceRanges = 'SetPriceRanges',
  UserAssignment = 'UserAssignment',
  RouteAssignment = 'RouteAssignment',
  ClusterBulkAssign = 'ClusterBulkAssign',
  ForecastAndOptimisation = 'ForecastAndOptimisation',
  EnableForecastGeneration = 'EnableForecastGeneration',
  DisableForecastGeneration = 'DisableForecastGeneration',
  EnableForecastLearning = 'EnableForecastLearning',
  DisableForecastLearning = 'DisableForecastLearning',
  ReverseMarketName = 'ReverseMarketName',
  ManageMarketLabels = 'ManageMarketLabels',
}

export enum AssignMarketLabelActionType {
  AssignMarketLabel_1 = 'AssignMarketLabel_1',
  AssignMarketLabel_2 = 'AssignMarketLabel_2',
  AssignMarketLabel_3 = 'AssignMarketLabel_3',
}

export enum UnassignMarketLabelActionType {
  UnassignMarketLabel_1 = 'UnassignMarketLabel_1',
  UnassignMarketLabel_2 = 'UnassignMarketLabel_2',
  UnassignMarketLabel_3 = 'UnassignMarketLabel_3',
}

export enum OpLevelAdjustmentIncrementActionType {
  BYOROpLevelAdjustIncrement = 'BYOROpLevelAdjustIncrement',
  DynamicProgramOpLevelAdjustIncrement = 'DynamicProgramOpLevelAdjustIncrement',
}

export const RouteManagementActions = {
  ...DefaultRouteManagementActionType,
  ...OpLevelAdjustmentIncrementActionType,
  ...AssignMarketLabelActionType,
  ...UnassignMarketLabelActionType,
};
export type RouteManagementActionTypeKey = keyof typeof RouteManagementActions;
export type RouteManagementActionType = (typeof RouteManagementActions)[RouteManagementActionTypeKey];

// Configuration actions
export enum DefaultRouteManagementConfigurationActionType {
  ConfigureMarketLabels = 'ConfigureMarketLabels',
}

export enum ConfigureMarketLabelsActionType {
  ConfigureMarketLabels_1 = 'ConfigureMarketLabels_1',
  ConfigureMarketLabels_2 = 'ConfigureMarketLabels_2',
  ConfigureMarketLabels_3 = 'ConfigureMarketLabels_3',
}

export const RouteManagementConfigurationActions = {
  ...DefaultRouteManagementConfigurationActionType,
  ...ConfigureMarketLabelsActionType,
} as const;

export type RouteManagementConfigurationActionTypeKey = keyof typeof RouteManagementConfigurationActions;
export type RouteManagementConfigurationActionType =
  (typeof RouteManagementConfigurationActions)[RouteManagementConfigurationActionTypeKey];
