import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { authService } from '@/modules/api/auth/auth-service';
import { useClustersStore } from '@/modules/cluster-management/store/clusters.store';
import { useControlStore } from '@/modules/control/store/control.store';
import { useOptimisationProfilesStore } from '@/modules/control/store/optimisation-profiles.store';
import { useCustomerDefinedDataStore } from '@/modules/customer-defined-data/store/customer-defined-data.store';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { useDocsStore } from '@/modules/docs/store/docs.store';
import { useAuthStore } from '@/modules/external/store/auth.store';
import { useFeaturesStore } from '@/modules/features/store/features.store';
import { useFlightActionGroupsStore } from '@/modules/flight-actions/store/flight-action-groups.store';
import { useCalendarStore } from '@/modules/route-management/store/calendar.store';
import { useMarketInfoStore } from '@/modules/route-management/store/market-info.store';
import { useGlobalLinkedClassRule } from '@/modules/rules/linked-class-rules/composables/use-linked-class-rule-loader.composable';
import { useGlobalRivalRule } from '@/modules/rules/rival-rules/composables/use-rival-rule-loader.composable';
import { MessageService } from '@/modules/shared';
import { useSystemStore } from '@/modules/system-settings/store/system.store';
import { useTagsStore } from '@/modules/tags/store/tags.store';
import { useSingleInventoryManagementStore } from '@/modules/tour-operator-details/stores/single-inventory-management.store';
import { useUserStore } from '@/modules/user-settings/store/user.store';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';

export const useSettingsStore = defineStore('settings', () => {
  const isLoading: Ref<boolean> = ref(false);
  const hasFetchedSettings: Ref<boolean> = ref(false);

  async function get(): Promise<void> {
    try {
      isLoading.value = true;

      const appSettingsStore = useAppSettingsStore();
      await appSettingsStore.getApplicationSettings();

      // Customer settings need to be loaded before User configuration
      // OwnFareSource is needed to set column state
      const customerSettingsStore = useCustomerSettingsStore();
      await customerSettingsStore.get();

      const clustersStore = useClustersStore();
      const customerDefinedDataStore = useCustomerDefinedDataStore();
      const docsStore = useDocsStore();
      const optimisationProfilesStore = useOptimisationProfilesStore();
      const tagsStore = useTagsStore();
      const systemStore = useSystemStore();
      const calendarStore = useCalendarStore();
      const featuresStore = useFeaturesStore();
      const marketInfoStore = useMarketInfoStore();
      const flightActionGroupsStore = useFlightActionGroupsStore();
      const authStore = useAuthStore();
      const userStore = useUserStore();
      const singleInventoryManagementStore = useSingleInventoryManagementStore();

      const { email, authorities } = authStore;

      if (email && authorities) {
        await Promise.all([
          customerSettingsStore.getMultiFactorAuthentication(),
          featuresStore.get(),
          authService.hasAuthority([Authority.RouteRead, Authority.FlightRead], authorities) ? marketInfoStore.get() : null,
          userStore.get(email),
          optimisationProfilesStore.getAll(),
          useControlStore().getAircraftTypes(),
          authService.hasAuthority([Authority.TagRead], authorities) ? tagsStore.get() : null,
          flightActionGroupsStore.get(),
          authService.hasAuthority([Authority.ClustersRead, Authority.RouteRead, Authority.FlightRead], authorities)
            ? clustersStore.get()
            : null,
          authService.hasAuthority([Authority.LinkedClassRulesRead], authorities) ? useGlobalLinkedClassRule().getAll() : null,
          authService.hasAuthority([Authority.RivalRulesRead], authorities) ? useGlobalRivalRule().getAll() : null,
          customerSettingsStore.settings?.hasCustomerDefinedDataEnabled ? customerDefinedDataStore.getSchema() : null,
          customerSettingsStore.settings?.hasSingleInventoryManagementEnabled
            ? singleInventoryManagementStore.getTourOperatorContractTypes()
            : null,
          customerSettingsStore.settings?.hasSingleInventoryManagementEnabled
            ? singleInventoryManagementStore.getAssignableTourOperatorContracts()
            : null,
        ]);

        await systemStore.get();
        await calendarStore.get(systemStore.config!.captureDate);

        // This is not critical, so no await
        docsStore.get();

        hasFetchedSettings.value = true;
      } else {
        throw new Error('Email and authorities are not set');
      }
    } catch (e) {
      MessageService.failedRequest('Failed to get settings');
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  function $reset(): void {
    isLoading.value = false;
    hasFetchedSettings.value = false;
  }

  return {
    isLoading,
    hasFetchedSettings,
    get,
    $reset,
  };
});
