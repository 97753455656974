import { defineStore } from 'pinia';
import { ref } from 'vue';

import { UserFilterModel, UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { userManagementService } from '@/modules/user-settings/api/users/users-management.service';

export const useUserManagementStore = defineStore('userManagement', () => {
  const enabledUsers = ref<UserModel[]>([]);
  const allUsers = ref<UserModel[]>([]);
  const enabledUsersForControlFilter = ref<UserFilterModel[]>([]);

  async function getEnabledUsers(): Promise<void> {
    enabledUsers.value = await userManagementService.get(true);
  }

  async function getEnabledUsersForControlFilter(): Promise<void> {
    enabledUsersForControlFilter.value = await userManagementService.getForControlStaticFilters();
  }

  async function getAllUsers(): Promise<void> {
    allUsers.value = await userManagementService.get();
    // When getting all users, make sure to update the enabled users as well as this could be after a user change
    enabledUsers.value = allUsers.value.filter((user) => user.isEnabled);
  }

  return {
    enabledUsers,
    allUsers,
    enabledUsersForControlFilter,
    getEnabledUsers,
    getAllUsers,
    getEnabledUsersForControlFilter,
  };
});
