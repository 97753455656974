import { CellClassParams, ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import moment from 'moment/moment';

import { TextColumnFilterSettings } from '@/models/columns/definitions/base';
import { GridUserActionModel } from '@/modules/grid/components/grid-user-action-model';
import { DateTimeInvertedComparator } from '@/modules/shared/utils/comparisons.utils';
import { UserGroup } from '@/modules/user-management/api/user-group/user-group-management.contracts';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export type UserGroupNameCellInnerRendererParams = {
  dataTest: string;
  value: string;
  onAddUserGroup: (userGroup: UserGroup) => void;
};

export const UserGroupNameColumn: ColDef<UserGroup, string> = {
  ...TextColumnFilterSettings,
  headerName: t('user_management.labels.group_name'),
  type: 'leftAligned',
  field: 'name',
  sortable: true,
  width: 380,
  minWidth: 380,
  rowDrag: true,
  sort: 'asc',
  cellClass: 'flex',
  cellRendererParams: (params: ICellRendererParams<UserGroup>) => {
    const innerRendererParams: UserGroupNameCellInnerRendererParams = {
      dataTest: `data-test-group-name-column-${params?.data?.id}`,
      value: params.data?.name || '',
      onAddUserGroup: (newUserGroup: UserGroup) => {
        params.context.onAddUserGroup(newUserGroup);
      },
    };
    const rendererParams = {
      suppressCount: true,
      innerRenderer: 'UserGroupNameCellRenderer',
      innerRendererParams,
    };
    return rendererParams;
  },
};

export const UserGroupLeaderColumn: ColDef<UserGroup> = {
  ...TextColumnFilterSettings,
  type: 'leftAligned',
  headerName: t('user_management.labels.group_leader'),
  sortable: true,
  field: 'leader.name',
  cellClass: ({ data }: CellClassParams) => `data-test-group-leader-column-${data?.id} flex items-center`,
  width: 275,
};

export const UserGroupMembersCountColumn: ColDef<UserGroup> = {
  ...TextColumnFilterSettings,
  type: 'leftAligned',
  headerName: t('user_management.labels.members_count'),
  sortable: true,
  valueGetter: ({ data }) => data?.members.length,
  cellClass: ({ data }: CellClassParams) => `data-test-member-count-column-${data?.id} flex items-center`,
  width: 125,
};

export const UserGroupMembersColumn: ColDef<UserGroup> = {
  ...TextColumnFilterSettings,
  type: 'leftAligned',
  headerName: t('user_management.labels.members'),
  sortable: true,
  valueGetter: ({ data }) => data?.members.map((member) => member.name).join(', '),
  cellClass: ({ data }: CellClassParams) => `data-test-member-names-column-${data?.id} flex items-center`,
  width: 125,
};

export const UserGroupLastModifiedDateColumn: ColDef<UserGroup> = {
  headerName: t('user_management.labels.last_modified_date'),
  headerClass: 'data-test-last-modified ag-right-aligned-header',
  field: 'lastModifiedDateTime',
  valueFormatter: ({ value }) => moment.utc(value).local().fromNow(),
  type: 'rightAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-group-modified-date-column-${data?.id} flex items-center flex-row-reverse`,
  sortable: true,
  comparator: DateTimeInvertedComparator,
  filter: 'agDateColumnFilter',
  filterParams: {
    comparator: DateTimeInvertedComparator,
  },
  menuTabs: ['filterMenuTab'],
};

export const UserGroupLastModifiedByColumn: ColDef<UserGroup> = {
  headerName: t('user_management.labels.last_modified_by'),
  valueGetter: ({ data }) => data?.lastModifiedBy?.name,
  sortable: true,
  filter: 'agTextColumnFilter',
  cellClass: ({ data }: CellClassParams) => `data-test-group-modified-by-column-${data?.id} flex items-center ag-right-aligned-cell`,
  menuTabs: ['filterMenuTab'],
};

export function generateUserActionsColumn(params: GridUserActionModel[]): ColDef<UserGroup> {
  return {
    headerName: t('general.actions'),
    field: 'id',
    minWidth: 125,
    width: 125,
    suppressHeaderMenuButton: true,
    sortable: false,
    cellRenderer: 'GridUserActionsRenderer',
    cellClass: ({ data }: CellClassParams) => `data-test-user-group-actions-column-${data?.id}`,
    cellRendererParams: () => ({
      gridUserActions: params,
    }),
  };
}
