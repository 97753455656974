import { MarketLabel } from '@/modules/route-management/api/routes/routes.contracts';

import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface SetMarketLabelsPayload extends RouteActionPayload {
  marketLabelIds: number[];
  series: number;
}

export class SetMarketLabelsAction implements RouteAction<SetMarketLabelsPayload> {
  public actionType: RouteActionType = RouteActionType.setMarketLabels;

  public marketLabelIds: number[];
  public series: number;

  constructor(series: number, label?: MarketLabel) {
    this.marketLabelIds = label?.id ? [label.id] : [];
    this.series = series;
  }

  public setPayload(payload: SetMarketLabelsPayload): void {
    this.marketLabelIds = payload.marketLabelIds;
    this.series = payload.series;
  }

  public getPayload(): SetMarketLabelsPayload {
    return {
      actionType: this.actionType,
      marketLabelIds: this.marketLabelIds,
      series: this.series,
    };
  }
}
