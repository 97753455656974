export const en = {
  user_management: {
    titles: {
      user_management: 'Users & Permissions',
      edit_user: 'Edit User',
      add_user: 'Add User',
      edit_user_group: 'Edit User Group',
      create_user_group: 'Create User Group',
      confirm_user_group_deletion: 'Delete Group {userGroup}?',
    },
    tabs: {
      user_list: 'User Management',
      user_groups: 'User Groups',
    },
    states: {
      loading_users: 'Loading Users...',
      loading_user_groups: 'Loading User Groups...',
      active: 'Active',
      inactive: 'Inactive',
      user_group_deleted: 'User Group Deleted',
      user_group_not_deleted: 'User Group Not Deleted',
      no_user_groups: 'There are no User Groups defined yet. Create the first User Group',
    },
    resource: {
      application: 'Application',
      automations: 'FlowFx',
      data_importer: 'External File Importer',
      flights: 'Flights',
      insights: 'Insights',
      optimisation_profiles: 'Optimization Profiles',
      promotions: 'Promotions',
      revenue_components: 'Revenue Components',
      route: 'Route',
      routes: 'Routes',
      rules: 'Rules',
      system: 'System',
      users: 'Users',
      user_groups: 'User Groups',
    },
    role_name: {
      application_admin: 'Application Admin',
      automations_admin: 'FlowFx Admin',
      automations_editor: 'FlowFx Editor',
      automations_viewer: 'FlowFx Viewer',
      data_importer_admin: 'External File Importer Admin',
      flights_admin: 'Flights Admin',
      flights_editor_of: 'Flight Editor Of',
      flights_editor: 'Flights Editor',
      flights_viewer: 'Flights Viewer',
      insights_admin: 'Insights Admin',
      application: 'Application',
      optimisation_profiles: 'Optimization Profiles',
      optimisation_profiles_admin: 'Optimization Profiles Admin',
      optimisation_profiles_viewer: 'Optimization Profiles Viewer',
      promotions_admin: 'Promotions Admin',
      promotions_viewer: 'Promotions Viewer',
      revenue_components_admin: 'Revenue Components Admin',
      routes_admin: 'Routes Admin',
      routes_viewer: 'Routes Viewer',
      rules_admin: 'Rules Admin',
      system_admin: 'System Admin',
      users_admin: 'Users Admin',
      users_creator: 'Users Creator',
      users_editor: 'User Editor',
      users_viewer: 'Users Viewer',
      user_groups_viewer: 'User Groups Viewer',
      user_groups_admin: 'User Groups Admin',
    },
    labels: {
      name: 'Name',
      email: 'Email',
      status: 'Status',
      roles: 'Roles',
      user_groups: 'User Groups',
      actions: 'Actions',
      enroll_into_mfa: 'Enroll into MFA',
      phone_number: 'Phone Number',
      password: 'Password',
      active: 'Active',
      select_role: 'Select a Role',
      group_name: 'Group Name',
      group_leader: 'User Group Leader',
      members_count: 'Members Count',
      members: 'Group Members',
      last_modified_date: 'Last Modified Date',
      last_modified_by: 'Last Modified By',
      edit_user_groups: 'Edit User Groups',
      unique_group_name: 'Unique Group Name',
      shortened_group_name: 'Abbreviation',
      unique_group_abbreviation: 'Unique Group Abbreviation',
      user_group_leader: 'User Group Leader',
      leader_name: 'Leader Name',
      select_leader_placeholder: 'Select a Leader',
      this_is_a_level_with: 'This is a level with',
      subordinates: 'Subordinates',
      no_subordinates: 'No Subordinates',
      user_group_members: 'Group Team Members',
      assigned_members: 'Assigned Members',
      select_members_placeholder: 'Select Members',
      assigned_user_groups: 'Assigned User Groups',
      unassigned: 'Unassigned',
      select_user_groups_placeholder: 'Select User Groups',
    },
    info: {
      password_copy_alert:
        'Please copy the password and send it to the user. After saving this user, you cannot copy the password anymore.',
      at_least_one_role: 'Add at least one role.',
      last_level:
        'Choosing this option indicates that this will be the last level of the organization. Thus, it will not be possible to add any nested groups under this one',
      disabled_last_level:
        'This option is disabled because the group already has other user groups nested in this part of the organization.',
      confirm_user_group_deletion:
        `Deleting a group is a permanent action and cannot be undone. \n` +
        `The group, its content and nested structures will be deleted. The users will be unassigned from the group.`,
      assigned_user_groups_tooltip_view: `User groups are a way to organize users in your organization. \n`,
      assigned_user_groups_tooltip_edit:
        `User groups are a way to organize users in your organization. \n` +
        `You can assign Users to User Groups in the User Groups section.`,
    },
    actions: {
      add_user: 'Add User',
      edit: 'Edit',
      reset: 'Reset',
      save: 'Save',
      save_changes: 'Save Changes',
      cancel: 'Cancel',
      add_role: 'Add a Role',
      add_another_role: 'Add Another Role',
      clear_selection: 'Clear Selection',
      delete: 'Delete',
      add_user_group: 'Add User Group',
      delete_group: 'Delete Group',
      add_nested_level: 'Add Nested Level',
      add_same_level: 'Add Same Level',
    },
    form: {
      validate_name: 'Please enter a name.',
      validate_group_name: 'Please enter a group name.',
      validate_alias: 'Please enter an abbreviation.',
      validate_leader: 'Please select a leader.',
      validate_max_length: 'Please enter a value with no more than {length} characters.',
    },
    validation_errors: {
      user_not_exists: 'Assigned User does not exist.',
      invalid_parent_error: `The parent of the user group doesn't exist.`,
      duplicate_name_error: 'User group name must be unique. This group name already exists.',
      duplicate_alias_error: 'Group abbreviation must be unique.',
      leaf_error: 'Only User Groups with no subordinates can have members assigned.',
    },
  },
};
