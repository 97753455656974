import { defineStore } from 'pinia';
import { ref } from 'vue';

import { UserGroup, UserGroupDefinition } from '@/modules/user-management/api/user-group/user-group-management.contracts';
import { userGroupManagementService } from '@/modules/user-management/api/user-group/user-group-management.service';

export const useUserGroupStore = defineStore('userGroup', () => {
  const userGroups = ref<UserGroup[]>([]);
  const userGroupsForControlFilter = ref<UserGroupDefinition[]>([]);

  async function getUserGroups(): Promise<void> {
    userGroups.value = await userGroupManagementService.get();
  }

  async function getUserGroupsForControlFilter(): Promise<void> {
    userGroupsForControlFilter.value = await userGroupManagementService.getForControlFilter();
  }

  function $reset(): void {
    userGroups.value = [];
    userGroupsForControlFilter.value = [];
  }

  return {
    userGroups,
    getUserGroups,
    userGroupsForControlFilter,
    getUserGroupsForControlFilter,
    $reset,
  };
});
