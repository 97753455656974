import { FormItemRule } from 'element-plus';

import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const verificationCodeValidator: FormItemRule['validator'] = (rule, value: string | undefined, callback) => {
  if (!value || /^[0-9]{6}$/.test(value)) {
    callback();
  } else {
    callback(new Error(t('external.invalid_field.verification_code')));
  }
};

export const phoneNumberValidator: FormItemRule['validator'] = (rule, value: string | undefined, callback) => {
  if (!value || /^\+[1-9]\d{1,14}$/.test(value)) {
    callback();
  } else {
    callback(new Error(t('external.invalid_field.phone_number')));
  }
};
