import { CellClassParams, ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { RequiredPermissionParams } from '@/models/columns/utils/col-def-with-permission';
import { ColumnId, generateCabinLevelColumnId } from '@/models/enums/grid';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FlightLegCabin, FlightLegModel, FlightLineModel } from '@/modules/api/flight/flight-contracts';
import { formatNumber, roundNumber } from '@/modules/shared';
import { ScheduleMode } from '@/modules/shared/shared-contracts';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';

import { NumberColumnFilterSettings, SetColumnFilterSettings } from './base';

const { t } = i18n.global;

const isManagedNetwork = ({ customerSettings }: RequiredPermissionParams): boolean =>
  customerSettings.scheduleMode === ScheduleMode.MANAGED_NETWORK;

const isGoldModel = ({ customerSettings }: RequiredPermissionParams): boolean => !!customerSettings.useGoldModel;

const getMatchedLegCabin = (legs: FlightLegModel[], cabinCode: string): FlightLegCabin | undefined => {
  if (legs?.length !== 1) {
    return;
  }
  return FlightService.getMatchedLegCabin(legs[0], cabinCode);
};

export function LegCabinAuthorizedCapacityColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinAuthorizedCapacity),
    headerName: t('leg_authorized_capacity_short'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-aCap-key-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams): undefined | number => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.isAuthorizedCapacityUpdated ? matchingCabin.updatedAuthorizedCapacity : matchingCabin.authorizedCapacity;
    },
    headerTooltip: t('leg_authorized_capacity'),
    enableValue: true,
  };
}

export function LegCabinSaleableCapacityColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinSaleableCapacity),
    headerName: t('leg_saleable_capacity_short'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-sCap-key-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams): undefined | number => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.saleableCapacity;
    },
    headerTooltip: t('leg_saleable_capacity'),
    enableValue: true,
  };
}

export function LegCabinAuthorizedCapacityLoadfactorColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinLoadFactor),
    headerName: t('authorized_capacity_load_factor_short'),
    headerTooltip: t('authorized_capacity_load_factor'),
    minWidth: 60,
    width: 60,
    sortable: true,
    cellRenderer: 'CapacityLoadFactorRenderer',
    cellClass: 'marginless-cell border border-primary flex',
    valueGetter: (params: ValueGetterParams): undefined | number => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin || matchingCabin.loadFactor === undefined) {
        return undefined;
      }
      return parseFloat(FormatService.roundNumber(matchingCabin.loadFactor, 1));
    },
    comparator: StringOrNumberComparator,
    cellRendererParams: (params: ICellRendererParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }

      return {
        value: matchingCabin.capturedLoadFactor,
        realtimeCapacityLoadFactor: matchingCabin.loadFactor,
      };
    },
  };
}

export function LegCabinSaleableCapacityLoadfactorColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinCapacityLoadfactor),
    headerName: t('saleable_capacity_load_factor_short'),
    headerTooltip: t('saleable_capacity_load_factor'),
    minWidth: 60,
    width: 60,
    sortable: true,
    cellRenderer: 'CapacityLoadFactorRenderer',
    cellClass: 'marginless-cell border border-primary flex',
    valueGetter: (params: ValueGetterParams): undefined | number => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin || matchingCabin.capacityLoadFactor === undefined) {
        return undefined;
      }
      return parseFloat(FormatService.roundNumber(matchingCabin.capacityLoadFactor, 1));
    },
    comparator: StringOrNumberComparator,
    cellRendererParams: (params: ICellRendererParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }

      return {
        value: matchingCabin.capturedCapacityLoadFactor,
        realtimeCapacityLoadFactor: matchingCabin.capacityLoadFactor,
      };
    },
  };
}

export function LegCabinOverbookingFactorColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinOverbookingFactor),
    headerName: t('overbooking_factor_short'),
    headerTooltip: t('overbooking_factor'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-overbooking-factor-key-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams): undefined | number => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.overbookingFactor;
    },
  };
}

export function LegCabinLeftoverCapacityFactorColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinLeftOverCapacity),
    headerName: t('leftover_capacity_short'),
    minWidth: 35,
    width: 35,
    sortable: true,
    headerTooltip: t('leftover_capacity'),
    cellClass: 'marginless-cell ag-right-aligned-cell',
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams): undefined | number => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.leftoverCapacity;
    },
  };
}

export function LegCabinExpectedNoShowsColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinExpectedNoShows),
    headerName: t('control.columns.expected_no_shows_short'),
    headerTooltip: t('control.columns.expected_no_shows'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-expected-no-shows-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams): undefined | number => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.expectedNoShows;
    },
    requiredPermission(params) {
      return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
    },
  };
}

export function LegCabinRecommendedOverbookingFactorColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinRecommendedOverbookingFactor),
    headerName: t('control.columns.recommended_overbooking_factor_short'),
    headerTooltip: t('control.columns.recommended_overbooking_factor'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-recommended-overbooking-factor-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams): undefined | number => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.recommendedOverbookingFactor;
    },
    requiredPermission(params) {
      return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
    },
  };
}

export function LegCabinOverbookingRiskColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinOverbookingRisk),
    headerName: t('control.columns.overbooking_risk_short'),
    headerTooltip: t('control.columns.overbooking_risk'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-overbooking-risk-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams): undefined | number => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.overbookingRisk;
    },
    requiredPermission(params) {
      return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
    },
  };
}

export function LegCabinOverbookingAutopilotColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...SetColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinOverbookingAutopilot),
    headerName: t('control.columns.overbooking_autopilot_short'),
    minWidth: 40,
    width: 40,
    sortable: true,
    hide: false,
    type: 'leftAligned',
    cellClass: ({ data }: CellClassParams) => `ag-left-aligned-cell data-test-overbooking-autopilot-key-cell-${data.ondId}`,
    headerTooltip: t('control.columns.overbooking_autopilot'),
    cellStyle: (params: CellClassParams) =>
      params.value ? { color: '#409EFF' } : params.value === false ? { color: '#909399' } : undefined,
    valueGetter: (params: ValueGetterParams): boolean => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      return matchingCabin?.overbookingAutopilot ?? false;
    },
    valueFormatter: (params: ValueFormatterParams): string => (params.value ? t('on') : t('off')),
    requiredPermission(params) {
      return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
    },
  };
}

export function LegCabinUnbalancedAdjustmentColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinUnbalancedAdjustment),
    headerName: t('control.columns.unbalanced_adjustments_short'),
    minWidth: 50,
    width: 50,
    sortable: true,
    hide: true,
    type: 'numericColumn',
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-${cabinCode}-unbalanced-adjustments-${data.ondId}`,
    headerTooltip: t('control.columns.unbalanced_adjustments'),
    valueGetter: (params: ValueGetterParams): number | undefined => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      const unbalancedAdj = matchingCabin?.isUnbalancedAdjustmentUpdated
        ? matchingCabin.updatedUnbalancedAdjustment
        : matchingCabin?.unbalancedAdjustment;
      return typeof unbalancedAdj === 'number' ? roundNumber(unbalancedAdj, 0) : 0;
    },
    requiredPermission: (params: RequiredPermissionParams): boolean => !!params?.customerSettings.hasUnbalancedAdjustmentsEnabled,
  };
}

export function LegCabinAllotmentColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinAllotment),
    headerName: t('control.columns.allotment_short'),
    minWidth: 50,
    width: 50,
    sortable: true,
    hide: true,
    type: 'numericColumn',
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-${cabinCode}-allotment-${data.ondId}`,
    headerTooltip: t('control.columns.allotment'),
    valueGetter: (params: ValueGetterParams): number | undefined => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      return typeof matchingCabin?.allotment === 'number' ? roundNumber(matchingCabin?.allotment, 0) : 0;
    },
    requiredPermission: (params: RequiredPermissionParams): boolean => !!params?.customerSettings.hasAllotmentsEnabled,
  };
}

export function LegCabinLocalBookingsColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegLocalBookings),
    headerName: t('control.columns.local_bookings_short'),
    type: 'numericColumn',
    minWidth: 55,
    width: 75,
    sortable: true,
    headerTooltip: t('control.columns.local_bookings'),
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-${cabinCode}-local-bookings-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams): number | undefined => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      return typeof matchingCabin?.localBookings === 'number' ? roundNumber(matchingCabin?.localBookings, 0) : undefined;
    },
    requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
  };
}

export function LegCabinConnectingBookingsColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegConnectingBookings),
    headerName: t('control.columns.connecting_bookings_short'),
    type: 'numericColumn',
    minWidth: 55,
    width: 75,
    sortable: true,
    headerTooltip: t('control.columns.connecting_bookings'),
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-${cabinCode}-connecting-bookings-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams): number | undefined => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      return typeof matchingCabin?.connectingBookings === 'number' ? roundNumber(matchingCabin?.connectingBookings, 0) : undefined;
    },
    requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
  };
}

export function LegCabinLocalRevenueColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegLocalRevenue),
    headerName: t('control.columns.local_revenue_short'),
    type: 'numericColumn',
    minWidth: 55,
    width: 75,
    sortable: true,
    headerTooltip: t('control.columns.local_revenue'),
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-${cabinCode}-local-revenue-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueFormatter: (params) => formatNumber(params.value),
    valueGetter: (params: ValueGetterParams) => roundNumber(getMatchedLegCabin(params.data.legs, cabinCode)?.localRevenue),
    requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
  };
}

export function LegCabinConnectingRevenueColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegConnectingRevenue),
    headerName: t('control.columns.connecting_revenue_short'),
    type: 'numericColumn',
    minWidth: 55,
    width: 75,
    sortable: true,
    headerTooltip: t('control.columns.connecting_revenue'),
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-${cabinCode}-connecting-revenue-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueFormatter: (params) => formatNumber(params.value),
    valueGetter: (params: ValueGetterParams) => roundNumber(getMatchedLegCabin(params.data.legs, cabinCode)?.connectingRevenue),
    requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
  };
}

export function LegCabinNetworkRevenueColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegNetworkRevenue),
    headerName: t('control.columns.network_revenue_short'),
    type: 'numericColumn',
    minWidth: 55,
    width: 75,
    sortable: true,
    headerTooltip: t('control.columns.network_revenue'),
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-${cabinCode}-network-revenue-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueFormatter: (params) => formatNumber(params.value),
    valueGetter: (params: ValueGetterParams) => roundNumber(getMatchedLegCabin(params.data.legs, cabinCode)?.networkRevenue),
    requiredPermission: (params: RequiredPermissionParams): boolean => isManagedNetwork(params) && isGoldModel(params),
  };
}
