import { CellClassParams, ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-enterprise';

import { Constants } from '@/constants';
import { DateColumnFilterSettings, SetColumnFilterSettings, TextColumnFilterSettings } from '@/models/columns/definitions/base';
import { GridUserActionModel } from '@/modules/grid/components/grid-user-action-model';
import { PromotionStateUI, PromotionUIModel } from '@/modules/promotions/api/promotion-contracts';
import { DateInvertedComparator, DateTimeInvertedComparator, PromotionStateComparator } from '@/modules/shared/utils/comparisons.utils';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { useUserStore } from '@/modules/user-settings/store/user.store';
import { i18n } from '@/plugins/i18n';
import { DateTimeService } from '@/services/date-time.service';

const { t } = i18n.global;

export function generatePromotionOverviewColumns(): ColDef<PromotionUIModel>[] {
  return [
    {
      ...TextColumnFilterSettings,
      headerName: t('name'),
      headerClass: 'ag-left-aligned-header',
      field: 'name',
      minWidth: 90,
      width: 90,
      sortable: true,
      type: 'leftAligned',
    },
    {
      suppressHeaderMenuButton: true,
      headerName: t('state'),
      field: 'state',
      minWidth: 90,
      width: 90,
      sortable: true,
      comparator: PromotionStateComparator,
      type: 'leftAligned',
      cellClass: (params) => (params.value as PromotionStateUI).color ?? '',
      valueFormatter: (params) => params.data?.state.label ?? '',
    },
    {
      ...TextColumnFilterSettings,
      headerName: t('cabin'),
      field: 'cabinCode',
      minWidth: 90,
      width: 90,
      sortable: true,
      type: 'leftAligned',
    },
    {
      ...DateColumnFilterSettings,
      headerName: t('promo_start'),
      field: 'startSaleDate',
      minWidth: 90,
      width: 90,
      sortable: true,
      type: 'rightAligned',
      filterParams: {
        ...DateColumnFilterSettings.filterParams,
        comparator: DateInvertedComparator,
      },
      valueGetter: (params) => {
        if (params.data) {
          return DateTimeService.formatUTCDate({
            date: params.data.startSaleDate,
            format: Constants.DEFAULT_DATE_FORMAT,
          });
        }
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          const userStore = useUserStore();

          return DateTimeService.formatUTCDate({
            date: params.value,
            format: userStore.datePattern,
          });
        } else {
          return '';
        }
      },
    },
    {
      ...DateColumnFilterSettings,
      headerName: t('promo_end'),
      field: 'endSaleDate',
      minWidth: 90,
      width: 90,
      sortable: true,
      type: 'rightAligned',
      filterParams: {
        ...DateColumnFilterSettings.filterParams,
        comparator: DateInvertedComparator,
      },
      valueGetter: (params) => {
        if (params.data) {
          return DateTimeService.formatUTCDate({
            date: params.data.endSaleDate,
            format: Constants.DEFAULT_DATE_FORMAT,
          });
        }
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          const userStore = useUserStore();

          return DateTimeService.formatUTCDate({
            date: params.value,
            format: userStore.datePattern,
          });
        } else {
          return '';
        }
      },
    },
    {
      ...SetColumnFilterSettings,
      headerName: t('promo_type'),
      field: 'type',
      minWidth: 90,
      width: 90,
      sortable: true,
      type: 'leftAligned',
      valueGetter: (params) => params.data?.type?.label ?? '',
    },
    {
      ...TextColumnFilterSettings,
      headerName: t('created_by'),
      field: 'createdBy',
      minWidth: 90,
      width: 90,
      sortable: true,
      type: 'leftAligned',
      valueGetter: (params) => params.data?.createdBy?.name ?? '',
    },
    {
      ...DateColumnFilterSettings,
      headerName: t('created_on'),
      field: 'createdDateTime',
      minWidth: 90,
      width: 90,
      sortable: true,
      type: 'rightAligned',
      filterParams: {
        ...DateColumnFilterSettings.filterParams,
        comparator: DateTimeInvertedComparator,
      },
      valueGetter: (params) => {
        if (params.data?.createdDateTime) {
          return DateTimeService.formatDate({
            date: new Date(params.data.createdDateTime),
            format: Constants.DEFAULT_DATE_FORMAT,
          });
        }
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          const userStore = useUserStore();

          return DateTimeService.formatDate({
            date: new Date(params.value),
            format: userStore.datePattern,
          });
        } else {
          return '';
        }
      },
    },
  ];
}

export function generateUserActionsColumn(params: GridUserActionModel[]): ColDef<UserModel> {
  return {
    headerName: t('general.actions'),
    field: 'id',
    minWidth: 125,
    width: 125,
    suppressHeaderMenuButton: true,
    sortable: false,
    cellRenderer: 'GridUserActionsRenderer',
    cellClass: ({ data }: CellClassParams) => `data-test-user-permissions-actions-column-${data.id}`,
    cellRendererParams: (cellRendererParams: ICellRendererParams<PromotionUIModel>) => ({
      createdById: cellRendererParams.data?.createdBy,
      gridUserActions: params,
    }),
  };
}
