<template>
  <div class="k-card-footer">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'KCardFooter',
});
</script>

<style lang="scss">
.k-card-footer {
  @apply bg-gray-100 border-t border-gray-200;

  padding: 8px 15px;
  display: flex;
  justify-content: flex-end;
}
</style>
