import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export class ReverseMarketNameAction implements RouteAction<RouteActionPayload> {
  public actionType: RouteActionType = RouteActionType.reverseMarketName;

  public setPayload(): void {}

  public getPayload(): RouteActionPayload {
    return {
      actionType: this.actionType,
    };
  }
}
