import { CellClassParams, ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { ColumnId, generateCabinLevelColumnId } from '@/models/enums/grid';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';

import { NumberColumnFilterSettings } from './base';

const { t } = i18n.global;

export const generateCabinPricingAdjustmentColumn = (cabinCode: string): ColDef => ({
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.PricingAdjustment),
  headerName: t('tactic_adjustment_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  hide: true,
  menuTabs: ['filterMenuTab'],
  filter: true,
  filterParams: { newRowsAction: 'keep' },
  headerTooltip: t('tactic_adjustment'),
  cellClass: ({ data }: CellClassParams) => `data-test-tactic-adjustment-${data.ondId}-${cabinCode}`,
  valueFormatter: (params: ValueFormatterParams) => FormatService.numberToTacticAdjustmentString(params.value),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && matchingCabin.pricingAdjustment;
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasContinuousPricingEnabled,
});

export const generateCabinPricingAggregationColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.PricingAggregation),
  headerName: t('price_increment_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  hide: true,
  headerTooltip: t('dynamic_filters.price_increment'),
  cellClass: ({ data }: CellClassParams) => `data-test-price-increment-${data.ondId}-${cabinCode}`,
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && matchingCabin.pricingIncrement;
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasContinuousPricingEnabled,
});

export const generateCabinPricingTacticColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.PricingTactic),
  headerName: t('pricing_tactic_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  hide: true,
  headerTooltip: t('pricing_tactic'),
  cellClass: ({ data }: CellClassParams) => `data-test-pricing-tactic-${data.ondId}-${cabinCode}`,
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && matchingCabin.pricingTactic;
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasContinuousPricingEnabled,
});
