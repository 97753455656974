<template>
  <el-tag :type="value ? 'success' : 'danger'">
    {{ title }}
  </el-tag>
</template>

<script lang="ts" setup>
import { ICellRendererParams } from 'ag-grid-enterprise';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  params: ICellRendererParams;
}>();

const value = ref<string>();

const title = computed<string>(() => (value.value ? t('user_management.states.active') : t('user_management.states.inactive')));

function setValue(params: typeof props.params): void {
  value.value = params.value;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function refresh(params: typeof props.params): void {
  setValue(params);
}

onMounted(() => {
  setValue(props.params);
});
</script>
