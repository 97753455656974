import { InsightName } from '@/modules/insights/configuration/insights-configuration';

type InsightLocaleConfig = {
  title: string;
  description: string;
};

export const en = {
  insights: {
    title: 'Insights',
    description: 'Here you can find all the Insights that were made available to you.',
    loading_insights: 'Loading Insights',
    open_in_new_tab: 'Open Insights in a new tab',
    no_insights: 'Insights Not Available',
    no_insights_description: 'There are no Explores available for this account. Please contact your account manager.',
    configurations: {
      default: {
        title: '',
        description: 'This Explore is unknown to the system.',
      },
      flight: {
        title: 'Flights',
        description: 'Flight inventory, bookings, availability, and performance data.',
      },
      flight_cabin_forecast: {
        title: 'Flight Cabin Forecast',
        description: `A dashboard that displays forecast components and expected incremental cabin bookings when the class is in the LAF for a selected flight-cabin departure.`,
        tooltip: 'Forecast learning and generation should be activated for the selected routes within the route configuration in SRM Flex.',
      },
      competitive_fares: {
        title: 'Competitor Fares',
        description: 'Competitor fare data for all capture dates and flights.',
      },
      leg_month_performance: {
        title: 'Leg Month Performance',
        description: 'Leg-month aggregated booking, revenue, and capacity data. Ideal to monitor route LF/yield curves.',
      },
      leg: {
        title: 'Leg',
        description: 'Leg-level booking and capacity aggregates. Includes overbooking management.',
      },
      leg_cabin_post_departure: {
        title: 'Leg Cabin Post-Departure',
        description: 'The final flown bookings, capacity, and departure control system counters (e.g., boardings and no-shows).',
      },
      user_action_summary: {
        title: 'User Action Summary',
        description: 'The aggregated user actions performed in Flight Control, Flight Details, and through automations.',
      },
    } as Record<InsightName, InsightLocaleConfig>,
  },
};
