import { has } from 'lodash-es';

const UPPERCASE_RE = /([A-Z])/;
const LOWERCASE_RE = /([a-z])/;
const NUMBER_RE = /([\d])/;
const SPECIAL_CHAR_RE = /[<>@\!#\$%^\&\*\(\)\_\+[\]{}\?:;\|'\"\\,\.\/~`\-=]*/;

function isStrongEnough(password: string, length: number): boolean {
  const uc = password.match(UPPERCASE_RE);
  const lc = password.match(LOWERCASE_RE);
  const n = password.match(NUMBER_RE);
  const sc = password.match(SPECIAL_CHAR_RE);

  return !!(password.length >= length && uc && uc.length >= 1 && lc && lc.length >= 1 && n && n.length >= 1 && sc && sc.length >= 1);
}

function matchesAnyRequirements(char: string): RegExpMatchArray | null {
  const uc = char.match(UPPERCASE_RE);
  const lc = char.match(LOWERCASE_RE);
  const n = char.match(NUMBER_RE);
  const sc = char.match(SPECIAL_CHAR_RE);

  return uc || lc || n || sc;
}

function createPassword(length: number, prefix: string): string {
  let char = '',
    i;
  const validChars = [];

  // Will pick characters from a pre-generated list of characters
  for (i = 33; i <= 126; i += 1) {
    char = String.fromCharCode(i);
    if (matchesAnyRequirements(char)) {
      validChars.push(char);
    }
  }

  char = validChars[rand(0, validChars)];

  if (matchesAnyRequirements(char)) {
    prefix = '' + prefix + char;
  }

  return prefix;
}

function rand(min: number, validChars: Array<string>): number {
  const max = validChars.length;
  let key, value;
  let arr = new Uint8Array(max);

  arr = crypto.getRandomValues(arr);

  for (key in arr) {
    if (has(arr, key)) {
      value = arr[key];
      if (value >= min && value < max) {
        return value;
      }
    }
  }

  return rand(min, validChars);
}

/**
 * Creates a randomly generated password based on requirements specified above
 * Inspired by: https://github.com/bermi/password-generator
 */
export function generatePassword(length: number): string {
  let password = '';

  while (!isStrongEnough(password, length)) {
    password = createPassword(length, password);
  }

  return password;
}
