export enum RouteActionType {
  setCompetitiveFareRange = 'set_competitive_fare_range',
  setDirection = 'set_direction',
  setFareCurrency = 'set_fare_currency',
  setBYOROpLevelAdjustmentIncrement = 'set_byor_op_level_adjustment_increment',
  setDynamicProgramOpLevelAdjustmentIncrement = 'set_dynamic_program_op_level_adjustment_increment',
  setForecastGeneration = 'set_forecast_generation',
  setForecastLearning = 'set_forecast_learning',
  reverseMarketName = 'reverse_market_name',
  setMarketLabels = 'set_market_labels',
}

export interface RouteActionPayload {
  actionType: RouteActionType;
}

export interface RouteAction<T> {
  actionType: RouteActionType;
  /**
   * getPayload gets the payload form the action,
   * which is a DTO ready to be sent to the API,
   * containing the data needed to execute the action
   */
  getPayload: () => T;
  setPayload: (payload: T) => void;
  value?: any;
}
