<template>
  <div class="user-management-modal">
    <el-drawer ref="userManagementModal" v-model="visibleDrawer" :before-close="handleClose" direction="rtl" size="615px">
      <template #header>
        <div>
          <span class="user-management-modal__header">{{ title }}</span>
          <span>{{ form.name }}</span>
        </div>
      </template>

      <el-form
        ref="userForm"
        :model="form"
        :rules="rules"
        label-position="top"
        label-width="30px"
        :validate-on-rule-change="false"
        @validate="onValidate"
        @submit.prevent="onSave"
      >
        <!-- Authentication Method -->
        <el-row v-show="ssoEnabled" :gutter="20" justify="space-between" type="flex">
          <el-col :span="8">
            <label class="text-sm block font-bold mt-1">{{ t('external.labels.user_auth_method') }}</label>
          </el-col>

          <el-col :span="16">
            <el-form-item prop="ssoLogin">
              <el-radio-group v-model="form.ssoLogin" size="default" :disabled="editMode">
                <el-radio :value="true" border label="SSO" />
                <el-radio :value="false" border label="Email & password" />
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- Credentials -->
        <el-row :gutter="20" justify="space-between" type="flex">
          <el-col :span="8">
            <label class="text-sm block mt-7 font-bold">{{ t('external.labels.user_credentials') }}</label>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="name" :label="t('display_name')">
              <el-input
                v-model="form.name"
                class="w-full"
                :placeholder="t('user_management.labels.name')"
                size="default"
                data-test="display-name-input"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="email" :label="t('user_management.labels.email')">
              <el-input
                class="w-full"
                :model-value="form.email"
                :placeholder="t('user_management.labels.email')"
                size="default"
                data-test="email-input"
                @input="transformEmailInput"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- Password -->
        <el-row v-if="!form.ssoLogin" type="flex" :gutter="20">
          <el-col :span="16" :offset="8">
            <el-form-item prop="password">
              <template #label>
                <div class="flex justify-between align-center">
                  {{ t('user_management.labels.password') }}

                  <el-link v-if="editMode" class="text-xs" type="primary" @click="resetPassword">
                    {{ t('user_management.actions.reset') }}
                  </el-link>
                </div>
              </template>

              <el-input
                id="passwordInput"
                v-model="form.password"
                class="w-full"
                :placeholder="t('user_management.labels.password')"
                :type="editMode && !isReset ? 'password' : 'text'"
                disabled
                size="default"
                data-test="password-input"
              >
                <template v-if="isReset || !editMode" #prepend>
                  <el-button type="primary" @click="copyPasswordToClipboard">
                    <font-awesome-icon :icon="['far', 'copy']" class="copy-icon" />
                  </el-button>
                </template>
              </el-input>
            </el-form-item>

            <k-alert v-show="isReset || !editMode" icon="exclamation-triangle" variant="orange" class="mb-4">
              {{ t('user_management.info.password_copy_alert') }}
            </k-alert>
          </el-col>
        </el-row>

        <!-- Permissions & MFA -->
        <el-row :gutter="20" justify="space-between" type="flex">
          <el-col :span="8">
            <label class="text-sm block mt-7 font-bold">{{ t('external.labels.user_permissions') }}</label>
          </el-col>

          <el-col :span="form.ssoLogin ? 16 : 6">
            <el-form-item prop="isEnabled" :label="t('user_management.labels.active')">
              <el-switch
                v-model="form.isEnabled"
                :active-icon="Check"
                :inactive-icon="Close"
                inline-prompt
                size="default"
                data-test="active-toggle"
              />
            </el-form-item>
          </el-col>

          <el-col v-if="!form.ssoLogin && hasMultiFactorAuthentication" :span="10">
            <el-form-item prop="enrollMFA" :label="t('user_management.labels.enroll_into_mfa')">
              <el-switch v-model="form.enrollMFA" :active-icon="Check" :inactive-icon="Close" inline-prompt size="default" />
            </el-form-item>

            <el-form-item v-if="form.enrollMFA" prop="phoneNumber" :label="t('user_management.labels.phone_number')">
              <el-input
                class="w-full"
                :model-value="form.phoneNumber"
                :placeholder="t('user_management.labels.phone_number')"
                size="default"
                @input="transformPhoneNumberInput"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- Roles -->
        <el-row :gutter="20" justify="space-between" type="flex">
          <el-col :span="8">
            <label class="text-sm block mt-9 font-bold">{{ t('external.labels.user_roles') }}</label>
          </el-col>

          <el-col :span="16">
            <el-form-item ref="formItemRole" prop="roles" :label="t('user_management.labels.roles')">
              <div class="flex flex-col w-full">
                <el-alert
                  v-if="formItemRole?.validateState === 'error' && !addingRole"
                  show-icon
                  :closable="false"
                  :title="t('user_management.info.at_least_one_role')"
                  class="mb-4"
                />

                <div v-for="(role, index) in form.roles" :key="role?.id" class="user-management-modal__row-role">
                  <el-card shadow="never">
                    <div class="flex justify-between items-center">
                      <div class="flex flex-col">
                        <h5 class="user-management-modal__role">
                          {{ translateRoleName(role?.name) }}
                        </h5>
                        <span class="user-management-modal__description">
                          {{ role?.description }}
                        </span>
                      </div>

                      <el-button plain type="danger" @click="onDeleteRole(index)">
                        <font-awesome-icon :icon="['far', 'times']" />
                      </el-button>
                    </div>
                    <div v-if="role.name === 'Flights.EditorOf' && form.rolesMeta?.['Flights.EditorOf']">
                      <el-form-item prop="editorOf" :label="t('user_management.labels.user_groups')">
                        <el-select-v2
                          v-model="form.rolesMeta['Flights.EditorOf'].userGroups"
                          :placeholder="t('user_management.labels.select_user_groups_placeholder')"
                          :options="userGroupOptions"
                          value-key="id"
                          label-key="name"
                          filterable
                          clearable
                          multiple
                        />
                      </el-form-item>
                    </div>
                  </el-card>
                </div>

                <div v-if="addingRole" class="user-management-modal__row-role">
                  <el-card shadow="never">
                    <div class="flex justify-between items-center">
                      <el-cascader
                        :options="availableRoles"
                        :placeholder="t('user_management.labels.select_role')"
                        class="filters-cascader-container__cascader-control data-test-add-role-select"
                        @change="onAddRole"
                      >
                        <template #default="{ node, data }">
                          <span
                            v-if="!node.isLeaf"
                            class="filters-cascader-container__cascader-control"
                            :data-test="'eddy-module-' + `${translateResourceName(data.label)}`"
                          >
                            {{ translateResourceName(data.label) }}
                          </span>

                          <span v-else>
                            <span
                              class="filters-cascader-container__cascader-control"
                              :data-test="'eddy-role-' + `${translateRoleName(data.label)}`"
                            >
                              {{ translateRoleName(data.label) }}
                            </span>
                          </span>
                        </template>
                      </el-cascader>

                      <el-button plain type="danger" @click="addingRole = false">
                        <font-awesome-icon :icon="['far', 'times']" />
                      </el-button>
                    </div>
                  </el-card>
                </div>

                <span>
                  <el-button :disabled="addingRole" link type="primary" @click="addingRole = true">
                    <font-awesome-icon class="mr-1" :icon="['fal', 'plus']" />
                    {{ roleButtonTitle }}
                  </el-button>
                </span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="settings?.hasUserGroupsEnabled && mayViewUserGroups" :gutter="20" justify="space-between" type="flex">
          <el-col :span="8">
            <label class="text-sm block mt-9 font-bold">{{ t('user_management.labels.user_groups') }}</label>
          </el-col>

          <el-col :span="16" class="text-xs">
            <el-form-item prop="userGroups">
              <template #label>
                <span> {{ $t('user_management.labels.assigned_user_groups') }} </span>
                <el-tooltip :content="assignedUserGroupsTooltip" placement="top-start">
                  <font-awesome-icon :icon="['fas', 'info-circle']" class="cursor-pointer text-gray-400 ml-2 mt-2" />
                </el-tooltip>
              </template>
              <template #default>
                <span> {{ userGroupsDisplayValue }} </span>
              </template>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- Error Message -->
        <el-row type="flex" :gutter="20">
          <el-col :span="16" :offset="8">
            <k-alert v-for="(errorMessage, index) in errorMessages" :key="index" variant="red" class="mb-2">
              {{ errorMessage }}
            </k-alert>
          </el-col>
        </el-row>

        <!-- Save / Cancel -->
        <el-row type="flex" :gutter="20">
          <el-col :span="16" :offset="8">
            <el-form-item>
              <el-button
                class="user-management-modal__buttons__btn"
                type="primary"
                :loading="isLoading"
                native-type="submit"
                data-test="save-button"
              >
                {{ t('user_management.actions.save') }}
              </el-button>

              <el-button class="user-management-modal__buttons__btn" @click.stop="close">
                {{ t('user_management.actions.cancel') }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-drawer>

    <route-management-user-assignment-dialog
      :dialog-visible="userAssignmentDialogVisible"
      :route-management-action="selectedAction"
      :selected-routes="user?.assignedRoutes ?? []"
      :selected-user="user?.name"
      :users="enabledUsers"
      width="750"
      @dialog-closed="onDialogClosed"
    />
  </div>
</template>

<script lang="ts" setup>
import { Check, Close } from '@element-plus/icons-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { CascaderValue, ElMessage, FormInstance, FormItemInstance, FormItemProp, FormRules } from 'element-plus';
import { cloneDeep, differenceBy, head, isEqual, uniq } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, getCurrentInstance, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Environment } from '@/environment';
import { CascaderItem } from '@/models/element-ui';
import { RouteManagementActionType, RouteManagementActions } from '@/models/enums';
import { Authority } from '@/modules/api/auth/auth-contracts';
import { EddyBaseError } from '@/modules/api/base-client';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { phoneNumberValidator } from '@/modules/external/form-validators';
import RouteManagementUserAssignmentDialog from '@/modules/route-management/components/RouteManagementUserAssignmentDialog.vue';
import { EddyError, MessageService } from '@/modules/shared';
import KAlert from '@/modules/shared/components/KAlert.vue';
import { RoleModel } from '@/modules/user-management/api/role/role-contracts';
import { roleService } from '@/modules/user-management/api/role/role-service';
import { UserGroupDefinition } from '@/modules/user-management/api/user-group/user-group-management.contracts';
import { generatePassword } from '@/modules/user-management/services/generate-password';
import { useUserGroupStore } from '@/modules/user-management/store/user-group.store';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { userManagementService } from '@/modules/user-settings/api/users/users-management.service';
import { useUserStore } from '@/modules/user-settings/store/user.store';

const { t } = useI18n();

export interface UserManagementModalProps {
  user?: UserModel;
  users: UserModel[];
  editMode?: boolean;
}

const props = defineProps<UserManagementModalProps>();

const ssoEnabled: Ref<boolean> = ref(Environment.ssoEnabled);

const form: Ref<UserModel> = ref({
  ssoLogin: ssoEnabled.value,
  name: '',
  email: '',
  phoneNumber: '',
  password: '',
  isEnabled: true,
  enrollMFA: false,
  roles: [],
  rolesMeta: {},
  authorities: [],
});

const roles: Ref<RoleModel[]> = ref([]);
const addingRole: Ref<boolean> = ref(false);
const isReset: Ref<boolean> = ref(false);
const isLoading: Ref<boolean> = ref(false);
const visibleDrawer: Ref<boolean> = ref(true);
const errorMessages: Ref<string[]> = ref([]);
const userForm: Ref<FormInstance | undefined> = ref();
const formItemRole: Ref<FormItemInstance | undefined> = ref();
const userManagementModal = ref();
const userAssignmentDialogVisible: Ref<boolean> = ref(false);
const selectedAction: Ref<RouteManagementActionType> = ref(RouteManagementActions.ReassignUser);

const customerSettingsStore = useCustomerSettingsStore();
const { hasMultiFactorAuthentication, settings } = storeToRefs(customerSettingsStore);

const userStore = useUserStore();
const { authorities } = storeToRefs(userStore);

const userGroupStore = useUserGroupStore();
const { userGroups } = storeToRefs(userGroupStore);

const userGroupOptions: ComputedRef<{ label: string; value: UserGroupDefinition }[]> = computed(() =>
  userGroups.value.map((group) => ({
    label: group.name,
    value: { id: group.id as number, name: group.name },
  })),
);

const title: ComputedRef<string> = computed(() =>
  props.editMode ? t('user_management.titles.edit_user') : t('user_management.titles.add_user'),
);
const roleButtonTitle: ComputedRef<string> = computed(() =>
  form.value.roles && form.value.roles.length === 0 ? t('user_management.actions.add_role') : t('user_management.actions.add_another_role'),
);

// Filter out disabled users and currently selected user
const enabledUsers: ComputedRef<UserModel[]> = computed(() => props.users.filter((user) => user.isEnabled && user.id !== props.user?.id));

const availableRoles: ComputedRef<CascaderItem<RoleModel>[]> = computed(() => {
  const resources = uniq(roles.value.map((role: RoleModel) => role.name.split('.')[0]));
  return resources
    .map((resource: string) => ({
      value: resource,
      label: resource,
      children: differenceBy(roles.value, form.value.roles, 'id')
        .filter((role: RoleModel) => resource === role.name.split('.')[0])
        .map((role: RoleModel) => ({
          value: role.name,
          label: role.name,
          description: role.description,
        })),
    }))
    .filter((role: CascaderItem<RoleModel>) => !!role.children?.length);
});

const mayUpdateUserGroups = computed<boolean>(() => authorities.value.includes(Authority.UserGroupsUpdate));
const mayViewUserGroups = computed<boolean>(() => !!mayUpdateUserGroups.value || authorities.value.includes(Authority.UserGroupsRead));

const userGroupsDisplayValue = computed<string>(
  () => form.value.userGroups?.map((group) => group.name).join(', ') || t('user_management.labels.unassigned'),
);

const assignedUserGroupsTooltip = computed<string>(() =>
  mayUpdateUserGroups.value
    ? t('user_management.info.assigned_user_groups_tooltip_edit')
    : t('user_management.info.assigned_user_groups_tooltip_view'),
);

const rules: ComputedRef<FormRules<UserModel>> = computed(() => ({
  ssoLogin: [
    {
      required: true,
      trigger: 'change',
    },
  ],
  name: [
    {
      required: true,
      message: t('form.validate_name'),
      trigger: 'blur',
    },
  ],
  enrollMFA: [
    {
      required: !form.value.ssoLogin,
      trigger: 'change',
    },
  ],
  email: [
    {
      required: true,
      message: t('form.validate_email'),
      trigger: 'blur',
    },
    {
      type: 'email',
      message: t('form.validate_valid_email'),
      trigger: ['blur', 'change'],
    },
  ],
  roles: {
    required: true,
    message: t('form.validate_role'),
    trigger: 'change',
    type: 'array',
  },
  phoneNumber: [
    { required: !form.value.ssoLogin && form.value.enrollMFA, message: t('external.required_field.phone_number') },
    { validator: phoneNumberValidator, trigger: 'blur' },
  ],
}));

onMounted(async () => {
  if (props.user) {
    form.value = cloneDeep(props.user);
  }

  if (!props.editMode) {
    form.value.password = generateRandomPassword();
  }

  roles.value = await roleService.get();
  addingRole.value = !props.editMode;
});

function translateResourceName(value: string): string {
  return value ? roleService.translateResource(value) : '';
}
function translateRoleName(value?: string): string {
  return value ? roleService.translateRole(value) : '';
}
function onValidate(prop: FormItemProp): void {
  if (!userForm.value) {
    return;
  }

  if (prop === 'enrollMFA' && !form.value.enrollMFA) {
    // Unset phone number if user switched off MFA
    delete form.value.phoneNumber;
  }
}
function transformEmailInput(value: string): void {
  form.value = { ...form.value, email: value.toLowerCase() };
}

function transformPhoneNumberInput(value: string): void {
  // strips all non-digits, allowing for a preceding '+' and caps the length at 15
  form.value = {
    ...form.value,
    phoneNumber: value.replace(/(?!^\+)[^\d]/, '').substring(0, 15),
  };
}
function generateRandomPassword(): string {
  return generatePassword(16);
}
function onAddRole(values: CascaderValue): void {
  const addedRole = head(roles.value.filter((role: RoleModel) => role.name === (values as string[])[1]));

  if (addedRole) {
    form.value.roles.push(addedRole);
  }

  if (addedRole?.name === 'Flights.EditorOf') {
    if (!userGroups.value?.length) {
      userGroupStore.getUserGroups();
    }
    form.value.rolesMeta = {
      ...form.value.rolesMeta,
      ['Flights.EditorOf']: {
        userGroups: [],
      },
    };
  }

  addingRole.value = false;
}

function onDeleteRole(index: number): void {
  if (form.value.roles[index].name === 'Flights.EditorOf') {
    delete form.value.rolesMeta?.['Flights.EditorOf'];
  }

  form.value.roles.splice(index, 1);
  // ElementPlus doesn't see the change by itself (no also not with a new array instead of splice),
  // so we trigger the change manually
  formItemRole.value?.validate('change');
}

async function save(): Promise<void> {
  isLoading.value = true;

  if (props.editMode) {
    try {
      // Only save changes if user made change to form
      if (!isEqual(form.value, props.user)) {
        const updatedUser = await userManagementService.updateUser({
          ...form.value,
        });

        emit('userSaved', updatedUser);

        // Show contact support message when has been switched off for user
        if (props.user?.enrollMFA && !form.value.enrollMFA) {
          MessageService.warning(t('contact_support_to_unenroll_user'));
        }
      }

      close();
    } catch (error) {
      handleErrors(error as EddyBaseError);
    } finally {
      isLoading.value = false;
    }

    return;
  }

  try {
    const createdUser = await userManagementService.create({ ...form.value });

    emit('userSaved', createdUser);
    close();
  } catch (error) {
    handleErrors(error as EddyBaseError);
  } finally {
    isLoading.value = false;
  }
}
function handleErrors(errors: EddyBaseError): void {
  errorMessages.value = errors.response?.data?.errors?.map((eddyError: EddyError) => eddyError.uiMessage) ?? [];
}
function close(): void {
  handleClose();
  visibleDrawer.value = false;
}
function handleClose(): void {
  emit('close');
}
function copyPasswordToClipboard(): void {
  const tempEl = document.createElement('input');
  tempEl.type = 'text';
  tempEl.value = form.value.password ?? '';
  document.body.appendChild(tempEl);
  tempEl.select();
  document.execCommand('Copy');
  document.body.removeChild(tempEl);
  ElMessage.info({
    message: t('password_copied'),
    type: 'success',
    duration: 2000,
  });
}

function resetPassword(): void {
  isReset.value = true;
  form.value.password = generateRandomPassword();
  const instance = getCurrentInstance();
  instance?.proxy?.$forceUpdate();
}

function onDialogClosed(appliedChanges: boolean): void {
  if (appliedChanges) {
    save();
  } else {
    // User cancelled reassignment of routes
    form.value.isEnabled = true;
  }

  userAssignmentDialogVisible.value = false;
}

async function onSave(): Promise<void> {
  if (!userForm.value) return;

  try {
    // Validate
    await userForm.value.validate();

    // Show assign routes modal when editing a user which is getting deactivated and has assigned routes
    if (props.editMode && !form.value.isEnabled && props.user?.assignedRoutes && props.user?.assignedRoutes.length > 0) {
      userAssignmentDialogVisible.value = true;
      return;
    }

    save();
  } catch {
    return;
  }
}

const emit = defineEmits<{
  (event: 'userSaved', user: UserModel): void;
  (close: 'close'): void;
}>();

defineExpose({
  roleButtonTitle,
  form,
  availableRoles,
  onAddRole,
  onDeleteRole,
  save,
  onSave,
  userAssignmentDialogVisible,
  onDialogClosed,
});
</script>

<style lang="scss" scoped>
.user-management-modal {
  &__header {
    color: #9a9a9a;
    margin-right: 0.25rem;
  }

  &__role {
    font-weight: bold;
  }

  &__alert {
    margin: 5px 0 10px 0;
  }

  &__row-role {
    margin-bottom: 0.5rem;
  }

  &__description {
    font-size: 0.75rem;
  }

  &__buttons {
    &__btn {
      width: 6.25rem;
    }
  }

  .el-drawer {
    overflow-y: auto;

    .el-drawer__header {
      min-height: 1.125rem !important;
    }
  }

  :deep(.el-input__inner) {
    font-size: 0.75rem;
  }

  :deep(.el-card__body) {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .copy-icon {
    font-size: 1rem;
    margin-top: -0.1875rem;
  }
}
</style>
