<template>
  <sticky-header :title="t('user_management.titles.user_management')">
    <template #content>
      <div class="h-full flex flex-col">
        <k-tabs v-if="showUserGroupManagement" class="mb-8" data-test="user-management-tabs">
          <k-tabs-router-link :route-options="{ name: RouteName.userManagementUsers }" data-test="tabs-user-list">
            {{ t('user_management.tabs.user_list') }}
          </k-tabs-router-link>
          <k-tabs-router-link :route-options="{ name: RouteName.userManagementUserGroups }" data-test="tabs-user-groups">
            {{ t('user_management.tabs.user_groups') }}
          </k-tabs-router-link>
        </k-tabs>
        <router-view />
      </div>
    </template>
  </sticky-header>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import StickyHeader from '@/modules/shared/components/layouts/StickyHeader.vue';
import KTabs from '@/modules/shared/components/tabs/KTabs.vue';
import KTabsRouterLink from '@/modules/shared/components/tabs/KTabsRouterLink.vue';
import { RouteName } from '@/modules/shared/types/routes';
import { useUserStore } from '@/modules/user-settings/store/user.store';

const { t } = useI18n();

const customerSettingsStore = useCustomerSettingsStore();
const { settings } = storeToRefs(customerSettingsStore);

const userStore = useUserStore();
const { authorities } = storeToRefs(userStore);

const showUserGroupManagement = computed<boolean>(
  () => !!(settings.value?.hasUserGroupsEnabled && authorities.value.includes(Authority.UserGroupsRead)),
);
</script>
