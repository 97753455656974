import 'firebase/compat/auth';

import { Environment } from '@/environment';
import { ApiClient } from '@/modules/api/base-client';
import { RouteModel } from '@/modules/route-management/api/routes/routes.contracts';
import { RoleModel } from '@/modules/user-management/api/role/role-contracts';

import { AssignRouteModel, IUserManagementService, UserFilterModel, UserModel } from './users-management.contracts';

export class UserManagementService implements IUserManagementService {
  private basePath = 'users';
  private contentType = 'text/uri-list';

  public async get(isEnabled?: boolean): Promise<UserModel[]> {
    return await ApiClient.get<UserModel[]>(`${this.basePath}`, { isEnabled });
  }

  public async getForControlStaticFilters(): Promise<UserFilterModel[]> {
    return await ApiClient.get<UserFilterModel[]>(`${this.basePath}/control-filter`);
  }

  public async create(user: UserModel): Promise<UserModel> {
    const roles = [...user.roles];
    user.roles = [];

    const createdUser = await ApiClient.post<UserModel>(`${this.basePath}`, user);

    await this.assignRoles(roles, createdUser);

    return createdUser;
  }

  public async updateUser(user: UserModel): Promise<UserModel> {
    await this.assignRoles(user.roles, user);
    user.roles = [];
    return ApiClient.put<UserModel>(`${this.basePath}/${user.id}`, user);
  }

  public delete(user: UserModel): Promise<any> {
    return ApiClient.delete(`${this.basePath}/${user.id}`);
  }

  public async addRoutes(routes: RouteModel[] | AssignRouteModel[], userId: number): Promise<void> {
    const addedRoutes: AssignRouteModel[] = routes.map((route) => ({
      flightPath: route.flightPath,
      carrierCode: route.carrierCode,
    }));

    await ApiClient.patch(`${this.basePath}/${userId}/routes`, addedRoutes);
  }

  public async removeRoutes(routes: RouteModel[] | AssignRouteModel[], userId: number): Promise<void> {
    const removedRoutes: AssignRouteModel[] = routes.map((route) => ({
      flightPath: route.flightPath,
      carrierCode: route.carrierCode,
    }));

    await ApiClient.delete(`${this.basePath}/${userId}/routes`, removedRoutes);
  }

  private async assignRoles(roles: RoleModel[], user: UserModel): Promise<void> {
    const links = this.getLinks(roles);
    const headers = new Headers();
    headers.append('Content-Type', this.contentType);

    return await ApiClient.put(`${this.basePath}/${user.id}/roles`, links, headers);
  }

  private getLinks(roles: RoleModel[]): string {
    const rolesApiUrl = Environment.apiUrl + 'roles/';

    return roles.map((role: RoleModel) => rolesApiUrl + role.id).join('\n');
  }
}

export const userManagementService = new UserManagementService();
