import { RemovableRef, useStorage } from '@vueuse/core';
import { ColumnState } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import { defineStore } from 'pinia';
import { ComputedRef, Ref, WritableComputedRef, computed, ref, watch } from 'vue';

import { Constants } from '@/constants';
import { Authority } from '@/modules/api/auth/auth-contracts';
import { FilterFieldField, RouteFilterType } from '@/modules/api/shared-contracts';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';
import { InventoryManagementMethodology } from '@/modules/shared/shared-contracts';
import {
  CONTROL_DATA_VIEW_MODE,
  ControlDataDisplayMode,
  ControlSettings,
  DatePattern,
  UserConfigModel,
} from '@/modules/user-settings/api/user/user.contracts';
import { userService } from '@/modules/user-settings/api/user/user.service';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { defaultUserControlFilterSettings } from '@/store/modules/default-user-control-filter-settings';

type UserStoreGridColumnStates = Partial<
  Pick<UserConfigModel, 'controlGridColumnState' | 'cabinInventoryGridColumnState' | 'inventoryGridColumnState' | 'departedGridColumnState'>
>;
type UserStoreGridColumnStateKeys = keyof UserStoreGridColumnStates;

export const useUserStore = defineStore('user', () => {
  const isLoading: Ref<boolean> = ref(false);
  const user: Ref<UserModel | undefined> = ref();
  const authorities: ComputedRef<Authority[]> = computed(() => user.value?.authorities || []);

  const isLoadingUserSettings: Ref<boolean> = ref(false);
  const userSettings: ComputedRef<UserModel['settings']> = computed(() => user.value?.settings);
  const fontSize: ComputedRef<number> = computed(() => userSettings.value?.fontSize || Constants.DEFAULT_FONT_SIZE);
  const datePattern: ComputedRef<DatePattern> = computed(
    () => (userSettings.value?.datePattern?.toUpperCase() as DatePattern) || (Constants.DEFAULT_DATE_PATTERN as DatePattern),
  );
  const inventoryManagementMethodology: ComputedRef<InventoryManagementMethodology> = computed(
    () => userSettings.value?.inventoryManagementMethodology ?? InventoryManagementMethodology.au,
  );

  const controlDataDisplayMode: ComputedRef<ControlDataDisplayMode> = computed(
    () => userSettings.value?.controlSettings?.controlDataDisplayMode || CONTROL_DATA_VIEW_MODE.TABLE,
  );

  const controlSettings: ComputedRef<ControlSettings> = computed(() =>
    !userSettings.value?.controlSettings || isEmpty(userSettings.value.controlSettings)
      ? defaultUserControlFilterSettings
      : userSettings.value.controlSettings,
  );
  const isLoadingControlSettings: Ref<boolean> = ref(false);

  const gridColumnStates: RemovableRef<UserStoreGridColumnStates> = useStorage('gridColumnStates', {});
  const isLoadingGridStates: Ref<boolean> = ref(false);

  const inventoryGridColumnState: WritableComputedRef<ColumnState[]> = computed({
    get: () => gridColumnStates.value.inventoryGridColumnState ?? [],
    set: (value) => (gridColumnStates.value.inventoryGridColumnState = value),
  });

  const controlGridColumnState: WritableComputedRef<ColumnState[]> = computed({
    get: () => gridColumnStates.value.controlGridColumnState ?? [],
    set: (value) => (gridColumnStates.value.controlGridColumnState = value),
  });

  const cabinInventoryGridColumnState: WritableComputedRef<ColumnState[]> = computed({
    get: () => gridColumnStates.value.cabinInventoryGridColumnState ?? [],
    set: (value) => (gridColumnStates.value.cabinInventoryGridColumnState = value),
  });

  const departedGridColumnState: WritableComputedRef<ColumnState[]> = computed({
    get: () => gridColumnStates.value.departedGridColumnState ?? [],
    set: (value) => (gridColumnStates.value.departedGridColumnState = value),
  });

  watch(
    () => user.value?.id,
    (userId) => {
      if (userId) {
        logger.setUser({ id: `${userId}` });
      } else {
        logger.clearUser();
      }
    },
  );

  watch(
    [
      () => userSettings.value?.controlGridColumnState,
      () => userSettings.value?.cabinInventoryGridColumnState,
      () => userSettings.value?.inventoryGridColumnState,
      () => userSettings.value?.departedGridColumnState,
    ],
    ([
      updatedControlGridColumnState,
      updatedCabinInventoryGridColumnState,
      updatedInventoryGridColumnState,
      updatedDepartedGridColumnState,
    ]) => {
      if (!controlGridColumnState.value.length && updatedControlGridColumnState) {
        controlGridColumnState.value = updatedControlGridColumnState;
      }

      if (!cabinInventoryGridColumnState.value.length && updatedCabinInventoryGridColumnState) {
        cabinInventoryGridColumnState.value = updatedCabinInventoryGridColumnState;
      }

      if (!inventoryGridColumnState.value.length && updatedInventoryGridColumnState) {
        inventoryGridColumnState.value = updatedInventoryGridColumnState;
      }

      if (!departedGridColumnState.value.length && updatedDepartedGridColumnState) {
        departedGridColumnState.value = updatedDepartedGridColumnState;
      }
    },
    { immediate: true },
  );

  async function get(email: string): Promise<void> {
    try {
      isLoading.value = true;
      user.value = await userService.getByEmail(email);
    } catch {
      MessageService.failedRequest('Failed to fetch user');
    } finally {
      isLoading.value = false;
    }
  }

  async function updateUserSettings(config: Partial<UserConfigModel>): Promise<void> {
    try {
      isLoadingUserSettings.value = true;

      if (!user.value?.id) {
        throw new Error('No user id present');
      }

      user.value.settings = await userService.patchConfig(user.value.id, config);
    } catch {
      MessageService.failedRequest('Failed to update user settings');
    } finally {
      isLoadingUserSettings.value = false;
    }
  }

  async function updateControlSettings(config: Partial<ControlSettings>): Promise<void> {
    try {
      isLoadingControlSettings.value = true;

      await updateUserSettings({ controlSettings: { ...controlSettings.value, ...config } });
    } catch {
      MessageService.failedRequest('Failed to update control settings');
    } finally {
      isLoadingControlSettings.value = false;
    }
  }

  // Updates the control settings until new user settings are fetched from the server that will overwrite these changes
  function updateControlSettingsLocally(config: Partial<ControlSettings>): void {
    if (user.value?.settings) {
      user.value.settings.controlSettings = { ...controlSettings.value, ...config };
    }
  }

  function getFiltersByRouteFilterType(filterType: RouteFilterType, filters: FilterFieldField[]): FilterFieldField[] {
    let updatedFilters: FilterFieldField[] = [...filters];

    /**
     * What we do here is replace the separate 'origin' and 'destination' filter with the 'hub' and 'flightpath' filter; and vice versa.
     * The user will see this in the UI when filtering on the control page.
     */
    if (filterType === RouteFilterType.origin_destination) {
      updatedFilters = updatedFilters.filter((filter) => filter !== FilterFieldField.hub && filter !== FilterFieldField.flightPath);

      if (!(updatedFilters.includes(FilterFieldField.origin) && updatedFilters.includes(FilterFieldField.destination))) {
        // If for some reason the origin or destination filter is not present, but the other is we remove that one to add them both to the start of filters array
        updatedFilters = updatedFilters.filter((filter) => filter !== FilterFieldField.origin && filter !== FilterFieldField.destination);
        updatedFilters.unshift(FilterFieldField.origin, FilterFieldField.destination);
      }
    } else {
      updatedFilters = updatedFilters.filter((filter) => filter !== FilterFieldField.origin && filter !== FilterFieldField.destination);

      if (!(updatedFilters.includes(FilterFieldField.hub) && updatedFilters.includes(FilterFieldField.flightPath))) {
        // If for some reason the hub or flightPath filter is not present, but the other is we remove that one to add them both to the start of filters array
        updatedFilters = updatedFilters.filter((filter) => filter !== FilterFieldField.hub && filter !== FilterFieldField.flightPath);
        updatedFilters.unshift(FilterFieldField.hub, FilterFieldField.flightPath);
      }
    }

    return updatedFilters;
  }

  function updateControlFiltersByRouteFilterTypeLocally(filterType: RouteFilterType): void {
    const updatedFilters = getFiltersByRouteFilterType(filterType, controlSettings.value.filters || []);
    const updatedControlSettings: ControlSettings = { ...controlSettings.value, routeFilterType: filterType, filters: updatedFilters };

    updateControlSettingsLocally(updatedControlSettings);
  }

  async function updateControlFiltersByRouteFilterType(filterType: RouteFilterType): Promise<void> {
    const updatedFilters = getFiltersByRouteFilterType(filterType, controlSettings.value.filters || []);
    const updatedControlSettings: ControlSettings = { ...controlSettings.value, routeFilterType: filterType, filters: updatedFilters };

    await updateControlSettings(updatedControlSettings);
  }

  async function updateGridColumnsState(
    gridStateKey: UserStoreGridColumnStateKeys,
    gridState: UserStoreGridColumnStates[typeof gridStateKey],
  ): Promise<void> {
    try {
      isLoadingGridStates.value = true;
      gridColumnStates.value[gridStateKey] = gridState;

      await updateUserSettings({
        [gridStateKey]: gridColumnStates.value[gridStateKey],
      });
    } catch (e) {
      MessageService.error('Failed to save grid state');
    } finally {
      isLoadingGridStates.value = true;
    }
  }

  function $reset(): void {
    isLoading.value = false;
    isLoadingControlSettings.value = false;
    isLoadingUserSettings.value = false;

    user.value = undefined;

    gridColumnStates.value = {};
    isLoadingGridStates.value = false;
  }

  return {
    isLoading,
    user,
    userSettings,
    updateUserSettings,
    isLoadingUserSettings,
    fontSize,
    datePattern,
    inventoryManagementMethodology,
    authorities,
    controlSettings,
    updateControlSettings,
    isLoadingControlSettings,
    updateControlSettingsLocally,
    updateControlFiltersByRouteFilterType,
    updateControlFiltersByRouteFilterTypeLocally,
    gridColumnStates,
    updateGridColumnsState,
    isLoadingGridStates,
    inventoryGridColumnState,
    controlGridColumnState,
    cabinInventoryGridColumnState,
    departedGridColumnState,
    get,
    $reset,
    controlDataDisplayMode,
  };
});
