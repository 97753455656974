import { CellClassParams, ColDef, IProvidedColumn, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { SetColumnFilterSettings, TextColumnFilterSettings } from '@/models/columns/definitions/base';
import { ColumnId } from '@/models/enums/grid';
import { RouteGroup } from '@/modules/route-management/api/route-groups.contracts';
import { RouteCabin, RouteUsers } from '@/modules/route-management/api/routes/routes.contracts';
import { SlimRouteModel, useRouteManagementStore } from '@/modules/route-management/store/route-management.store';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { UserGroupDefinition } from '@/modules/user-management/api/user-group/user-group-management.contracts';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

type RouteModelValueGetterParams = ValueGetterParams<SlimRouteModel>;

const getRouteKey = (carrierCode: string, flightPath: string): string => `${carrierCode.padEnd(3, ' ')}-${flightPath}`;

export const RouteKeyColumn: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.RouteKey,
  type: 'leftAligned',
  headerName: t('route_key'),
  minWidth: 150,
  width: 180,
  headerCheckboxSelection: true,
  checkboxSelection: true,
  sortable: true,
  sort: 'asc',
  headerClass: 'data-test-route-key-header',
  valueGetter: ({ data }: RouteModelValueGetterParams) => (data ? getRouteKey(data?.carrierCode, data?.flightPath) : ''),
  cellClass: ({ data }: CellClassParams<SlimRouteModel>) =>
    `data-test-route-key-cell ${data ? `data-test-route-key-cell-${getRouteKey(data?.carrierCode, data?.flightPath)}` : ''}`,
};

export const RouteCarrierCodeColumn: ColDef<SlimRouteModel> = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RouteCarrierCode,
  type: 'leftAligned',
  headerName: t('general.carrier_code'),
  field: 'carrierCode',
  minWidth: 60,
  width: 100,
  sortable: true,
  headerClass: 'ag-header-carrier',
};

export const RouteFlightPathColumn: ColDef<SlimRouteModel> = {
  ...TextColumnFilterSettings,
  colId: ColumnId.RouteFlightPath,
  type: 'leftAligned',
  headerName: t('path'),
  field: 'flightPath',
  minWidth: 80,
  width: 130,
  sortable: true,
};

export const RouteStopsColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RouteStops,
  type: 'leftAligned',
  headerName: t('grid.columns.routes.stops'),
  headerTooltip: t('grid.columns.routes.stops_short'),
  field: 'stops',
  minWidth: 70,
  width: 70,
  sortable: true,
};

export const RouteOriginColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.Origin,
  type: 'leftAligned',
  headerName: t('general.origin'),
  field: 'origin',
  minWidth: 60,
  width: 100,
  sortable: true,
  headerClass: 'ag-header-origin',
};

export const RouteDestinationColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.Destination,
  type: 'leftAligned',
  headerName: t('general.destination'),
  field: 'destination',
  minWidth: 60,
  width: 100,
  sortable: true,
  headerClass: 'ag-header-destination',
};

export const RouteAssignedToColumn: ColDef<SlimRouteModel> = {
  ...TextColumnFilterSettings,
  colId: ColumnId.RouteAssignedTo,
  type: 'leftAligned',
  headerName: t('assigned_to'),
  field: 'users',
  minWidth: 150,
  width: 250,
  sortable: true,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    const result = params.data.users ? params.data.users.map((user: RouteUsers) => user.name).join() : null;
    return result || null;
  },
  cellClass: ({ data: { carrierCode, flightPath } }: CellClassParams) =>
    `data-test-assigned-to-column-${carrierCode.padEnd(3, ' ')}-${flightPath}`,
};

export const RouteUserGroupColumn: ColDef<SlimRouteModel> = {
  ...TextColumnFilterSettings,
  colId: 'userGroups',
  type: 'leftAligned',
  headerName: t('route_management.labels.user_groups'),
  field: 'userGroups',
  minWidth: 150,
  width: 250,
  sortable: true,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    const result = params.data.userGroups ? params.data.userGroups.map((userGroup: UserGroupDefinition) => userGroup.name).join() : null;
    return result || null;
  },
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasUserGroupsEnabled,
};

export const RouteCfTimeWindow: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.RouteCfTime,
  type: 'leftAligned',
  headerName: t('time_window'),
  field: 'timeWindow',
  minWidth: 150,
  width: 150,
  sortable: false,
  valueGetter: ({ data }: RouteModelValueGetterParams) => [
    (data?.competitiveFareStartAt ?? 0) / 60,
    (data?.competitiveFareEndAt ?? 0) / 60,
  ],
  cellRenderer: 'GridRouteCfWindowRenderer',
};

export const RouteFareCurrencyColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RouteFareCurrency,
  type: 'leftAligned',
  headerName: t('fare_currency'),
  field: 'fareCurrency',
  minWidth: 60,
  width: 100,
  sortable: true,
  valueGetter: (params: RouteModelValueGetterParams) => params.data?.fareCurrency,
};

export const RouteDirectionColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RouteDirection,
  type: 'leftAligned',
  headerName: t('direction'),
  field: 'direction',
  minWidth: 60,
  width: 100,
  sortable: true,
};

export const RouteGroupsColumn: ColDef<SlimRouteModel> = {
  ...TextColumnFilterSettings,
  colId: ColumnId.RouteGroups,
  type: 'leftAligned',
  headerName: t('route_groups'),
  sortable: true,
  field: 'routeGroups',
  minWidth: 100,
  width: 150,
  cellRenderer: 'GridRouteGroupsRenderer',
  comparator: StringOrNumberComparator,
  valueGetter: ({ data }: RouteModelValueGetterParams) => data?.routeGroups?.map((routeGroup: RouteGroup) => routeGroup.name).join(),
  cellRendererParams: ({ data }: RouteModelValueGetterParams) => ({ value: data?.routeGroups }),
};

export const RouteForecastGeneration: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.ForecastGeneration,
  type: 'leftAligned',
  headerName: t('general.forecast_generation'),
  field: 'forecastGeneration',
  minWidth: 60,
  width: 150,
  sortable: true,
  headerClass: 'ag-header-origin',
  valueFormatter: (params: ValueFormatterParams): string => (params.value ? t('enabled') : t('disabled')),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasForecastingEnabled,
};

export const RouteForecastLearning: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.ForecastLearning,
  type: 'leftAligned',
  headerName: t('general.forecast_learning'),
  field: 'forecastLearning',
  minWidth: 60,
  width: 150,
  sortable: true,
  headerClass: 'ag-header-origin',
  valueFormatter: (params: ValueFormatterParams): string => (params.value ? t('enabled') : t('disabled')),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasForecastingEnabled,
};

// Used in the route review queue
export const GenerateRouteFlightPathColumn = (): ColDef => ({
  colId: ColumnId.RouteFlightPath,
  headerName: t('path'),
  minWidth: 200,
  width: 200,
  sortable: false,
  sort: 'asc',
  valueGetter: (params: RouteModelValueGetterParams) => params.data?.flightPath,
});

export const RouteCabinClustersColumn = (cabinCode: string): ColDef => {
  const store = useRouteManagementStore();

  return {
    ...SetColumnFilterSettings,
    colId: `${cabinCode}-clusters`,
    type: 'leftAligned',
    headerName: t('clusters'),
    sortable: true,
    minWidth: 100,
    width: 150,
    cellRenderer: 'GridRouteCabinClustersRenderer',
    cellClass: ({ data: { carrierCode, flightPath } }: CellClassParams) =>
      `data-test-cluster-column-${carrierCode.padEnd(3, ' ')}-${flightPath}`,
    comparator: StringOrNumberComparator,
    cellRendererParams: ({
      data,
    }: RouteModelValueGetterParams): { routeId: number; cabinCode: string; numberOfClusters: number } | undefined => {
      if (data?.id) {
        const numberOfClusters: number = store.routeClustersMap.get(data.id)?.get(cabinCode)?.length ?? 0;

        return { cabinCode, routeId: data.id, numberOfClusters };
      }
    },
    valueGetter: ({ data }: RouteModelValueGetterParams): string | undefined => {
      if (data?.id) {
        return store.routeClustersMap.get(data.id)?.get(cabinCode)?.[0]?.name;
      }

      return;
    },
  };
};

export const RouteCabinPacingCurvesColumn = (cabinCode: string): ColDef => {
  const store = useRouteManagementStore();

  return {
    ...SetColumnFilterSettings,
    colId: `${cabinCode}-pacing-curves`,
    type: 'leftAligned',
    headerName: t('pacing_curves'),
    sortable: true,
    minWidth: 100,
    width: 150,
    cellRenderer: 'GridRouteCabinPacingCurvesRenderer',
    cellClass: ({ data: { carrierCode, flightPath } }: CellClassParams) =>
      `data-test-pacing-curve-column-${carrierCode.padEnd(3, ' ')}-${flightPath}`,
    comparator: StringOrNumberComparator,
    cellRendererParams: ({
      data,
      column,
    }: RouteModelValueGetterParams):
      | { cabinCode: string; numberOfClusters: number; routeId: number; relatedClusterColumnVisible: boolean }
      | undefined => {
      if (data?.id) {
        const relatedClusterColumnVisible = !!column
          .getOriginalParent()
          ?.getChildren()
          .find((child: IProvidedColumn) => child.getId().includes('-clusters'))
          ?.isVisible();

        const numberOfClusters = store.routeClustersMap.get(data.id)?.get(cabinCode)?.length ?? 0;

        return {
          cabinCode,
          numberOfClusters,
          routeId: data.id,
          relatedClusterColumnVisible,
        };
      }

      return;
    },
    valueGetter: ({ data }: RouteModelValueGetterParams): string | undefined => {
      if (data?.id) {
        return store.routeClustersMap.get(data.id)?.get(cabinCode)?.[0]?.pacingCurve.name;
      }

      return;
    },
  };
};

export function RouteCabinBYOROpLevelAdjustmentIncrementColumn(cabinCode: string): ColDef {
  return {
    ...SetColumnFilterSettings,
    colId: `${cabinCode}-byor-op-level-adjustment-increment`,
    type: 'leftAligned',
    headerName: t('route_management.labels.byor_op_level_adjustment_increment'),
    headerTooltip: t('route_management.labels.byor_op_level_adjustment_increment_tooltip'),
    sortable: true,
    minWidth: 120,
    width: 120,
    comparator: StringOrNumberComparator,
    valueGetter: ({ data }: RouteModelValueGetterParams): number | undefined =>
      data?.cabins.find((cabin: RouteCabin) => cabin.cabinCode === cabinCode)?.byorOpLevelAdjustmentIncrement,
    requiredPermission: ({ customerSettings }) => !!customerSettings.hasConstructBringYourOwnRecommendationsOpLevelsEnabled,
  };
}

export function RouteCabinForecastDynamicProgramOpLevelAdjustmentIncrementColumn(cabinCode: string): ColDef {
  return {
    ...SetColumnFilterSettings,
    colId: `${cabinCode}-forecast-dynamic-program-op-level-adjustment-increment`,
    type: 'leftAligned',
    headerName: t('route_management.labels.forecast_dynamic_program_op_level_adjustment_increment'),
    headerTooltip: t('route_management.labels.forecast_dynamic_program_op_level_adjustment_increment_tooltip'),
    sortable: true,
    minWidth: 120,
    width: 120,
    comparator: StringOrNumberComparator,
    valueGetter: ({ data }: RouteModelValueGetterParams): number | undefined =>
      data?.cabins.find((cabin: RouteCabin) => cabin.cabinCode === cabinCode)?.dsOpLevelAdjustmentIncrement,
    requiredPermission: ({ customerSettings }) =>
      !!customerSettings.hasForecastingEnabled && !!customerSettings.hasForecastingAndDynamicProgramEnabled,
  };
}

export const RouteMarket: ColDef<SlimRouteModel> = {
  ...TextColumnFilterSettings,
  colId: ColumnId.Market,
  type: 'leftAligned',
  headerName: t('route_management.labels.market'),
  field: 'market',
  minWidth: 60,
  width: 150,
  sortable: true,
  headerClass: 'ag-header-origin data-test-route-market',
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasAdvancedRouteLabelsEnabled,
};

export const RouteMarketLabel1: ColDef<SlimRouteModel> = {
  ...TextColumnFilterSettings,
  colId: ColumnId.MarketLabel1,
  type: 'leftAligned',
  headerName: t('route_management.labels.market_label', 1),
  minWidth: 60,
  width: 150,
  sortable: true,
  headerClass: 'ag-header-origin data-test-route-market-label-1',
  valueGetter: ({ data }: RouteModelValueGetterParams) => data?.marketLabels?.[1]?.map((label) => label.name).join(),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasAdvancedRouteLabelsEnabled,
};

export const RouteMarketLabel2: ColDef<SlimRouteModel> = {
  ...TextColumnFilterSettings,
  colId: ColumnId.MarketLabel2,
  type: 'leftAligned',
  headerName: t('route_management.labels.market_label', 2),
  minWidth: 60,
  width: 150,
  sortable: true,
  headerClass: 'ag-header-origin data-test-route-market-label-2',
  valueGetter: ({ data }: RouteModelValueGetterParams) => data?.marketLabels?.[2]?.map((label) => label.name).join(),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasAdvancedRouteLabelsEnabled,
};

export const RouteMarketLabel3: ColDef<SlimRouteModel> = {
  ...TextColumnFilterSettings,
  colId: ColumnId.MarketLabel3,
  type: 'leftAligned',
  headerName: t('route_management.labels.market_label', 3),
  minWidth: 60,
  width: 150,
  sortable: true,
  headerClass: 'ag-header-origin data-test-route-market-label-3',
  valueGetter: ({ data }: RouteModelValueGetterParams) => data?.marketLabels?.[3]?.map((label) => label.name).join(),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasAdvancedRouteLabelsEnabled,
};
