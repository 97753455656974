<template>
  <div class="user-group-management-modal">
    <el-drawer ref="userGroupManagementModal" v-model="visibleDrawer" :before-close="verifyClose" direction="rtl" size="615px">
      <template #header>
        <div>
          <span class="user-group-management-modal__header">{{
            userGroup?.id ? t('user_management.titles.edit_user_group') : t('user_management.titles.create_user_group')
          }}</span>
          <span>{{ userGroup?.name }}</span>
        </div>
      </template>

      <el-form
        v-if="form"
        ref="userGroupForm"
        :model="form"
        :rules="rules"
        label-position="top"
        label-width="30px"
        :validate-on-rule-change="false"
        @validate="onValidate"
        @submit.prevent="onSave"
      >
        <!-- Full group Name -->
        <el-row :gutter="20" justify="space-between" type="flex">
          <el-col :span="8">
            <label class="text-sm block mt-7 font-bold">{{ t('user_management.labels.group_name') }}</label>
          </el-col>

          <el-col :span="16">
            <el-form-item prop="name" :label="t('user_management.labels.unique_group_name')">
              <el-input
                v-model="form.name"
                class="w-full"
                :placeholder="t('user_management.labels.name')"
                size="default"
                data-test="display-name-input"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- Shortened group Name -->
        <el-row :gutter="20" justify="space-between" type="flex">
          <el-col :span="8">
            <label class="text-sm block mt-7 font-bold">{{ t('user_management.labels.shortened_group_name') }}</label>
          </el-col>

          <el-col :span="16">
            <el-form-item prop="alias" :label="t('user_management.labels.unique_group_abbreviation')">
              <el-input
                v-model="form.alias"
                class="w-full"
                :placeholder="t('user_management.labels.name')"
                :maxlength="maxAcronymLength"
                show-word-limit
                size="default"
                data-test="display-acronym-input"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- Group Leader -->
        <el-row :gutter="20" justify="space-between" type="flex">
          <el-col :span="8">
            <label class="text-sm block mt-7 font-bold">{{ t('user_management.labels.user_group_leader') }}</label>
          </el-col>

          <el-col :span="16">
            <el-form-item prop="leader" :label="t('user_management.labels.leader_name')">
              <el-select-v2
                v-model="form.leader"
                :placeholder="t('user_management.labels.select_leader_placeholder')"
                :options="userOptions"
                value-key="id"
                filterable
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- Group is leaf -->
        <el-row :gutter="20" justify="space-between" type="flex">
          <el-col :span="8">
            <label class="text-sm block mt-2 font-bold">{{ t('user_management.labels.this_is_a_level_with') }}</label>
          </el-col>

          <el-col :span="16">
            <el-form-item prop="isLeaf">
              <el-radio-group v-model="form.isLeaf" class="mt-2">
                <el-radio :label="t('user_management.labels.subordinates')" :value="false" />
                <el-radio :disabled="userGroupHasChildren" :label="t('user_management.labels.no_subordinates')" :value="true" />
              </el-radio-group>
              <el-tooltip placement="top" :show-after="250">
                <template #content>{{
                  userGroupHasChildren ? t('user_management.info.disabled_last_level') : t('user_management.info.last_level')
                }}</template>
                <font-awesome-icon class="ml-2 mt-2" :icon="['fas', 'info-circle']" />
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>

        <template v-if="form.isLeaf">
          <hr class="mb-4" />

          <!-- Group Members -->
          <el-row :gutter="20" justify="space-between" type="flex">
            <el-col :span="8">
              <label class="text-sm block mt-7 font-bold">{{ t('user_management.labels.user_group_members') }}</label>
            </el-col>

            <el-col :span="16">
              <el-form-item prop="members" :label="t('user_management.labels.assigned_members')">
                <el-select-v2
                  v-model="form.members"
                  :placeholder="t('user_management.labels.select_members_placeholder')"
                  :options="userOptions"
                  value-key="id"
                  filterable
                  clearable
                  multiple
                />
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <!-- Error Message -->
        <el-row type="flex" :gutter="20">
          <el-col :span="16" :offset="8">
            <k-alert v-for="(errorMessage, index) in errorMessages" :key="index" variant="red" class="mb-2">
              {{ errorMessage }}
            </k-alert>
          </el-col>
        </el-row>

        <!-- Save / Cancel -->
        <el-row type="flex" :gutter="20">
          <el-col :span="16" :offset="8">
            <el-form-item>
              <el-button
                class="user-group-management-modal__buttons__btn"
                type="primary"
                :loading="isLoading"
                native-type="submit"
                data-test="save-button"
              >
                {{ t('user_management.actions.save_changes') }}
              </el-button>

              <el-button class="user-management-modal__buttons__btn" @click.stop="verifyClose">
                {{ t('user_management.actions.cancel') }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-drawer>
  </div>
</template>

<script lang="ts" setup>
import { FormInstance, FormRules } from 'element-plus';
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { EddyBaseError } from '@/modules/api/base-client';
import { EddyError } from '@/modules/shared';
import KAlert from '@/modules/shared/components/KAlert.vue';
import { UserGroup, UserGroupUser } from '@/modules/user-management/api/user-group/user-group-management.contracts';
import { userGroupManagementService } from '@/modules/user-management/api/user-group/user-group-management.service';
import { useUserManagementStore } from '@/modules/user-management/store/user-management.store';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { MessageBoxService } from '@/services/message-box.service';

const { t } = useI18n();

const userManagementStore = useUserManagementStore();
const { enabledUsers: users } = storeToRefs(userManagementStore);

export interface UserGroupManagementProps {
  userGroup?: UserGroup;
  userGroupHasChildren: boolean;
}

const props = defineProps<UserGroupManagementProps>();
const form: Ref<UserGroup | undefined> = ref();
const isLoading: Ref<boolean> = ref(false);
const visibleDrawer: Ref<boolean> = ref(true);
const errorMessages: Ref<string[]> = ref([]);
const userGroupForm: Ref<FormInstance | undefined> = ref();
const userOptions: ComputedRef<{ label: string; value: UserGroupUser }[]> = computed(() =>
  users.value.map((user: UserModel) => ({
    label: user.name,
    value: {
      name: user.name,
      id: user.id as number,
    },
  })),
);

const maxAcronymLength = ref<number>(10);

const rules: ComputedRef<FormRules<UserModel>> = computed(() => ({
  name: [
    {
      required: true,
      message: t('user_management.form.validate_group_name'),
      trigger: 'blur',
    },
  ],
  alias: [
    {
      required: true,
      message: t('user_management.form.validate_alias'),
      trigger: 'blur',
    },
    {
      max: maxAcronymLength.value,
      message: t('user_management.form.validate_max_length', { length: maxAcronymLength.value }),
      trigger: 'blur',
    },
  ],
  leader: [
    {
      required: true,
      message: t('user_management.form.validate_leader'),
      trigger: 'blur',
    },
  ],
}));

onMounted(async () => {
  if (props.userGroup) {
    form.value = cloneDeep(props.userGroup);
  }

  if (!users.value.length) {
    await userManagementStore.getEnabledUsers();
  }

  // Check leader, if no longer available clear the value
  if (form.value?.leader && userOptions.value.length) {
    if (!userOptions.value.some((option) => option.value.id === form.value?.leader?.id)) {
      form.value.leader = undefined;
    }
  }

  // Check members, if no longer available filter the value
  if (form.value?.members && userOptions.value.length) {
    form.value.members = form.value.members.filter((member) => userOptions.value.some((option) => option.value.id === member.id));
  }
});

function onValidate(): void {
  if (!userGroupForm.value) {
    return;
  }
}

function handleErrors(errors: EddyBaseError): void {
  errorMessages.value = errors.response?.data?.errors?.map((eddyError: EddyError) => eddyError.uiMessage) ?? [];
}

async function verifyClose(): Promise<void> {
  await MessageBoxService.confirmLeaveForm().then(() => {
    close();
  });
}

function close(): void {
  emit('close');
  visibleDrawer.value = false;
}

async function onSave(): Promise<void> {
  if (!userGroupForm.value) return;
  isLoading.value = true;

  try {
    // Validate
    await userGroupForm.value.validate();
    if (form.value) {
      if (!form.value.isLeaf) {
        form.value.members = [];
      }
      await userGroupManagementService.editUserGroup({
        ...form.value,
      });

      emit('userGroupSaved');
      close();
    }
  } catch (error) {
    handleErrors(error as EddyBaseError);
  } finally {
    isLoading.value = false;
  }
}

const emit = defineEmits<{
  (event: 'userGroupSaved'): void;
  (close: 'close'): void;
}>();

defineExpose({
  form,
  onSave,
});
</script>

<style lang="scss" scoped>
.user-group-management-modal {
  &__header {
    color: #9a9a9a;
    margin-right: 0.25rem;
  }
  &__alert {
    margin: 5px 0 10px 0;
  }
  &__buttons {
    &__btn {
      width: 6.25rem;
    }
  }

  .el-drawer {
    overflow-y: auto;

    .el-drawer__header {
      min-height: 1.125rem !important;
    }
  }

  :deep(.el-input__inner) {
    font-size: 0.75rem;
  }

  :deep(.el-card__body) {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
</style>
